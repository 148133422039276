import formClasses from '@features/EstimationForm/FormPage.module.scss'
import Icon from '@components/Icon'
import { useTranslation } from 'react-i18next'
import { postFormValues } from '@root/utilities/form-utils'
import { useSelector } from 'react-redux'
import { selectModalData } from '@root/reducers/modal/modalSelectors'

export default function PrestationTypeModalError() {
  const { t } = useTranslation(['common', 'checkout'])
  const { formValues } = useSelector(selectModalData)

  return (
    <>
      <h1 className={formClasses['FormPage__title-modal']}>
        <Icon
          iconId='icon-warning-triangle'
          fill='secondary'
          size='xl'
          extraClasses={[formClasses['FormPage__icon-pdl-modal']]}
        />
        {t('common:service not available')}
      </h1>
      <p className={formClasses['FormPage__text-modal']}>
        {t(
          'checkout:error offer only available in change of supplier situation'
        )}{' '}
        <span
          className={formClasses['FormPage__link-modal']}
          onClick={() => postFormValues('/electricite', formValues)}
        >
          {t('common:here')}
        </span>
      </p>
    </>
  )
}
