import { t } from 'i18next'
import { createSlice } from '@reduxjs/toolkit'

import { keysToCamel } from '@/utilities/snake-case-to-camel-case'

const initialState = {
  data: {},
  error: null,
}

const getFunnelSteps = ({ funnelType }) => {
  const funnelSteps = {
    comparator: {
      names: [
        t('checkout:funnel steps.summary'),
        t('checkout:funnel steps.subscription'),
        t('checkout:funnel steps.confirmation'),
      ],
      initial: 1,
    },
    offer: {
      names: [
        t('checkout:funnel steps.simulation'),
        t('checkout:funnel steps.subscription'),
        t('checkout:funnel steps.confirmation'),
      ],
      initial: 1,
    },
  }

  return funnelSteps[funnelType || 'comparator']
}

const updateCurrentOfferHandler = (state, action) => {
  state.data = {
    ...state.data,
    funnelSteps: getFunnelSteps(action.payload),
    isFullOnline:
      action.payload.isOnline !== undefined
        ? Number(action.payload.isOnline) === 1
        : state.data.isFullOnline,
    ...keysToCamel(action.payload),
  }
}

const updateIsSingleOfferTunnelHandler = (state, action) => {
  state.data = {
    ...state.data,
    isSingleOfferTunnel: action.payload.isSingleOfferTunnel,
  }
}

const updatePricesHandler = (state, action) => {
  state.data = {
    ...state.data,
    globalPrice: action.payload.offerBudget,
    globalEconomie: action.payload.offerSaving,
    priceDetails: action.payload.priceDetails,
    gasPriceDetails: action.payload.gasPriceDetails,
  }
}

export const currentOfferSlice = createSlice({
  name: 'currentOffer',
  initialState,
  reducers: {
    updateCurrentOffer: updateCurrentOfferHandler,
    clearCurrentOffer: () => initialState,
    updateIsSingleOfferTunnel: updateIsSingleOfferTunnelHandler,
    updatePrices: updatePricesHandler,
  },
})

export const {
  updateCurrentOffer,
  clearCurrentOffer,
  updateIsSingleOfferTunnel,
  updatePrices,
} = currentOfferSlice.actions

export default currentOfferSlice.reducer
