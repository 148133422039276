import { createSelector } from '@reduxjs/toolkit'
import { CATEGORY_FILTER, FEATURE, PROVIDER } from '@root/constants'

export const selectFilters = state => state.filters

export const selectFiltersData = createSelector(
  selectFilters,
  filters => filters?.data
)

export const selectAppliedFiltersData = createSelector(
  selectFiltersData,
  data =>
    data.filter(({ checked }) => checked).map(({ type, id }) => `${type}-${id}`)
)

export const selectFeatureFilters = createSelector(
  selectFiltersData,
  filtersData => filtersData.filter(({ type }) => type === FEATURE)
)

export const selectProviderFilters = createSelector(
  selectFiltersData,
  filtersData => filtersData.filter(({ type }) => type === PROVIDER)
)

export const selectCategoryFilters = createSelector(
  selectFiltersData,
  filtersData => filtersData.filter(({ type }) => type === CATEGORY_FILTER)
)

export const selectSortCriteria = createSelector(
  selectFilters,
  filters => filters?.sortCriteria
)

export const selectTemporaryFilters = createSelector(
  selectFilters,
  filters => filters?.temporaryFilters
)

export const selectTemporarySortCriteria = createSelector(
  selectFilters,
  filters => filters?.temporarySortCriteria
)
