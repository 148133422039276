export const dateByUtc = offset => {
  const dateNow = new Date();
  const localTime = dateNow.getTime();
  const localOffset = dateNow.getTimezoneOffset() * 60000;

  const utc = localTime + localOffset;
  const timeOfCountry = utc + (3600000 * offset);

  return new Date(timeOfCountry)
}




