import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import endpoints from '@/utilities/apiEndPoints'

export const endpointsApi = createApi({
  reducerPath: 'appEndpointsApi',
  baseQuery: fetchBaseQuery({ baseUrl: endpoints.v2 }),
  endpoints: builder => ({
    getSiteConfig: builder.query({
      query: host => ({
        url: 'site',
        params: { host }
      }),
      transformResponse: response => ({
        host: response.url ?? response.data?.url,
        country: response.country_code ?? response.data?.countryCode,
        siteId: response.idSite ?? response.data?.siteId,
        lang: response.lang ?? response.data?.lang,
        siteConfig: {
          ...(response.config && { ...response.config }),
          ...(response.data?.config && { ...response.data?.config }),
          powers: response.powers ?? response.data?.powers
        },
        title: response.title ?? response.data?.title,
        canonical: response.canonical ?? response.data?.canonical,
        gtmId: response.gtmId ?? response.data?.gtmId
      })
    })
  })
})

export const { useGetSiteConfigQuery } = endpointsApi
