const footerLinks = [
  {
    href: 'https://luce-gas.it/chi-siamo',
    translationKey: 'in regards to',
  },
  {
    href: 'https://luce-gas.it/note-legali-privacy',
    translationKey: 'legal notice',
  },
  {
    href: 'https://luce-gas.it/confronto/come-funziona',
    translationKey: 'methodology and comparatives',
  },
]

export default footerLinks
