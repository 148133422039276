import { createSlice } from '@reduxjs/toolkit'
import { CATEGORY_FILTER, FEATURE, PROVIDER } from '@root/constants'
import {
  getCheckedOffers,
  handleCategoryFilters,
  handleFeatureFilters,
  handleProviderFilters
} from './filtersHelper'

const initialState = {
  data: [],
  temporaryFilters: [],
  sortCriteria: 'price',
  temporarySortCriteria: 'price',
  error: null
}

const resetFiltersHandler = (state, action) => {
  const { stateKey } = action.payload
  state[stateKey] = state[stateKey].map(filter => ({
    ...filter,
    checked: false,
    enabled: true
  }))
}

const updateFiltersHandler = (state, action) => {
  const { filterKey, stateKey } = action.payload
  const [type] = filterKey.split('-')

  const filters = state[stateKey]

  const checkedFeatureOffers = getCheckedOffers({
    filters,
    filterType: FEATURE
  })

  const checkedProviderOffers = getCheckedOffers({
    filters,
    filterType: PROVIDER
  })

  const checkedCategoryOffers = getCheckedOffers({
    filters,
    filterType: CATEGORY_FILTER
  })

  if (!(checkedFeatureOffers.length || checkedProviderOffers.length || checkedCategoryOffers.length)) {
    return resetFiltersHandler(state, action)
  }

  if (type === FEATURE) {
    state[stateKey] = handleFeatureFilters({
      filters
    })
    return
  }

  if (type === PROVIDER) {
    state[stateKey] = handleProviderFilters({
      filters
    })
    return
  }

  state[stateKey] = handleCategoryFilters({
    filters
  })
}

const initFiltersHandler = (state, action) => {
  const filters = action.payload.map(filter => {
    return {
      ...filter,
      checked: false,
      enabled: true
    }
  })

  state.data = [...filters]
}

const toggleFilterHandler = (state, action) => {
  const { filterKey, stateKey } = action.payload
  const [type, id] = filterKey.split('-')

  const newState = state[stateKey].map(filter => {
    const filterToggled = filter.id === Number(id) && filter.type === type

    if (!filterToggled) return filter

    return {
      ...filter,
      checked: !filter.checked
    }
  })

  state[stateKey] = newState
}

const updateSortCriteriaHandler = (state, action) => {
  state.sortCriteria = action.payload.sortCriteria
}

const updateTemporarySortCriteriaHandler = (state, action) => {
  state.temporarySortCriteria = action.payload
}

const setFiltersHandler = (state, action) => {
  const { stateKey } = action.payload
  state[stateKey] = action.payload[stateKey]
}

export const filtersSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    initFilters: initFiltersHandler,
    toggleFilter: toggleFilterHandler,
    clearFilters: () => initialState,
    updateFilters: updateFiltersHandler,
    updateSortCriteria: updateSortCriteriaHandler,
    updateTemporarySortCriteria: updateTemporarySortCriteriaHandler,
    resetFilters: resetFiltersHandler,
    setFilters: setFiltersHandler
  }
})

export const {
  initFilters,
  clearFilters,
  toggleFilter,
  updateFilters,
  updateSortCriteria,
  updateTemporarySortCriteria,
  resetFilters,
  setFilters,
  resetTemporaryFilters
} = filtersSlice.actions

export default filtersSlice.reducer
