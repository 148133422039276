import { forwardRef } from 'react'

import withMouseEvents from '../Hoc/withMouseEvents'
import classes from './Icon.module.scss'

const IconWithRef = (
  {
    id,
    iconId,
    spriteUrl,
    position,
    fill,
    rotate,
    size,
    extraClasses,
    extraAttributes,
    clickHandler,
    keyPressHandler,
    tabIndex,
    hidden,
    verticalAlignMiddle,
  },
  ref
) => {
  if (!iconId || hidden) return

  const iconClasses = [classes.Icon]
  fill && iconClasses.push(classes[`Icon--${fill}`])
  position && iconClasses.push(classes[`Icon--${position}`])
  rotate && iconClasses.push(classes['Icon--rotating'])
  verticalAlignMiddle &&
    iconClasses.push(classes['Icon--vertical-align-middle'])
  iconClasses.push(classes[`Icon--${size ?? 'md'}`])
  Array.isArray(extraClasses) && iconClasses.push(...extraClasses)

  return (
    <svg
      id={id ?? null}
      ref={ref}
      onClick={clickHandler}
      onKeyPress={keyPressHandler}
      className={iconClasses.join(' ')}
      tabIndex={tabIndex || null}
      {...extraAttributes}
    >
      <use xlinkHref={`${spriteUrl || '/img/sprite.svg'}#${iconId}`} />
    </svg>
  )
}

const Icon = forwardRef(IconWithRef)

const IconWithMouseEvents = withMouseEvents(forwardRef(IconWithRef))

export { Icon as default, IconWithMouseEvents }
