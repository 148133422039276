import {
  BELGIUM_ID,
  FORM_PAGE,
  FRANCE_ID,
  ITALY_ID,
  JAPAN_ID,
  PORTUGAL_ID,
  PROFESSIONALS_PAGE,
  SPAIN_ID
} from '@root/constants'
import apiEndPoints from '@/utilities/apiEndPoints'
import { getConfigByCountry } from './config'

const defaultEnergyPowerByCountry = {
  [FRANCE_ID]: 6,
  [ITALY_ID]: 3,
  [BELGIUM_ID]: 0,
  [PORTUGAL_ID]: 41
}

const getCampaignData = () => {
  const urlParams = new URL(window.location.href).searchParams
  return {
    content: urlParams.get('utm_source'),
    medium: urlParams.get('utm_medium'),
    name: urlParams.get('utm_campaign'),
    leadSource: urlParams.get('lead_source'),
    source: urlParams.get('source'),
    externalId: urlParams.get('external_id')
  }
}
const fetchData = async (action, data) => {
  const payloadData = new window.FormData()

  Object.entries(data).forEach(([key, value]) => {
    payloadData.append(key, value)
  })
  const fetchResponse = await fetch(apiEndPoints[action], {
    method: 'POST',
    body: payloadData
  })

  return await fetchResponse.json()
}

const fetchEstimationData = async (countryId, dataToSend) => {
  const apiVersionsByContry = {
    [ITALY_ID]: 2,
    [FRANCE_ID]: 2,
    [BELGIUM_ID]: 2,
    [JAPAN_ID]: 3,
    [SPAIN_ID]: 2,
    [PORTUGAL_ID]: 2
  }

  const endpointNamesByVersion = {
    2: 'estimation',
    3: 'estimationV3'
  }

  const apiVersion = apiVersionsByContry[countryId]
  const endpointName = endpointNamesByVersion[apiVersion]

  const fetchedData = await fetchData(endpointName, dataToSend)
  return apiVersion === 2 ? fetchedData : fetchedData.data
}

const getEstimationData = (countryId, energy, data) => {
  const configByCountry = {
    [ITALY_ID]: {
      common: {
        heat: data.heatingEnergy,
        water: data.waterEnergy,
        cooking: data.cookingEnergy,
        surface: data.surface,
        people: data.people,
        energy_type: energy
      },
      elec: {
        air_conditioner: data.electroAirConditionne ? '1' : '0',
        washing_machine: data.electroLlinge ? '1' : '0',
        dryer: data.electroSlinge ? '1' : '0',
        dishwasher: data.electroLvaisselle ? '1' : '0'
      },
      gas: {}
    },
    [FRANCE_ID]: {
      common: {
        heat: data.heatingEnergy === 'propane' ? 'other' : data.heatingEnergy,
        water: data.waterEnergy === 'propane' ? 'other' : data.waterEnergy,
        cooking:
          data.cookingEnergy === 'propane' ? 'other' : data.cookingEnergy,
        surface: data.surface,
        people: data.people,
        energy_type: energy
      },
      elec: {
        washing_machine: data.electroLlinge ? '1' : '0',
        dryer: data.electroSlinge ? '1' : '0',
        dishwasher: data.electroLvaisselle ? '1' : '0'
      },
      gas: {}
    },
    [BELGIUM_ID]: {
      common: {
        heat: data.heatingEnergy,
        water: data.waterEnergy,
        cooking: data.cookingEnergy,
        ...(data.tariffOption && { tarif_option: data.tariffOption !== 'unknown' ? data.tariffOption : 'base' }),
        has_exclusive_night: data.hasExclusiveNightTariff === 'yes' ? 1 : 0,
        surface: data.surface,
        nb_people: data.people
      },
      elec: {
        'electro-llinge': data.electroLlinge ? '1' : '0',
        'electro-slinge': data.electroSlinge ? '1' : '0',
        'electro-lvaisselle': data.electroLvaisselle ? '1' : '0'
      },
      gas: {}
    },
    [JAPAN_ID]: {
      common: {
        people: data.people,
        morning: data.rangeMorning ? 1 : 0,
        afternoon: data.rangeAfternoon ? 1 : 0,
        night: data.rangeNight ? 1 : 0,
        air_conditioner: data.electroAirConditioner ? 1 : 0,
        computer: data.electroComputer ? 1 : 0,
        dishwasher: data.electroDishwasher ? 1 : 0,
        dryer: data.electroDryer ? 1 : 0,
        fan_heater: data.electroFanHeater ? 1 : 0,
        kotatsu: data.electroKotatsu ? 1 : 0,
        heat: data.electroFloorHeating ? 1 : 0,
        cooking: data.electroInductionHeating ? 1 : 0,
        region_id: data.city?.regionId
      },
      elec: {},
      gas: {}
    },
    [SPAIN_ID]: {
      common: {
        heat: data.heatingEnergy,
        water: data.waterEnergy,
        cooking: data.cookingEnergy,
        ...(data.tariffOption !== 'unknown' && { tarif_option: data.tariffOption }),
        surface: data.surface,
        nb_people: data.people
      },
      elec: {
        'electro-llinge': data.electroLlinge ? '1' : '0',
        'electro-slinge': data.electroSlinge ? '1' : '0',
        'electro-lvaisselle': data.electroLvaisselle ? '1' : '0',
        'electro-oven': data.electroOven ? 1 : 0,
        'electro-microwave': data.electroMicrowave ? 1 : 0
      },
      gas: {}
    },
    [PORTUGAL_ID]: {
      common: {
        heat: data.heatingEnergy === 'propane' ? 'other' : data.heatingEnergy,
        water: data.waterEnergy === 'propane' ? 'other' : data.waterEnergy,
        cooking:
          data.cookingEnergy === 'propane' ? 'other' : data.cookingEnergy,
        surface: data.surface,
        nb_people: data.people
      },
      elec: {
        'electro-air-conditionne': data.electroAirConditionne ? '1' : '0',
        'electro-llinge': data.electroLlinge ? '1' : '0',
        'electro-slinge': data.electroSlinge ? '1' : '0',
        'electro-lvaisselle': data.electroLvaisselle ? '1' : '0'
      },
      gas: {}
    },
    all: {
      common: {
        site_id: countryId,
        energy
      },
      elec: {},
      gas: {}
    }
  }

  const estimationData = {
    ...configByCountry.all.common,
    ...configByCountry[countryId].common
  }

  if (energy === 'dual') {
    return fetchEstimationData(countryId, {
      ...estimationData,
      ...configByCountry.all.elec,
      ...configByCountry.all.gas,
      ...configByCountry[countryId].elec,
      ...configByCountry[countryId].gas
    })
  }

  return fetchEstimationData(countryId, {
    ...estimationData,
    ...configByCountry.all[energy],
    ...configByCountry[countryId][energy]
  })
}

const compareData = (countryId, energy, data) => {
  const getElecData = () => {
    const isHpHc = data.tariffOption === 'hphc'

    const dataByTariffOption = isHpHc
      ? {
          tarif_option: 'hphc',
          conso_hp: data.consoHp,
          ...(data.consoHf
            ? {
                conso_hc2: data.consoHf
              }
            : null),
          conso_hc: data.consoHc
        }
      : {
          tarif_option: 'base',
          annual_conso_elec: data.annualConsoElec
        }

    const subscribedPower =
      data.subscribedPowerId !== 0
        ? data.subscribedPowerId
        : defaultEnergyPowerByCountry[countryId]

    const configByCountry = countryId => {
      if (countryId === ITALY_ID) {
        return {
          is_resident: data.resident,
          power: data.subscribedPower !== 0
            ? data.subscribedPower
            : defaultEnergyPowerByCountry[countryId],
          tariff_option: data.tariffOption !== 'unknown' ? data.tariffOption : 'base',
          energy_type: data.energyType,
          car_base: data.annualConsoElec,
          car_hp: data.consoHp,
          car_hc: data.consoHc,
          car_hn: data.consoHn
        }
      }
      if (countryId === FRANCE_ID) {
        return {
          power: data.subscribedPower !== 0
            ? data.subscribedPower
            : defaultEnergyPowerByCountry[countryId],
          tariff_option: data.tariffOption !== 'unknown' ? data.tariffOption : 'base',
          energy_type: data.energyType,
          car_base: data.annualConsoElec,
          car_hp: data.consoHp,
          car_hc: data.consoHc
        }
      }
      if (countryId === BELGIUM_ID) {
        return {
          has_exclusive_night: data.hasExclusiveNightTariff === 'yes' ? 1 : 0,
          annual_conso_elec_night:
            data?.annualConsoElecNight !== '' ? data.annualConsoElecNight : 0,
          period: data.period,
          know_tarif_option: data.tariffOption !== 'no' ? 1 : 0,
          know_power: data.subscribedPowerId !== 0 ? 1 : 0,
          promotions: data.withPromos === 'yes' ? 1 : 0,
          ...dataByTariffOption
        }
      }
      if (countryId === JAPAN_ID) {
        return {
          house_type: data.houseType,
          city: data.city.label,
          currentUserSupplier: data.elecCompany.value,
          currentUserPlan: data.ratePlan.value,
          people: data.people,
          choiceMonthlyConsumptionElec_kwh: parseInt(data.monthConsoElec),
          choiceMonthlyConsumptionElec_monthkwh: data.elecMonth,
          choice_power_meter: 'kva',
          power_meter_kva: data.subscribed_power,
          timeFrameProportion_morning: String(+data.rangeMorning),
          timeFrameProportion_afternoon: String(+data.rangeAfternoon),
          timeFrameProportion_night: String(+data.rangeNight),
          annual_conso_elec: 1 // Backend needs it
        }
      }
      if (countryId === SPAIN_ID) {
        return {
          know_tarif_option: data.tariffOption !== 'no' ? 1 : 0,
          invoice_days_elec: data.elecInvoiceDays,
          elec_power: data.subscribedPower,
          ...dataByTariffOption
        }
      }
      if (countryId === PORTUGAL_ID) {
        return {
          subscribed_power: subscribedPower,
          know_tarif_option: data.tariffOption !== 'no' ? 1 : 0,
          know_power: data.subscribedPowerId !== 0 ? 1 : 0,
          ...dataByTariffOption
        }
      }
    }
    const commonData = {}

    return {
      ...commonData,
      ...configByCountry(countryId)
    }
  }

  const getGasData = () => {
    const configByCountry = {
      [ITALY_ID]: {
        car_gas: data.annualConsoGas,
        energy_type: data.energyType
      },
      [FRANCE_ID]: {
        car_gas: data.annualConsoGas,
        energy_type: data.energyType
      },
      [BELGIUM_ID]: {
        period: data.period,
        promotions: data.withPromos === 'yes' ? 1 : 0
      },
      [PORTUGAL_ID]: {},
      [SPAIN_ID]: { invoice_days_gas: data.gasInvoiceDays }
    }

    const commonData = {
      annual_conso_gas: data.annualConsoGas
    }

    return {
      ...commonData,
      ...configByCountry[countryId]
    }
  }

  const getCommonConfig = () => {
    const commonConfig = {
      energy_choice: data.energyType,
      page_type: 'part',
      client_type: 'domestic',
      know_consumption: 1,
      site_id: countryId
    }

    if (data.city?.cityId) {
      commonConfig.city_id = data.city.cityId
    }

    if (data.city?.inseeCode) {
      commonConfig.insee = data.city.inseeCode
    }

    return commonConfig
  }

  const configData = {
    elec: getElecData(),
    gas: getGasData()
  }

  if (energy === 'dual') {
    return fetchData('compare', {
      ...getCommonConfig(),
      ...configData.elec,
      ...configData.gas
    })
  }

  return fetchData('compare', {
    ...getCommonConfig(),
    ...configData[energy]
  })
}

const makeContractInformation = (
  countryId,
  energy,
  data,
  offersData = {},
  contractType = 'part',
  countryCode
) => {
  const configEs = getConfigByCountry('es')
  const configFr = getConfigByCountry('fr')
  const configPt = getConfigByCountry('pt')

  const configByContry = {
    [ITALY_ID]: {
      common: {
        countryCode: 'it',
        contractPage: FORM_PAGE,
        newsletter: data.optionNewsLetter ? 1 : 0,
        energyChoice: data.energyType,
        email: data.email,
        phone1: data.phone,
        maxEconomyBrut: offersData.offers?.max_economy_brut,
        tariffOption: data.tariffOption !== 'unknown' ? data.tariffOption : 'base',
        city: {
          name: data.city?.cityName ?? '',
          postalCode: data.city?.postalCode ?? ''
        }
      },
      elec: {
        meterPower: data.subscribedPower,
        carElectricityHP: data.consoHp,
        carElectricityHC: data.consoHc,
        electricityConsumption: offersData.consommations?.elec
      },
      gas: {
        carGas: data.annualConsoGas
      }
    },
    [FRANCE_ID]: {
      common: {
        contractType: 'part',
        energyChoice: data.energyType,
        city: {
          name: data.city?.cityName ?? '',
          postalCode: data.city?.postalCode ?? '',
          INSEE:
            data?.city?.inseeCode ?? data?.address?.citycode ?? ''
        },
        phone1: data.phone,
        email: data.email,
        prestationType: data.prestationType,
        heatingEnergy: data.heatingEnergy,
        cookingEnergy: data.cookingEnergy,
        hotwaterEnergy: data.waterEnergy,
        newsletter: data.optionNewsLetter ? 1 : 0,
        street: data.street,
        streetAutocomplete: data.streetAutocomplete,
        surface: data.surface,
        leadSource: getCampaignData().leadSource ?? data.leadSource,
        knowPdl: data?.knowPdl === 'yes',
        campaignData: getCampaignData()
      },
      elec: {
        firstName: data.firstName,
        lastName: data.lastName,
        PDL: data.pdl,
        meterPower: data.subscribedPower,
        carElectricityBase: data.annualConsoElec,
        carElectricityHP: data.consoHp,
        carElectricityHC: data.consoHc,
        tariffOption: data.tariffOption !== 'unknown' ? data.tariffOption : 'base'
      },
      gas: {
        carGas: data.annualConsoGas,
        gasZone: offersData?.consommations?.gasZone
      },
      pro: {
        energyChoice: data.energyType,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phone1: data.phone,
        proSocialReason: data.proSocialReason,
        prestationType: data.prestationType,
        contractType: PROFESSIONALS_PAGE,
        leadSource: configFr.leadSources.mainFormPro
      }
    },
    [BELGIUM_ID]: {
      common: {
        contractPage: FORM_PAGE,
        countryCode,
        newsletter: data.email !== '' && data.optionNewsLetter ? 1 : 0,
        energyChoice: data.energyType,
        email: data.email,
        phone1: data.phone,
        tariffOption: data.tariffOption !== 'unknown' ? data.tariffOption : 'base',
        city: {
          name: data.city?.label ?? ''
        },
        campaignData: getCampaignData()
      },
      elec: {
        carElectricityHP: data.consoHp,
        carElectricityHC: data.consoHc,
        electricityConsumption: offersData?.consommations?.elec,
        annualConsoElecNight:
          data?.annualConsoElecNight !== '' ? data.annualConsoElecNight : 0
      },
      gas: {
        carGas: data.annualConsoGas
      }
    },
    [JAPAN_ID]: {
      common: {
        city: {
          name: data.city?.label ?? ''
        },
        countryCode: 'jp',
        energyChoice: data.energyType,
        email: data.email,
        newsletter: data.optionNewsLetter ? 1 : 0,
        contractPage: FORM_PAGE,
        phone1: data.phone,
        ratePlan: data.ratePlan?.value,
        houseType: data.houseType
      },
      elec: {
        electricityConsumption: offersData?.consommations?.elec,
        maxEconomyBrut: offersData.offers?.max_economy_brut
      },
      gas: {}
    },
    [SPAIN_ID]: {
      common: {
        contractPage: FORM_PAGE,
        countryCode: 'es',
        newsletter: data.optionNewsLetter ? 1 : 0,
        energyChoice: data.energyType,
        email: data.email,
        phone1: data.phone,
        tariffOption: data.tariffOption,
        campaignData: getCampaignData(),
        leadSource: data.leadSource ?? configEs.leadSources.mainForm,
        maxEconomyBrut: offersData.offers?.max_economy_brut,
        meterPower: data.subscribedPower
      },
      elec: {
        carElectricityHP: data.consoHp,
        carElectricityHF: data.consoHf,
        carElectricityHC: data.consoHc,
        electricityConsumption: offersData?.consommations?.elec,
        elecInvoiceDays: data.elecInvoiceDays ?? null
      },
      gas: {
        carGas: data.annualConsoGas,
        gasInvoiceDays: data.gasInvoiceDays ?? null
      }
    },
    [PORTUGAL_ID]: {
      common: {
        contractPage: FORM_PAGE,
        countryCode: 'pt',
        newsletter: data.optionNewsLetter ? 1 : 0,
        energyChoice: data.energyType,
        email: data.email,
        phone1: data.phone,
        tariffOption: data.tariffOption,
        campaignData: getCampaignData(),
        leadSource: data.leadSource ?? configPt.leadSources.mainForm,
        maxEconomyBrut: offersData.offers?.max_economy_brut,
        meterPower: data.subscribedPower
      },
      elec: {
        carElectricityHP: data.consoHp,
        carElectricityHF: data.consoHf,
        carElectricityHC: data.consoHc,
        electricityConsumption: offersData?.consommations?.elec,
        elecInvoiceDays: data.elecInvoiceDays ?? null
      },
      gas: {
        carGas: data.annualConsoGas,
        gasInvoiceDays: data.gasInvoiceDays ?? null
      }
    }
  }

  if (contractType === PROFESSIONALS_PAGE) {
    return configByContry[countryId].pro
  }

  if (energy === 'dual') {
    return countryId === FRANCE_ID
      ? {
          ...configByContry[countryId].common,
          ...{
            meterPower: data.subscribedPower,
            carElectricityBase: data.annualConsoElec,
            carElectricityHP: data.consoHp,
            carElectricityHC: data.consoHc,
            tariffOption: data.tariffOption !== 'unknown' ? data.tariffOption : 'base'
          },
          ...configByContry[countryId].gas
        }
      : {
          ...configByContry[countryId].common,
          ...configByContry[countryId].elec,
          ...configByContry[countryId].gas
        }
  }

  return {
    ...configByContry[countryId].common,
    ...configByContry[countryId][energy]
  }
}

export { getEstimationData, compareData, makeContractInformation }
