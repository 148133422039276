import classes from './Input.module.scss'
import withFormField from '../Hoc/withFormField'
import withAddons from '../Hoc/withAddons'

const Input = ({
  field: { ref, value, onChange, onBlur: handleOnBlur },
  id,
  changeHandler: outerChangeHandler,
  inputMode,
  disabled,
  readOnly,
  extraClasses,
  autoFocus,
  dataCy,
  placeholder,
}) => {
  const inputClasses = [classes.Input__input, 'Field__control']
  Array.isArray(extraClasses) && inputClasses.push(...extraClasses)

  return (
    <input
      ref={ref}
      id={id}
      type='text'
      className={inputClasses.join(' ')}
      value={value}
      onChange={event => {
        outerChangeHandler?.(event)
        !event.defaultPrevented && onChange(event.target.value)
      }}
      onBlur={handleOnBlur}
      readOnly={readOnly === true}
      disabled={disabled === true}
      inputMode={inputMode || 'text'}
      autoComplete='off'
      autoFocus={autoFocus}
      data-cy={dataCy}
      placeholder={placeholder}
    />
  )
}

const FormInput = withFormField(withAddons(Input))

export { Input, FormInput as default }
