import useBreakpoint from '@hooks/useBreakpoint'
import { useNavigate } from 'react-router-dom'
import { apiContacts } from '@/utilities/comparator'
import { phone } from '@/utilities/phone'
import Icon from '@components/Icon'
import Button from '@components/Button/Button'
import classes from './CTASelectraSubscriptionButton.module.scss'
import { useTranslation } from 'react-i18next'
import useCheckout from '@features/Checkout/useCheckout'
import { clearModal } from '@root/reducers/modal/modalSlice'
import { useDispatch, useSelector } from 'react-redux'
import { selectContractData } from '@root/reducers/contract/contractSelectors'
import {
  selectUserData,
  selectUserExperimentId,
} from '@root/reducers/user/userSelectors'

const CTASelectraSubscriptionButton = ({ hidden }) => {
  const { t } = useTranslation(['checkout'])
  const breakpoint = useBreakpoint()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { phone1, knowConsumption, energyChoice, pdl, email, offerId } =
    useCheckout()
  const { knowPdl, firstName, lastName, street, prestationType } =
    useSelector(selectContractData)
  const { urlOrigin } = useSelector(selectUserData)
  const experimentId = useSelector(selectUserExperimentId)

  if (hidden) {
    return null
  }

  return (
    <Button
      id='subscribe-button'
      extraClasses={[
        'checkout-subscription-button',
        classes.CTASelectraSubscriptionButton,
      ]}
      pill
      block
      size='md'
      clickHandler={() => {
        phone1 &&
          apiContacts({
            phone: phone(phone1, { country: 'FR' }).phoneNumber,
            page: 'checkout-online-details',
            knowConsumption,
            energyChoice,
            pdl,
            email,
            knowPdl,
            firstName,
            lastName,
            address: street?.label,
            prestationType,
            urlOrigin,
            experimentId,
          })
        dispatch(clearModal())
        navigate(`/checkout/${offerId}`, {
          state: { pageStep: 0 },
        })
      }}
      dataCy='subscription-button'
    >
      {['xs', 'sm'].includes(breakpoint)
        ? t('checkout:quick subscription')
        : t('checkout:quick online subscription')}
      <Icon
        iconId='icon-arrow-right'
        fill='primary'
        size='md'
        position='right'
        extraClasses={[classes.CTASelectraSubscriptionButton__icon]}
      />
    </Button>
  )
}

export { CTASelectraSubscriptionButton as default }
