import DOMPurify from 'dompurify'
import { forwardRef } from 'react'
import classes from './Card.module.scss'

const CardWithRef = (
  { children, rounded, color, bordered, height, extraClasses, hidden },
  ref
) => {
  const cardClasses = [classes.Card]

  const borderedClasses = {
    solid: classes['Card--bordered-solid'],
    dashed: classes['Card--bordered-dashed'],
  }

  const heightClasses = {
    2: classes['Card--height-2'],
    4: classes['Card--height-4'],
    8: classes['Card--height-8'],
    16: classes['Card--height-16'],
    24: classes['Card--height-24'],
  }

  const colorClasses = {
    white: classes['Card--white'],
    neutral: classes['Card--grey'],
    primary: classes['Card--primary'],
    secondary: classes['Card--secondary'],
    success: classes['Card--success'],
    danger: classes['Card--danger'],
    background: classes['Card--background'],
    notice: classes['Card--notice'],
  }

  rounded && cardClasses.push(classes[`Card--rounded-${rounded}`])
  bordered && cardClasses.push(borderedClasses[bordered])
  height && cardClasses.push(heightClasses[height])
  cardClasses.push(colorClasses[color || 'white'])

  Array.isArray(extraClasses) && cardClasses.push(...extraClasses)

  if (hidden) {
    return null
  }

  return (
    <div
      ref={ref}
      className={cardClasses.join(' ')}
      dangerouslySetInnerHTML={
        typeof children === 'string'
          ? {
              __html: DOMPurify.sanitize(children),
            }
          : null
      }
    >
      {typeof children !== 'string' ? children : null}
    </div>
  )
}

export default forwardRef(CardWithRef)
