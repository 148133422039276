/**
 * @function padNum
 * @description Convert a number into a string with leading zeroes.
 * @param {number} number - The number to convert.
 * @param {number} [length=2] - Total number of digits for the padded string.
 * @returns {string}
 */
function padNum(number, length = 2) {
  return ('0'.repeat(length) + number).slice(-length)
}

/**
 * Force a value into a numerical range
 *
 * @example
 * clamp( 2, 0, 1 )    // 1
 * clamp( 0.5, 0, 1 )  // 0.5
 * @param {number} n
 *   Value to clamp.
 * @param {number} rangeMin
 *   Lower value of the range.
 * @param {number} rangeMax
 *   higher value of the range.
 * @returns {number} clamped
 */
function clamp(n, rangeMin = 0, rangeMax = 1) {
  return Math.min(rangeMax, Math.max(rangeMin, n))
}

function toFixed(n, decimals = 2) {
  const factor = decimals > 0 ? Math.pow(10, decimals) : 10
  return Number(Math.round((n + Number.EPSILON) * factor) / factor).toFixed(
    decimals
  )
}

function parsePrice(price) {
  if (typeof price === 'string') {
    return Number.parseFloat(price?.replace(',', '.'))
  }

  return price ?? 0
}

function isNumeric(number) {
  return !isNaN(parseFloat(number)) && isFinite(number)
}

function variableToNumber(number) {
  return isNumeric(number) ? +number : 0
}

export { padNum, clamp, toFixed, parsePrice, isNumeric, variableToNumber }
