export const siteRoutes = [
  // NotFound wildcard
  {
    path: '*',
    component: 'NotFound',
  },
  // Default Route
  {
    path: '',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
      showEnergySelector: true,
    },
  },
  // Estimation Form Page
  {
    path: 'vergelijking',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
      showEnergySelector: true,
    },
  },
  {
    path: 'vergelijking-elektriciteit',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  {
    path: 'vergelijking-gas',
    component: 'EstimationForm',
    props: {
      configModuleId: 'gasEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'gas',
      pageType: 'part',
    },
  },
  {
    path: 'vergelijking-elektriciteit-gas',
    component: 'EstimationForm',
    props: {
      configModuleId: 'dualEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'dual',
      pageType: 'part',
    },
  },
  // Product List Page
  {
    path: 'elektriciteit',
    component: 'ProductList',
    props: {
      formConfigModuleId: 'elecEstimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  {
    path: 'gas',
    component: 'ProductList',
    props: {
      formConfigModuleId: 'gasEstimationForm',
      energyType: 'gas',
      pageType: 'part',
    },
  },
  {
    path: 'elektriciteit-gas',
    component: 'ProductList',
    props: {
      formConfigModuleId: 'dualEstimationForm',
      energyType: 'dual',
      pageType: 'part',
    },
  },
  // Product Page
  {
    path: 'aanbod/*',
    component: 'ProductPage',
  },
  // Checkout
  {
    path: 'checkout/*',
    component: 'Checkout',
    props: {
      siteId: 1,
      singleOfferTunnel: false,
    },
  },
  // Single electricity offer tunnel
  {
    path: 'estimation-elektriciteit/*',
    component: 'Checkout',
    props: {
      siteId: 1,
      singleOfferTunnel: true,
    },
  },
  // Single gas offer tunnel
  {
    path: 'estimation-gas/*',
    component: 'Checkout',
    props: {
      siteId: 1,
      singleOfferTunnel: true,
    },
  },
  // Single dual offer tunnel
  {
    path: 'estimation-dual/*',
    component: 'Checkout',
    props: {
      siteId: 1,
      singleOfferTunnel: true,
    },
  },
  {
    path: 'clear-data',
    component: 'ClearRedux',
  },
]

export default siteRoutes
