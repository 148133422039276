import { formatDate } from './date-utils'
import { phone } from './phone'
import { t } from 'i18next'
import apiEndpoints from '@root/utilities/apiEndPoints'

const getHopOfferConfig = async (
  offerId,
  energyType,
  contractType,
  prestationType
) => {
  const response = await fetch(
    `${apiEndpoints.hopOfferInformation}/${offerId}/${energyType}/${contractType}`
  )
  if (response.status === 204) {
    return null
  }
  if (response.status !== 200) {
    throw new window.Error('Non 200 response status')
  }
  const hopOfferConfiguration = (await response.json()).offerConfiguration
  return {
    provider: hopOfferConfiguration.providerName,
    name: hopOfferConfiguration.offerName,
    invalidBIC: hopOfferConfiguration.bannedBic?.split(',') || [],
    paymentsPerYear: Number.parseInt(hopOfferConfiguration.paymentsPerYear, 10),
    hasMonthlyAmountEndpoint: hopOfferConfiguration.hasMonthlyAmountEndpoint,
    effectiveDateOffset:
      prestationType === 'mes'
        ? Number.parseInt(hopOfferConfiguration.mesContractDelay, 10)
        : Number.parseInt(hopOfferConfiguration.cdfContractDelay, 10),
    formFields: {
      dateOfBirth: {
        isNeeded: hopOfferConfiguration.birthdayMandatory === 'active'
      },
      phone1: {
        label: t('common:mobile phone'),
        phone1Accepted: hopOfferConfiguration.phone1Accepted
      },
      paymentDate: Object.assign(
        {
          isNeeded: hopOfferConfiguration.paymentDateAvailable === 'active',
          type: hopOfferConfiguration.paymentDateType
        },
        hopOfferConfiguration.paymentDateType === 'range'
          ? {
              min: hopOfferConfiguration.paymentDateRange.split(';')[0],
              max: hopOfferConfiguration.paymentDateRange.split(';')[1]
            }
          : null,
        hopOfferConfiguration.paymentDateType === 'fixe'
          ? {
              dates: hopOfferConfiguration.paymentDateRange.split(';')
            }
          : null
      ),
      mainIndexElec: {
        isNeeded:
          prestationType === 'mes'
            ? hopOfferConfiguration.mesIndexMandatory === 'active'
            : hopOfferConfiguration.cdfIndexMandatory === 'active'
      },
      secondaryIndexElec: {
        isNeeded:
          prestationType === 'mes'
            ? hopOfferConfiguration.mesIndexMandatory === 'active'
            : hopOfferConfiguration.cdfIndexMandatory === 'active'
      }
    },
    strings: {
      12: {
        paymentProductPage:
          "Prélèvement bancaire mensuel, facture de régularisation annuelle ou semestrielle (d'après votre consommation réelle)."
      },
      11: {
        paymentProductPage:
          "Prélèvement bancaire mensuel pendant 11 mois puis facture de régularisation (d'après votre consommation réelle) le 12ème mois."
      },
      10: {
        paymentProductPage:
          'Prélèvement bancaire mensuel pendant 10 mois puis facture de régularisation (d\'après votre consommation réelle) le 11ème mois".'
      }
    }[hopOfferConfiguration.paymentsPerYear]
  }
}

const getOrUpdateEmailToken = async (values, source) => {
  let tokenId = getEmailToken()
  if (tokenId) {
    tokenId = await sendWebLead(
      {
        ...values,
        tokenId
      },
      source
    )
  } else {
    tokenId = await sendWebLead(values, source)
  }
  return tokenId
}

const getEmailToken = () => {
  if (!emailTokenIsValid) {
    return null
  }

  const json = window.localStorage.getItem('email-token')
  return json ? JSON.parse(json).value : null
}

const saveEmailToken = tokenId => {
  !window.localStorage.getItem('email-token') &&
    window.localStorage.setItem(
      'email-token',
      JSON.stringify({
        value: tokenId,
        expiry: new Date().getTime() + 8640000
      })
    )
}

const removeEmailToken = (force = false) => {
  if (force || !emailTokenIsValid()) {
    window.localStorage.removeItem('email-token')
  }
}

const emailTokenIsValid = () => {
  const json = window.localStorage.getItem('email-token')

  if (!json) {
    return false
  }

  const data = JSON.parse(json)

  return new Date().getTime() < data.expiry
}

const formatValuesForHop = ({
  tokenId,

  offerId,

  email,
  phone1,
  firstName,
  lastName,
  civility,
  dateOfBirth,

  firstName2,
  lastName2,
  civility2,

  city,
  street,
  building,
  staircase,
  floor,
  apartmentNumber,
  additionalInfo,

  invoiceCountry,
  invoiceCity,
  invoiceStreet,
  invoiceBuilding,
  invoiceStaircase,
  invoiceFloor,
  invoiceApartmentNumber,
  invoiceAdditionalInfo,

  PDL,
  PCE,
  meterPower,
  meterConsentAcceptation,
  readingDateElec,
  mainIndexElec,
  secondaryIndexElec,
  tariffOption,
  consentAcceptation,
  carElectricityBase,
  carElectricityHC,
  carElectricityHP,

  meterIdGas,
  readingDateGas,
  mainIndexGas,
  secondaryIndexGas,
  carGas,

  housingType,
  residenceType,
  heatingEnergy,
  cookingEnergy,
  hotwaterEnergy,
  surface,

  energyChoice,
  contractType,
  paymentDate,
  paymentMode,
  invoiceFrequency,
  invoiceSendingMode,

  monthlyPaymentTotal,
  monthlyPaymentElec,
  monthlyPaymentGas,
  prestationType,
  contractEffectiveDate,

  bypassRetractationElec,
  bypassRetractationGas,

  IBAN,
  BIC,

  callback,

  leadSource,

  campaignData,

  newsletter,
  proSocialReason
}) =>
  Object.assign(
    Object.create(null),
    tokenId ? { email_token: tokenId } : null,
    offerId ? { offer: offerId } : null,
    email ? { email } : null,
    phone1
      ? {
          phone1: phone(phone1, { country: 'FR', validateMobilePrefix: false })
            .phoneNumber
        }
      : null,

    firstName ? { firstname: firstName } : null,
    lastName ? { lastname: lastName } : null,
    civility ? { civility } : null,
    dateOfBirth && dateOfBirth.split('/').length === 3
      ? {
          birthday: (() => {
            const [day, month, year] = dateOfBirth.split('/')
            return formatDate(new Date(year, month - 1, day), '-', [
              'year',
              'month',
              'day'
            ])
          })()
        }
      : null,

    firstName2 ? { firstname2: firstName2 } : null,
    lastName2 ? { lastname2: lastName2 } : null,
    civility2 ? { civility2 } : null,

    city
      ? {
          city: city.name || '',
          zipcode: city.postalCode || '',
          city_id: city.INSEE || ''
        }
      : null,

    street?.name ? { conso_street_name: street.street } : null,

    street?.housenumber ? { street_number: street.housenumber } : null,

    building ? { building } : null,
    staircase ? { staircase } : null,
    floor ? { floor } : null,
    apartmentNumber ? { apartment_number: apartmentNumber } : null,
    additionalInfo ? { additional_information: additionalInfo } : null,

    invoiceCountry ? { fact_country: invoiceCountry } : null,
    invoiceCity
      ? {
          fact_city: invoiceCity.cityName || '',
          fact_zipcode: invoiceCity.postalCode || '',
          fact_city_id: invoiceCity.INSEE || ''
        }
      : null,
    invoiceStreet
      ? {
          fact_conso_street_name: invoiceStreet.name,
          fact_street_number: invoiceStreet.number
        }
      : null,
    invoiceBuilding ? { fact_building: invoiceBuilding } : null,
    invoiceStaircase ? { fact_staircase: invoiceStaircase } : null,
    invoiceFloor ? { fact_floor: invoiceFloor } : null,
    invoiceApartmentNumber
      ? { fact_apartment_number: invoiceApartmentNumber }
      : null,
    invoiceAdditionalInfo
      ? { fact_additional_information: invoiceAdditionalInfo }
      : null,

    PDL ? { meter_id_elec: PDL } : null,
    PCE ? { meter_id_gas: PCE } : null,
    readingDateElec ? { reading_date_elec: readingDateElec } : null,
    mainIndexElec ? { main_index_elec: mainIndexElec } : null,
    secondaryIndexElec ? { secondary_index_elec: secondaryIndexElec } : null,
    meterPower ? { meter_power_elec: parseInt(meterPower, 10) } : null,
    meterConsentAcceptation
      ? { meter_consent_acceptation: meterConsentAcceptation }
      : null,
    tariffOption ? { meter_option_elec: tariffOption } : null,
    consentAcceptation
      ? { meter_consent_acceptation: consentAcceptation }
      : null,
    carElectricityBase ? { car_elec_base: carElectricityBase } : null,
    carElectricityHP ? { car_elec_hp: Math.round(carElectricityHP) } : null,
    carElectricityHC ? { car_elec_hc: Math.round(carElectricityHC) } : null,

    meterIdGas ? { meter_id_gas: meterIdGas } : null,
    readingDateGas ? { reading_date_gas: readingDateGas } : null,
    mainIndexGas ? { reading_date_gas: mainIndexGas } : null,
    secondaryIndexGas ? { reading_date_gas: secondaryIndexGas } : null,
    carGas ? { car_gas: carGas } : null,

    housingType ? { housing_type: housingType } : null,
    residenceType ? { conso_residence_type: residenceType } : null,
    heatingEnergy ? { heating_energy: heatingEnergy } : null,
    cookingEnergy ? { cooking_energy: cookingEnergy } : null,
    hotwaterEnergy ? { hotwater_energy: hotwaterEnergy } : null,
    surface ? { surface } : null,

    energyChoice ? { energy_type: energyChoice } : null,
    contractType ? { contract_type: contractType } : null,
    // TODO: this is so hacky... think about a generalised solution to treat
    //  null, falsy and undefined cases
    { payment_date: paymentDate || 'null' },
    paymentMode ? { payment_mode: paymentMode } : null,
    invoiceFrequency ? { invoice_frequency: invoiceFrequency } : null,
    invoiceSendingMode ? { invoice_sending_mode: invoiceSendingMode } : null,

    monthlyPaymentTotal
      ? {
          monthly_amount_calculated: monthlyPaymentTotal,
          monthly_amount_elec: monthlyPaymentElec ?? 0,
          monthly_amount_gas: monthlyPaymentGas ?? 0
        }
      : null,
    { prestation_type: prestationType ?? 'cdf' },
    contractEffectiveDate
      ? {
          elec: {
            effective_date_elec: formatDate(contractEffectiveDate, '-', [
              'year',
              'month',
              'day'
            ])
          },
          gas: {
            effective_date_gas: formatDate(contractEffectiveDate, '-', [
              'year',
              'month',
              'day'
            ])
          },
          dual: {
            effective_date_elec: formatDate(contractEffectiveDate, '-', [
              'year',
              'month',
              'day'
            ]),
            effective_date_gas: formatDate(contractEffectiveDate, '-', [
              'year',
              'month',
              'day'
            ])
          }
        }[energyChoice]
      : null,

    bypassRetractationElec
      ? { bypass_retractation_elec: bypassRetractationElec ? 1 : 0 }
      : null,
    bypassRetractationGas
      ? { bypass_retractation_gas: bypassRetractationGas ? 1 : 0 }
      : null,

    IBAN ? { iban: IBAN } : null,
    BIC ? { bic: BIC } : null,

    callback ? { web_callback: callback } : null,

    leadSource ? { web_sales_lead_source: leadSource } : null,

    campaignData
      ? {
          campaign_name: campaignData.name,
          campaign_medium: campaignData.medium,
          campaign_content: campaignData.content
        }
      : null,

    newsletter ? { newsletter } : null,
    proSocialReason ? { pro_social_reason: proSocialReason } : null
  )

const generateSalesComparatorPayload = async (values, source) => ({
  ...formatValuesForHop(values),
  // Hardcoded
  source,
  redirect: 0
})

const sendWebLead = async (values, source, contractType = 'part') => {
  let tokenId
  const webLeadError = new Error('webLead-error')
  if (values.phone1) {
    let response
    const payload = await generateSalesComparatorPayload(
      {
        ...values,
        tokenId: getEmailToken(),
        contractType
      },
      values.campaignData?.source ?? source
    )
    const endPointUrl = new URL(apiEndpoints.hopSalesContacts)
    Object.entries(payload).forEach(entry => {
      endPointUrl.searchParams.set(entry[0], `${entry[1]}`)
    })
    try {
      response = await window.fetch(endPointUrl.href, {
        method: 'GET',
        mode: 'cors',
        headers: {
          Accept: 'application/json, text/javascript,text/html, */*; q=0.01'
        }
      })
      if (response.status !== 200) {
        throw new Error()
      }
    } catch {
      throw webLeadError
    }
    tokenId = (await response.json()).id
    saveEmailToken(tokenId)
  }
  return tokenId
}

const generateSalesSavePayload = async (values, source) => {
  const tokenId = await getOrUpdateEmailToken(values, source)

  const payload = formatValuesForHop({
    // Usually, our form values
    ...values,
    tokenId,
    // Hardcoded
    housingType: 'house',
    addressType: 'main',
    residenceType: 'main',
    carGas: 0,
    meterConsentAcceptation: formatDate(
      new Date(),
      '-',
      ['year', 'month', 'day'],
      true
    ),
    paymentMode: 'prlvmt',
    invoiceFrequency: 'annual',
    invoiceSendingMode: 'mail',
    contractType: 'part',
    energyType: 'elec'
  })

  return {
    // Formatted data
    ...payload,
    // Tricks and traps
    action_requested: 'save_and_send',
    noRedirect: 1,
    source,
    _source_name: source,
    step: 2,
    _current_step_num: 2
  }
}

const sendSubscription = async (values, source) => {
  const endpointUrl = new URL(apiEndpoints.hopSalesSave)
  const payload = await generateSalesSavePayload(values, source)
  Object.entries(payload).forEach(entry => {
    endpointUrl.searchParams.set(entry[0], `${entry[1]}`)
  })
  const response = await window.fetch(
    `${endpointUrl.origin}${endpointUrl.pathname}`,
    {
      method: 'POST',
      mode: 'cors',
      body: endpointUrl.searchParams,
      headers: {
        accept: 'application/json'
      }
    }
  )
  if (response.status !== 200) {
    throw new Error('subscription/save: non 200 response status')
  }
  return response
}

export {
  getEmailToken,
  saveEmailToken,
  removeEmailToken,
  sendSubscription,
  sendWebLead,
  getHopOfferConfig,
  formatValuesForHop
}
