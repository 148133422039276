import classes from './ReviewsItem.module.scss'
import { formatDate } from '@/utilities/date-utils'
import Icon from '../Icon'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

const ReviewsItem = ({ date, rating, userName, userComment }) => {
  const { t } = useTranslation(['common'])

  const friendlyRating = Math.round(rating, 0)
  const CHAR_LIMIT = 300
  const comment = userComment ? userComment.slice(0, CHAR_LIMIT) : null
  const [showShortVersion, setShowShortVersion] = useState(true)
  const moreInfoIconClasses = [classes['ReviewsItem__more-icon']]
  !showShortVersion &&
    moreInfoIconClasses.push(classes['ReviewsItem__more-icon--open'])
  const dateParts = date.split('/')

  // month is 0-based, that's why we need dataParts[1] - 1
  const dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
  return (
    <li className={classes.ReviewsItem}>
      <div className={classes.ReviewsItem__header}>
        <Icon
          iconId='icon-star-full'
          size='lg'
          extraClasses={[classes['ReviewsItem__header--star-icon']]}
        />
        <h3 className={classes['ReviewsItem__header--rating']}>
          {friendlyRating} {t('common:on')} 5
        </h3>
        <h3 className={classes['ReviewsItem__header--name']}>{userName}</h3>
        <span className={classes['ReviewsItem__header--date']}>
          {formatDate(dateObject)}
        </span>
      </div>
      <p
        className={classes.ReviewsItem__review}
        dangerouslySetInnerHTML={{
          __html:
            showShortVersion && comment !== userComment
              ? `${comment}...`
              : userComment
        }}
      />
      {comment !== userComment && (
        <>
          <Icon
            iconId='icon-chevron-down'
            size='xs'
            extraClasses={moreInfoIconClasses}
            verticalAlignMiddle
            clickHandler={() => {
              setShowShortVersion(!showShortVersion)
            }}
          />
          <span
            className={classes['ReviewsItem__more-button']}
            onClick={() => {
              setShowShortVersion(!showShortVersion)
            }}
          >
            {showShortVersion ? t('common:read more') : t('common:read less')}
          </span>
        </>
      )}
    </li>
  )
}

export { ReviewsItem as default }
