import defaultCountryConfig from '@root/config/engines'
import configFr from '@root/engines/fr/config/main'
import configIt from '@root/engines/it/config/main'
import configBeFr from '@root/engines/be-fr/config/main'
import configBeNl from '@root/engines/be-nl/config/main'
import configJp from '@root/engines/jp/config/main'
import configEs from '@root/engines/es/config/main'
import configPt from '@root/engines/pt/config/main'
import { globalConfig, globalConfigByEnv } from '@root/config/global'
import deepmerge from 'deepmerge'

const configByCountry = {
  fr: configFr,
  it: configIt,
  beFr: configBeFr,
  beNl: configBeNl,
  jp: configJp,
  es: configEs,
  pt: configPt,
  default: defaultCountryConfig,
}

export const getConfigByCountry = country => {
  const defaultConfig = configByCountry.default
  const countryConfig = configByCountry[country] ?? {}
  return deepmerge(defaultConfig, countryConfig)
}

export const getEnvAndCountry = () => {
  for (const country of Object.keys(configByCountry)) {
    if (country === 'default') {
      continue
    }
    const config = getConfigByCountry(country)

    const env = Object.keys(config.urls.app).find(
      key => config.urls.app[key] === window.location.host
    )

    if (env) {
      return {
        env,
        country: env === 'local' ? globalConfig.defaultCountry : country,
      }
    }
  }
  return {
    env: null,
    country: null,
  }
}

export const { env, country } = getEnvAndCountry()
export const isLocalEnv = env === 'local'

export const getSiteConfig = () => {
  return getConfigByCountry(country)
}

export const getGlobalConfig = () => {
  return deepmerge(globalConfig, globalConfigByEnv[env ?? 'local'])
}
