import classes from '@features/ProductPage/CTA/Buttons/CTASubscriptionModalButton/CTASubscriptionModalButton.module.scss'
import ContactCard from 'src/common/components/ContactCard'
import { useSelector } from 'react-redux'
import { selectModalData } from '@root/reducers/modal/modalSelectors'
import CTAProviderSubscriptionButton from '@features/ProductPage/CTA/Buttons/CTAProviderSubscriptionButton'
import CTASelectraSubscriptionButton from '@features/ProductPage/CTA/Buttons/CTASelectraSubscriptionButton'
import useCheckout from '@features/Checkout/useCheckout'

export default function CTASubscriptionModal() {
  const {
    withCallback,
    withSchedule,
    title,
    withIcons,
    phoneVisual,
    phone,
    displayPhoneNumber,
    subscriptionButton,
    hideContactCard,
    externalLink,
    flagExtraClasses,
    subscriptionUrl,
  } = useSelector(selectModalData)

  const { rappelUrl } = useCheckout()

  const SubscriptionButtonComponent = () => {
    const subscriptionButtonComponents = {
      selectraSubscriptionButton: <CTASelectraSubscriptionButton />,
      providerSubscriptionButton: (
        <CTAProviderSubscriptionButton
          color='primary'
          url={subscriptionUrl || rappelUrl}
          withIcon
        />
      ),
    }
    return subscriptionButtonComponents[subscriptionButton]
  }

  return (
    <>
      <h3 className={classes.CTASubscriptionModalButton__title}>{title}</h3>
      <ContactCard
        id='cmb-product'
        withCallback={withCallback}
        withSchedule={withSchedule}
        withIcons={withIcons}
        externalLink={externalLink}
        phoneVisual={phoneVisual}
        phone={phone}
        displayPhoneNumber={displayPhoneNumber}
        extraClasses={[classes['CTASubscriptionModalButton__contact-card']]}
        flagExtraClasses={flagExtraClasses}
        hidden={hideContactCard}
      />
      <SubscriptionButtonComponent />
    </>
  )
}
