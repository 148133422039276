import withFormField from '../Hoc/withFormField'
import Icon from '../Icon'
import Button from '../Button/Button'
import { clamp } from '@/utilities/number-utils'
import classes from './Stepper.module.scss'

const Stepper = ({
  field,
  disabled,
  minValue,
  maxValue,
  spriteUrl,
  inputMode
}) => {
  const changeValue = newValue => {
    field.onChange(clamp(newValue, minValue || 0, maxValue || Infinity))
  }
  const handleOnBlur = field.onBlur
  return (
    <label>
      <div className={classes.Stepper}>
        <Button
          name='decrement-button'
          extraClasses={[classes.Stepper__button]}
          clickHandler={() => changeValue(parseInt(field.value, 10) - 1)}
          disabled={parseInt(field.value, 10) === minValue || disabled === true}
          tabIndex='-1'
        >
          <Icon
            spriteUrl={spriteUrl}
            iconId='icon-minus'
            size='xs'
            tabIndex='-1'
          />
        </Button>

        <input
          type='text'
          onBlur={handleOnBlur}
          className={[classes.Stepper__input, 'Field__control'].join(' ')}
          value={field.value}
          disabled={disabled === true}
          onChange={event => {
            const value = parseInt(event.target.value, 10)
            // TODO: Can't enter negative numbers with keyboard
            changeValue(Number.isInteger(value) ? value : 0)
          }}
          inputMode={inputMode}
          readOnly
        />
        <Button
          name='increment-button'
          extraClasses={[classes.Stepper__button]}
          clickHandler={() => changeValue(parseInt(field.value, 10) + 1)}
          disabled={parseInt(field.value, 10) === maxValue || disabled === true}
          tabIndex='-1'
        >
          <Icon
            spriteUrl={spriteUrl}
            iconId='icon-plus'
            size='xs'
            tabIndex='-1'
          />
        </Button>
      </div>
    </label>
  )
}

export default withFormField(Stepper)
