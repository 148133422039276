import { forwardRef } from 'react'
import Icon from '@components/Icon'
import PopUp from '@components/PopUp'
import formClasses from './WithFormField.module.scss'
import classes from './HelpTooltip.module.scss'
import { useDispatch } from 'react-redux'
import {
  updateModalComponent,
  updateModalProps,
} from '@root/reducers/modal/modalSlice'

const HelpTriggerIconWithRef = (
  { clickHandler, keyPressHandler, isOpen, id },
  ref
) => {
  const iconClasses = [formClasses['FormField__label-help-icon']]
  isOpen && iconClasses.push(formClasses['FormField__label-help-icon--active'])
  return (
    <Icon
      id={id}
      ref={ref}
      iconId='circle-interrogation-filled'
      fill='neutral-light'
      extraClasses={iconClasses}
      clickHandler={clickHandler}
      keyPressHandler={keyPressHandler}
      tabIndex='0'
    />
  )
}

const HelpTooltip = ({
  type,
  children,
  triggerWrapperExtraClasses,
  modalComponent,
  modalProps,
  modalId,
  ...passTroughProps
}) => {
  const dispatch = useDispatch()

  if (modalComponent) {
    const TriggerIcon = forwardRef(HelpTriggerIconWithRef)
    return (
      <TriggerIcon
        id={modalId}
        clickHandler={() => {
          dispatch(updateModalProps(modalProps))
          dispatch(updateModalComponent(modalComponent))
        }}
      />
    )
  }

  if (!Array.isArray(triggerWrapperExtraClasses)) {
    triggerWrapperExtraClasses = []
  }
  triggerWrapperExtraClasses.push(classes.HelpTooltip)

  return (
    <PopUp
      TriggerComponent={forwardRef(HelpTriggerIconWithRef)}
      triggerWrapperExtraClasses={triggerWrapperExtraClasses}
      {...passTroughProps}
    >
      {children}
    </PopUp>
  )
}

export { HelpTooltip as default }
