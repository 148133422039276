import { getSiteConfig } from '@root/utilities/config'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  clearUserPhonesToCall,
  updateUserPhonesToCall
} from '@root/reducers/user/userSlice'
import phoneValidations from '@/utilities/validations/phone'

const useUrlPhoneForCalls = () => {
  const config = getSiteConfig()
  const urlParams = new URL(window.location.href).searchParams
  const phone = urlParams.get('phone')
  const phoneIsValid = phone && phoneValidations.global.any(`+${phone}`)
  const dispatch = useDispatch()
  const phonePrefix = config?.phone?.prefix
  const phoneVisualPrefix = config?.phone?.visualPrefix

  useEffect(() => {
    if (!phone || !phoneIsValid || !phonePrefix || !phoneVisualPrefix) {
      dispatch(clearUserPhonesToCall())
      return
    }

    const phoneWithoutPrefix = phone.replace(phonePrefix, phoneVisualPrefix)
    const phoneVisual = phoneWithoutPrefix.replace(
      config?.phone?.visualFormatRegex,
      config?.phone?.visualFormatRegexDisplay
    )

    const userPhonesToCall = {
      phoneToCall: `+${phone}`,
      phoneVisualToCall: phoneVisual
    }

    dispatch(updateUserPhonesToCall(userPhonesToCall))
  }, [phonePrefix, phoneVisualPrefix])
}

export default useUrlPhoneForCalls
