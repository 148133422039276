export const siteRoutes = [
  // NotFound wildcard
  {
    path: '*',
    component: 'NotFound',
  },
  // Default Route
  {
    path: '',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
      showEnergySelector: true,
    },
  },
  // Estimation Form Page
  {
    path: 'confronto-elettricita',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  {
    path: 'confronto-gas',
    component: 'EstimationForm',
    props: {
      configModuleId: 'gasEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'gas',
      pageType: 'part',
    },
  },
  {
    path: 'confronto-elettricita-gas',
    component: 'EstimationForm',
    props: {
      configModuleId: 'dualEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'dual',
      pageType: 'part',
    },
  },

  // Product List Page
  {
    path: 'elettricita',
    component: 'ProductList',
    props: {
      energyType: 'elec',
      pageType: 'part',
    },
  },
  {
    path: 'gas',
    component: 'ProductList',
    props: {
      energyType: 'gas',
      pageType: 'part',
    },
  },
  {
    path: 'elettricita-gas',
    component: 'ProductList',
    props: {
      energyType: 'dual',
      pageType: 'part',
    },
  },
  // Product Page
  {
    path: 'offerte/*',
    component: 'ProductPage',
  },
  // Checkout
  {
    path: 'checkout/*',
    component: 'Checkout',
    props: {
      siteId: 2,
      singleOfferTunnel: false,
    },
  },
]

export default siteRoutes
