import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Icon, Button } from '@components/index'
import classes from './PdlModalError.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { formatPriceWithCurrency } from '@root/utilities/string-utils'
import { removeEmailToken, sendWebLead } from '@root/utilities/hop'
import { updateContract } from '@root/reducers/contract/contractSlice'
import { updatePrices } from '@root/reducers/currentOffer/currentOfferSlice'
import { apiContacts } from '@root/utilities/comparator'
import { setRequestHasErrors } from '@root/reducers/checkout/checkoutSlice'
import { clearModal } from '@root/reducers/modal/modalSlice'
import { selectModalData } from '@root/reducers/modal/modalSelectors'

export default function PowerPdlModalError() {
  const {
    previousValues,
    newValues,
    newOfferPrices,
    newData,
    contactsPayload,
    incrementStep,
    hopSource,
  } = useSelector(selectModalData)
  const dispatch = useDispatch()
  const { t } = useTranslation(['validation', 'common', 'eld', 'pdl'])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const clickHandler = async () => {
    setIsSubmitting(true)

    try {
      await sendWebLead(
        {
          ...newData,
          ...(newData.contractEffectiveDate && {
            contractEffectiveDate: new Date(newData.contractEffectiveDate),
          }),
        },
        hopSource
      )
      dispatch(updateContract(newData))
      incrementStep()
      dispatch(clearModal())
    } catch {
      removeEmailToken(true)
      dispatch(clearModal())
      dispatch(setRequestHasErrors(true))
    } finally {
      dispatch(updatePrices({ ...newOfferPrices }))
      apiContacts(contactsPayload)
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <h3 className={classes.PdlErrorModal__title}>
        <Icon
          iconId='icon-meter'
          fill='primary'
          size='xl'
          extraClasses={[classes['PdlErrorModal__icon-title']]}
        />
        {t('pdl:error title')}
      </h3>
      <div className={classes.PdlErrorModal__information}>
        <p
          className={classes['PdlErrorModal__information-text']}
          dangerouslySetInnerHTML={{
            __html: t('pdl:error power information'),
          }}
        />
        <div className={classes['PdlErrorModal__information-card']}>
          <p
            className={classes['PdlErrorModal__information-to-change']}
            dangerouslySetInnerHTML={{
              __html: t('pdl:previous power', { power: previousValues.power }),
            }}
          />
          <p className={classes['PdlErrorModal__information-to-change']}>
            {t('pdl:new value text')}
            <span className={classes['PdlErrorModal__new-value']}>
              {t('pdl:new power', { power: newValues.power })}
            </span>
          </p>
        </div>
        <div className={classes['PdlErrorModal__new-price-information']}>
          <p className={classes['PdlErrorModal__information-text']}>
            {t('pdl:new price text')}
          </p>
          <h3 className={classes['PdlErrorModal__new-price']}>
            {formatPriceWithCurrency(newOfferPrices.offerPriceMonthly)}
            <span className={classes['PdlErrorModal__price-period']}>
              /{t('common:month')}
            </span>
          </h3>
        </div>
        <p className={classes['PdlErrorModal__help-card']}>
          <Icon
            iconId='icon-info-circle-full'
            fill='notice'
            size='md'
            extraClasses={[classes['PdlErrorModal__icon-help']]}
          />
          <span className={classes['PdlErrorModal__help-text']}>
            {t('pdl:help information power part 1')}{' '}
            <strong>{t('pdl:after')}</strong> {t('pdl:help information part 2')}
          </span>
        </p>
        <Button
          block
          pill
          isLoading={isSubmitting}
          disabled={isSubmitting}
          clickHandler={clickHandler}
          extraClasses={[classes['PdlErrorModal__submit-button']]}
        >
          {t('pdl:accept changes')}
          <Icon iconId='icon-chevron-right' fill='white' position='right' />
        </Button>
      </div>
    </>
  )
}
