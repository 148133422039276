import Icon from '../components/Icon'
import Button from '../components/Button/Button'
import { useTranslation } from 'react-i18next'
import classes from '@features/EstimationForm/FormPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectModalData } from '@root/reducers/modal/modalSelectors'
import { clearModal } from '@root/reducers/modal/modalSlice'

export default function EldModalErrorElectricity () {
  const { eldData, gasFormRedirectHandler } = useSelector(selectModalData)
  const { elecPhone: providerPhone, elecProviderName: providerName } = eldData
  const { t } = useTranslation(['validation', 'common', 'eld'])
  const dispatch = useDispatch()

  return (
    <>
      <h1 className={classes['FormPage__title-modal']}>
        <Icon
          iconId='icon-warning-triangle'
          fill='secondary'
          size='xl'
          extraClasses={[classes['FormPage__icon-eld-modal']]}
        />
        {t('eld:postal code eld zone')}
      </h1>
      <p className={classes['FormPage__text-modal']}>
        {t('common:postal code eld zone.eld explanation 1')}
      </p>
      <p className={classes['FormPage__provider-eld-modal']}>
        {t('common:postal code eld zone.eld explanation elec')}
      </p>
      <p className={classes['FormPage__text-modal']}>
        <strong>{providerName}</strong> :{' '}
        <br className={classes.FormPage__linebreak} />{' '}
        {providerPhone}
      </p>
      <Button
        extraClasses={[classes['FormPage__gas-form-button']]}
        clickHandler={() => {
          gasFormRedirectHandler()
        }}
        hidden={!gasFormRedirectHandler}
      >
        {t('eld:compare for gas')}
        <Icon iconId='icon-chevron-right' fill='white' position='right' />
      </Button>
      <hr className={classes['FormPage__modal-separator']} />
      <p className={classes['FormPage__subtitle-modal']}>
        {t('eld:notify new alternative supplier')}
      </p>
      <p className={classes['FormPage__text-modal']}>
        {t('eld:notify new alternative supplier description')}
      </p>
      <Button
        outline
        href='https://selectra.info/energie/alerte-eld'
        clickHandler={() => {
          dispatch(clearModal())
        }}
        target='_blank'
        extraClasses={[classes['FormPage__alert-eld-button']]}
      >
        {t('common:notify me')}
        <Icon iconId='icon-chevron-right' position='right' />
      </Button>
    </>
  )
}
