import apiEndPoints, { host } from '@/utilities/apiEndPoints'

const LanguageDetector = {
  type: 'languageDetector',
  async: true, // If this is set to true, your detect function receives a callback function that you should call with your language, useful to retrieve your language stored in AsyncStorage for example
  init: function (services, detectorOptions, i18nextOptions) {
    /* use services and options */
  },
  detect: function (callback) {
    // You'll receive a callback if you passed async true
    /* return detected language */
    // callback('de'); if you used the async flag

    // TODO Remove hardcoded host
    fetch(
      `${apiEndPoints.site}?${new URLSearchParams({
        host
      })}`
    )
      .then(result => result.json())
      .then(data => {
        callback(data.country_code ?? data.data?.countryCode)
      })
  },
  cacheUserLanguage: function (lng) {
    /* cache language */
  }
}

export default LanguageDetector
