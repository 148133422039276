import { lazy } from 'react'

export const it = {
  knowConsumption: lazy(() =>
    import(
      /* webpackChunkName: 'app-it-estimation-form-know-consumption' */
      './KnowConsumption'
    )
  ),
  cityAutocomplete: lazy(() =>
    import(
      /* webpackChunkName: 'app-it-estimation-form-city' */
      './CityAutocomplete'
    )
  ),
  contactFields: lazy(() =>
    import(
      /* webpackChunkName: 'app-it-estimation-form-contacts-fields' */
      './ContactFields'
      )
  ),
}
