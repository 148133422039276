import phone from 'phone'
import { phoneRegex } from '../regEx'

const phoneValidations = {
  fr: {
    any: value =>
      !!value &&
      !!value.match(phoneRegex.fr.any) &&
      specialValidations.default(value),
    land: value =>
      !!value &&
      !!value.match(phoneRegex.fr.land) &&
      specialValidations.default(value),
    mobile: value =>
      !!value &&
      !!value.match(phoneRegex.fr.mobile) &&
      specialValidations.default(value),
  },
  it: {
    any: value =>
      !!value &&
      !!value.match(phoneRegex.it.any) &&
      specialValidations.default(value),
  },
  jp: {
    any: value =>
      !!value &&
      !!value.match(phoneRegex.jp.any) &&
      specialValidations.default(value),
  },
  es: {
    any: value =>
      !!value &&
      !!value.match(phoneRegex.es.any) &&
      specialValidations.default(value),
  },
  pt: {
    any: value =>
      !!value &&
      !!value.match(phoneRegex.pt.any) &&
      specialValidations.default(value),
  },
  global: {
    any: value =>
      !!value &&
      specialValidations.default(value) &&
      phone(value, { validateMobilePrefix: false }).isValid,
  },
}
const specialValidations = {
  default: value => {
    value = value.replace(/\s+/g, '')

    return (
      !numberRepeated5TimesInARow(value) &&
      patternPosInNumber(value, 'increasing', 6) === -1 &&
      patternPosInNumber(value, 'decreasing', 6) === -1
    )
  },
}

const numberRepeated5TimesInARow = value => {
  return value.match(/([0-9])\1{5,}/)
}

const patternPosInNumber = (value, seriesPattern, minLength) => {
  if (!['increasing', 'decreasing'].includes(seriesPattern)) {
    return -1
  }

  const compareOperation = (firstNumber, secondNumber) => {
    return seriesPattern === 'increasing'
      ? firstNumber - 1 === secondNumber
      : firstNumber + 1 === secondNumber
  }

  const maxPosition = value.length - minLength + 1

  // Iterate string until minLength positions left
  for (let pos = 0; pos < maxPosition; pos++) {
    // Iterate string from "i + 1" to the end
    for (let counter = 1; pos + counter < value.length; counter++) {
      const currentPos = pos + counter

      // The number series has broken, so let's start from de next "pos" position
      if (
        !compareOperation(
          parseInt(value[currentPos]),
          parseInt(value[currentPos - 1])
        )
      ) {
        break
      }

      if (counter === minLength - 1) {
        return pos
      }
    }
  }

  return -1
}

export { phoneValidations as default }
