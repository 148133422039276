import Button from '@components/Button/Button'
import classes from '@common/components/Layout/header-modules/HeaderHelp/MySelectraModal.module.scss'
import { useTranslation } from 'react-i18next'
import Icon from '@components/Icon'
import { getGlobalConfig } from '@root/utilities/config'

const Modal = () => {
  const { t } = useTranslation(['estimationForm', 'productList'])
  const { urls } = getGlobalConfig()

  const navigateToMySelectra = () => {
    open(urls.mySelectra, '_blank')
  }

  const benefitsList = t('estimationForm:header.mySelectraModal.list', {
    returnObjects: true,
  })

  return (
    <div className={classes.MySelectraModal}>
      <h1 className={classes['MySelectraModal__title-product-list']}>
        <Icon
          iconId='icon-acute'
          fill='success'
          size='xl'
          position='left'
          extraClasses={[classes['MySelectraModal__title-product-list__icon']]}
        />
        {t('productList:my selectra.modal.title')}
      </h1>
      <p
        className={classes.MySelectraModal__intro}
        dangerouslySetInnerHTML={{
          __html: t('productList:my selectra.modal.intro'),
        }}
      ></p>
      <ul className={classes.MySelectraModal__list}>
        {benefitsList.map(text => (
          <li key={text}>
            <Icon
              iconId='icon-thin-checkmark'
              size='xl'
              fill='success'
              position='left'
            />
            {text}
          </li>
        ))}
      </ul>
      <Button color='primary' pill block clickHandler={navigateToMySelectra}>
        {t('estimationForm:header.mySelectraModal.button')}
        <Icon
          iconId='icon-external-arrow'
          fill='neutral-light'
          size='sm'
          position='right'
          extraClasses={[classes['MySelectraModal__phone-icon']]}
        />
      </Button>
    </div>
  )
}

export { Modal as default }
