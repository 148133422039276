import { forwardRef, useState, useEffect } from 'react'
import Button from '../Button/Button'
import classes from './SubmitButton.module.scss'
import Icon from '../Icon'
import SubmitButtonLoadingBar from './SubmitButtonLoadingBar'

const SubmitButton = (
  {
    clickHandler,
    hasDanger,
    disabled,
    block,
    children,
    extraClasses: outsideExtraClasses,
    dataCy,
    loaderType = 'spinner',
    isLoading,
    loadingTime,
    loadingText,
    errorText,
    hasError,
    isActionCompleted,
    onActionCompleted: onActionCompletedHandler,
    onAnimationCompleted: onAnimationCompletedHandler,
  },
  ref
) => {
  const extraClasses = [classes.SubmitButton]
  Array.isArray(outsideExtraClasses) &&
    extraClasses.push(...outsideExtraClasses)
  const [animationIsCompleted, setAnimationIsCompleted] = useState(false)
  loaderType === 'spinner' &&
    isLoading &&
    extraClasses.push(classes['SubmitButton--loading'])

  if (loaderType === 'progressBar') {
    hasDanger = hasError || hasDanger
  }

  useEffect(() => {
    if (loaderType === 'spinner') {
      setAnimationIsCompleted(isActionCompleted)
    }
  }, [isActionCompleted])

  return (
    <Button
      clickHandler={clickHandler}
      dataCy={dataCy}
      ref={ref}
      block={block}
      extraClasses={extraClasses}
      pill
      size='lg'
      type='submit'
      color={hasDanger ? 'danger' : 'primary'}
      disabled={isLoading || hasDanger || disabled}
      isActionCompleted={isActionCompleted && animationIsCompleted}
    >
      {loaderType === 'progressBar' ? (
        <SubmitButtonLoadingBar
          isActionCompleted={isActionCompleted}
          loadingText={loadingText}
          isLoading={isLoading}
          loadingTime={loadingTime}
          onCompleteLoadBar={() => {
            setAnimationIsCompleted(true)
            onActionCompletedHandler?.()
          }}
          onAnimationCompleted={() => {
            onAnimationCompletedHandler?.()
          }}
          errorText={errorText}
          hasError={hasError}
        >
          {children}
        </SubmitButtonLoadingBar>
      ) : isLoading ? (
        <Icon iconId='icon-loader' size='lg' rotate fill='primary' />
      ) : (
        children
      )}
    </Button>
  )
}

export default forwardRef(SubmitButton)
