import { useTranslation } from 'react-i18next'
import classes from './PriceDate.module.scss'

const PriceDate = ({ date, extraClasses }) => {
  if (!date) return null

  const { t } = useTranslation()
  const priceDateClasses = [classes.PriceDate]
  const dateString = date.split('-').reverse().join('/')
  Array.isArray(extraClasses) && priceDateClasses.push(...extraClasses)

  return (
    <div className={priceDateClasses.join(' ')}>
      {t('current price', {
        date: dateString,
      })}
    </div>
  )
}

export default PriceDate
