import { useTranslation } from 'react-i18next'
import Icon from '../components/Icon'
import Button from '../components/Button/Button'
import classes from '@features/EstimationForm/FormPage.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { selectModalData } from '@root/reducers/modal/modalSelectors'
import { clearModal } from '@root/reducers/modal/modalSlice'

export default function EldModalErrorDual () {
  const { eldData, elecFormRedirectHandler, gasFormRedirectHandler } = useSelector(selectModalData)
  const { elecPhone: elecProviderPhone, elecProviderName, gasPhone: gasProviderPhone, gasProviderName } = eldData
  const { t } = useTranslation(['validation', 'common', 'eld'])
  const dispatch = useDispatch()
  const showGasProviderName = gasProviderName && gasProviderName !== elecProviderName
  let eldText = null

  if (gasProviderName) {
    eldText = t('eld:dual.gas available')
  }

  if (elecProviderName) {
    eldText = t('eld:dual.electricity available')
  }

  if (gasProviderName && elecProviderName) {
    eldText = t('eld:dual.electricity and gas available')
  }

  return (
    <>
      <h3 className={classes['FormPage__title-modal']}>
        <Icon
          iconId='icon-warning-triangle'
          fill='secondary'
          size='xl'
          extraClasses={[classes['FormPage__icon-eld-modal']]}
        />
        {t('eld:postal code eld zone')}
      </h3>
      <p className={classes['FormPage__text-modal']}>
        {t('eld:eld explanation 1')}
      </p>
      <p className={classes['FormPage__provider-eld-modal']}>
        {t('eld:eld explanation dual')}
        {eldText}
      </p>
      <p className={classes['FormPage__text-modal']} hidden={!elecProviderName}>
        <strong>{elecProviderName}</strong> :{' '}
        <br className={classes.FormPage__linebreak} />{' '}
        {elecProviderPhone}
      </p>

      <p className={classes['FormPage__text-modal']} hidden={!showGasProviderName}>
        <strong>{gasProviderName}</strong> :{' '}
        <br className={classes.FormPage__linebreak} />{' '}
        {gasProviderPhone}
      </p>

      <Button
        clickHandler={() => {
          gasFormRedirectHandler()
        }}
        extraClasses={[classes['FormPage__gas-form-button']]}
        hidden={!gasProviderName && !gasFormRedirectHandler}
      >
        {t('eld:compare for gas')}
        <Icon iconId='icon-chevron-right' fill='white' position='right' />
      </Button>

      <Button
        clickHandler={() => {
          elecFormRedirectHandler()
        }}
        extraClasses={[classes['FormPage__gas-form-button']]}
        hidden={!elecProviderName && !elecFormRedirectHandler}
      >
        {t('eld:compare for electricity')}
        <Icon iconId='icon-chevron-right' fill='white' position='right' />
      </Button>

      <hr className={classes['FormPage__modal-separator']} />
      <p className={classes['FormPage__subtitle-modal']}>
        {t('eld:notify new alternative supplier')}
      </p>
      <p className={classes['FormPage__text-modal']}>
        {t('eld:notify new alternative supplier description')}
      </p>
      <Button
        outline
        href='https://selectra.info/energie/alerte-eld'
        clickHandler={() => {
          dispatch(clearModal())
        }}
        target='_blank'
        extraClasses={[classes['FormPage__alert-eld-button']]}
      >
        {t('common:notify me')}
        <Icon iconId='icon-chevron-right' position='right' />
      </Button>
    </>
  )
}
