import ReviewsItem from './ReviewsItem'
import classes from './ReviewsList.module.scss'

const ReviewsList = ({ reviews }) => (
  <ul className={classes.ReviewsList}>
    {reviews.map(({ idRating, ratingDate, rating, userName, userComment }) => (
      <ReviewsItem
        key={idRating}
        date={ratingDate}
        rating={rating}
        userName={userName}
        userComment={userComment}
      />
    ))}
  </ul>
)

export { ReviewsList as default }
