import defaultValues from './defaultValues'
import footerLinks from './footerLinks'
import siteRoutes from './siteRoutes'
import { getUtcBySummerSchedule } from '@root/utilities/site'

const config = {
  urls: {
    app: {
      staging: 'it.energy-comparator-spa.s3.staging.aws.selectra.io',
      production: 'energia.luce-gas.it'
    },
    api: {
      staging: 'energy-core.app.staging.aws.selectra.io',
      production: 'energy.selectra.tech'
    }
  },
  apiEndpointsModule: 'energy-core',
  productCardBulletPoints: 4,
  locale: 'it-IT',
  utc: getUtcBySummerSchedule(),
  openingHours: {
    monday: ['08:00', '21:00'],
    tuesday: ['08:00', '21:00'],
    wednesday: ['08:00', '21:00'],
    thursday: ['08:00', '21:00'],
    friday: ['08:00', '21:00'],
    saturday: ['09:00', '19:00'],
    sunday: []
  },
  footerLinks,
  defaultValues,
  siteRoutes
}

export default config
