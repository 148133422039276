const stagingConfig = {
  auth: {
    recaptchaPublicKey: '6LfSq8YfAAAAACLWMpGIi0_05JUhIkv7kD7dH74I',
    loyaltyAuthorizationToken: '2PrX79tL7thFtkl8Cd3B5lNGGFyrbQWR',
  },
  apiUrls: {
    hop: 'https://fr.private.multi-provider-platform.app.staging.aws.selectra.io',
    customerService: 'https://customer-service.app.staging.aws.selectra.io',
  },
}
export default stagingConfig
