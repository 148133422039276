import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  offerId: null,
  currentStep: 1,
  maxStepCompleted: 1,
  isLoading: false,
  type: '',
  contractIsReadyToSend: false,
  requestHasErrors: false,
  isActionCompleted: false,
}

const nextStepHandler = state => {
  const { maxStepCompleted, currentStep } = state

  const nextStep = currentStep + 1
  state.currentStep = nextStep

  if (maxStepCompleted > currentStep) {
    state.currentStep = maxStepCompleted + 1
  }

  if (nextStep > maxStepCompleted) {
    state.maxStepCompleted = nextStep - 1
  }
}
const clearCheckoutStepStateHandler = state => {
  state.requestHasErrors = initialState.requestHasErrors
  state.isActionCompleted = initialState.isActionCompleted
  state.isLoading = initialState.isLoading
}

const updateOfferIdHandler = (state, action) => {
  state.offerId = +action.payload
}

const updateContractIsReadyToSendHandler = (state, action) => {
  state.contractIsReadyToSend = action.payload
}

const updateCheckoutTypeHandler = (state, action) => {
  if (state.type !== action.payload) {
    state.type = action.payload
    state.currentStep = 1
    state.maxStepCompleted = 1
  }
}

const updateCurrentStepHandler = (state, action) => {
  const nextStep = action.payload
  state.currentStep = nextStep
  if (nextStep > state.maxStepCompleted) {
    state.maxStepCompleted = nextStep - 1
  }
}

const updateCurrentOfferConfigurationHandler = (state, action) => {
  state.offerConfiguration = action.payload
}

const showSpinnerHandler = state => {
  state.isLoading = true
}

const hideSpinnerHandler = state => {
  state.isLoading = false
}

const setIsActionCompletedHandler = (state, action) => {
  state.isActionCompleted = action.payload
}
const setRequestHasErrorsHandler = (state, action) => {
  state.requestHasErrors = action.payload
}

const initCheckoutStepsHandler = state => {
  state.currentStep = 1
  state.maxStepCompleted = 1
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    updateCurrentStep: updateCurrentStepHandler,
    updateOfferId: updateOfferIdHandler,
    updateCheckoutType: updateCheckoutTypeHandler,
    updateContractIsReadyToSend: updateContractIsReadyToSendHandler,
    nextStep: nextStepHandler,
    showSpinner: showSpinnerHandler,
    hideSpinner: hideSpinnerHandler,
    setIsActionCompleted: setIsActionCompletedHandler,
    setRequestHasErrors: setRequestHasErrorsHandler,
    initCheckoutSteps: initCheckoutStepsHandler,
    updateCurrentOfferConfiguration: updateCurrentOfferConfigurationHandler,
    clearCheckoutStepState: clearCheckoutStepStateHandler,
    clearCheckout: () => initialState,
  },
})

export const {
  updateCurrentStep,
  updateOfferId,
  updateCheckoutType,
  updateContractIsReadyToSend,
  nextStep,
  showSpinner,
  hideSpinner,
  setIsActionCompleted,
  setRequestHasErrors,
  initCheckoutSteps,
  updateCurrentOfferConfiguration,
  clearCheckout,
  clearCheckoutStepState,
} = checkoutSlice.actions

export default checkoutSlice.reducer
