import { useSelector } from 'react-redux'
import { selectModalData } from '@root/reducers/modal/modalSelectors'
import Tooltip from './Tooltip'

const TooltipCard = () => {
  const props = useSelector(selectModalData)
  return <Tooltip {...props} />
}

export { TooltipCard as default }
