import { useSelector } from 'react-redux'
import { t } from 'i18next'
import { selectCurrentOfferData } from '../../reducers/currentOffer/currentOfferSelectors'
import {
  selectOffersDataOffers,
  selectOffersPostData
} from '../../reducers/offers/offersSelectors'
import { selectUserData } from '../../reducers/user/userSelectors'
import generatePricesData from '@/utilities/generatePricesData'
import phoneValidations from '@/utilities/validations/phone'
import { getComparatorOfferConfig } from '@/utilities/getComparatorOfferConfig'
import { getOffsetDate, isValidDate } from '@/utilities/date-utils'
import { selectContractData } from '@root/reducers/contract/contractSelectors'

const getHopConfig = ({ hopConfig = '', isFullOnline, offerId }) => {
  if (!isFullOnline) return {}

  const comparatorOfferConfig = getComparatorOfferConfig(offerId)

  if (!hopConfig) return

  const validation = value => {
    const validationFunction = {
      french_mobile: phoneValidations.fr.mobile,
      french_fixe: phoneValidations.fr.land,
      'french_fixe;french_mobile': phoneValidations.fr.any,
      'french_fixe;french_mobile;foreign_fixe': phoneValidations.fr.land,
      'french_fixe;french_mobile;foreign_mobile': phoneValidations.fr.mobile,
      'french_fixe;french_mobile;foreign_fixe;foreign_mobile':
        phoneValidations.fr.any
    }[hopConfig.phone1Accepted || 'french_fixe;french_mobile']
    return validationFunction(value) || t('validation:enter a phone number')
  }

  const contractEffectiveDate = (dateOffset = 5) => {
    const minimumContractEffectiveDate =
      comparatorOfferConfig?.minimumContractEffectiveDate
    const contractEffectiveDate = getOffsetDate(0, 0, dateOffset)
    if (
      (comparatorOfferConfig.minimumContractEffectiveDate &&
        !isValidDate(minimumContractEffectiveDate)) ||
      !isValidDate(contractEffectiveDate)
    ) {
      throw new Error('Invalid contract effective date')
    }

    const shouldSetMinimumContractEffectiveDate =
      minimumContractEffectiveDate?.getTime() > contractEffectiveDate?.getTime()

    return shouldSetMinimumContractEffectiveDate
      ? minimumContractEffectiveDate
      : contractEffectiveDate
  }

  const newOfferHopConfig = {
    ...hopConfig,
    formFields: {
      ...comparatorOfferConfig.formFields,
      ...hopConfig.formFields,
      phone1: {
        ...hopConfig.formFields.phone1,
        validation
      }
    },
    contractEffectiveDate: contractEffectiveDate(hopConfig.effectiveDateOffset)
  }

  return { ...comparatorOfferConfig, ...newOfferHopConfig }
}

const useCheckout = () => {
  const currentOfferData = useSelector(selectCurrentOfferData)
  const userData = useSelector(selectUserData)
  const { knowConsumption, powerMeterKva } = useSelector(selectOffersPostData)
  const dataOffers = useSelector(selectOffersDataOffers)
  const { city } = useSelector(selectContractData)

  const {
    tariffOption,
    annualConsoElec,
    annualConsoGas,
    subscribedPower,
    energyType,
    phone,
    waterEnergy,
    houseType,
    consoHp,
    consoHc,
    pdl,
    street,
    streetAutocomplete,
    energyChoice,
    carElectricityBase,
    carElectricityHC,
    carElectricityHP
  } = userData
  const {
    priceDetails,
    gasPriceDetails,
    hopConfig,
    avantages,
    inconvenients,
    offerPageAbsolutePath,
    presentationTextLong,
    idProvider,
    globalPrice,
    globalEconomie,
    realPhone,
    realPhoneForcall,
    urlRappel,
    idOffer,
    isFullOnline,
    offerEnergyType,
    offerId,
    providerId,
    offerBudget,
    filtersList,
    limitStartingDate,
    additionnalCosts,
    discounts
  } = currentOfferData

  const { referenceOffer } = dataOffers
  const prices = referenceOffer?.priceDetails ?? referenceOffer?.prices
  const referenceOfferGasPrices = {
    gasPriceKwhCalculatedGlobal: referenceOffer?.gasPriceKwhGlobal ?? referenceOffer?.gasPriceKwhCalculatedGlobal ?? '',
    gasPriceAboCalculated: referenceOffer?.gasPricePayment ?? referenceOffer?.gasPriceAboCalculated ?? '',
    gasTotalAmount: referenceOffer?.gasTotalAmount ?? ''
  }

  const offerTableData = generatePricesData({
    ...{
      additionalCostsList: additionnalCosts,
      energyType: energyType ?? offerEnergyType
    },
    tariffOption,
    ...priceDetails,
    ...gasPriceDetails,
    hopConfig,
    discounts
  })

  const referenceOfferTableData = generatePricesData({
    ...{
      additionalCostsList: additionnalCosts,
      energyType: energyType ?? offerEnergyType
    },
    tariffOption,
    ...prices,
    ...referenceOfferGasPrices,
    hopConfig
  })

  const userStreet = street ?? {
    name: '',
    postcode: ''
  }

  const userStreetAutocomplete = streetAutocomplete ?? userStreet?.name ?? ''

  const isSaving = parseInt(globalEconomie, 10) >= 0
  const prestationType = houseType === 'new' ? 'mes' : 'cdf'

  return {
    ...currentOfferData,
    ...userData,
    ...dataOffers,
    city,
    offerTableData,
    referenceOfferTableData,
    knowConsumption,
    phone1: phone,
    carElectricityBase: annualConsoElec || carElectricityBase,
    carElectricityHC: consoHc || carElectricityHC,
    carElectricityHP: consoHp || carElectricityHP,
    carGas: annualConsoGas,
    PDL: pdl,
    meterPower: subscribedPower || powerMeterKva,
    energyChoice: energyType || energyChoice,
    advantages: avantages,
    disadvantages: inconvenients,
    standardizedPageUrl: offerPageAbsolutePath,
    offerPresentationText: presentationTextLong,
    providerId: idProvider || providerId,
    offerBudget: globalPrice || offerBudget,
    offerSaving: globalEconomie,
    offerEnergyType: energyType || offerEnergyType,
    partnerPhone: realPhone,
    partnerPhoneFormatted: realPhoneForcall,
    rappelUrl: urlRappel ?? '',
    offerId: idOffer || offerId,
    street: userStreet,
    hotwaterEnergy: waterEnergy,
    isSaving,
    hopConfig: getHopConfig({
      hopConfig,
      isFullOnline,
      offerId: idOffer || offerId
    }),
    prestationType,
    energyType: energyType || offerEnergyType,
    streetAutocomplete:
      userStreetAutocomplete === 'undefined' ? '' : userStreetAutocomplete,
    filtersList,
    limitStartingDate: limitStartingDate ?? ''
  }
}

export default useCheckout
