import ReactModal from 'react-modal'
import classes from './Modal.module.scss'
import Icon from '../Icon'
import { useDispatch, useSelector } from 'react-redux'
import { selectModal } from '@root/reducers/modal/modalSelectors'
import { clearModal, updateModalIsOpen } from '@root/reducers/modal/modalSlice'
import modalComponents from './ModalComponents'
import { useEffect } from 'react'

const Modal = ({ onRequestClose: outerHandleRequestClose }) => {
  const dispatch = useDispatch()
  const {
    isOpen,
    component,
    isDrawer,
    size,
    closeButton,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
    onAfterOpen,
    onAfterClose: handleAfterClose,
    id,
    childrenProps,
  } = useSelector(selectModal)
  const setIsOpen = isOpen => dispatch(updateModalIsOpen(isOpen))
  const Component = modalComponents[component]

  // TODO: Review modal open state behavior with ModalEstimationForm
  useEffect(() => {
    // fix overflow when modal is closed
    !isOpen && document.body.classList.remove('body--modal-is-open')
  }, [isOpen])

  if (!(isOpen && Component)) return null

  const isMobile = isDrawer || size === 'full'

  const contentClasses = [classes.Modal__content]
  isDrawer && contentClasses.push(classes['Modal__content-drawer'])

  const overlayClasses = [classes.Modal__overlay]
  isDrawer && overlayClasses.push(classes['Modal__overlay-drawer'])

  const closeButtonClasses = [classes['Modal__close-icon']]
  isMobile && closeButtonClasses.push(classes['Modal__close-icon-drawer'])

  const contentSizeClasses = {
    xs: 'Modal__content--xs',
    md: 'Modal__content--md',
    lg: 'Modal__content--lg',
    xl: 'Modal__content--xl',
    full: 'Modal__content--full',
  }

  const handleAfterOpen = () => {
    !isOpen && setIsOpen(true)
    onAfterOpen?.()
    window.addEventListener('modal-close', handleAfterClose)
  }

  const handleRequestClose = () => {
    isOpen && setIsOpen(false)
    outerHandleRequestClose?.()
    dispatch(clearModal())
    window.removeEventListener('modal-close', handleAfterClose)
  }

  contentClasses.push(
    classes[contentSizeClasses[(size in contentSizeClasses && size) || 'md']]
  )

  return (
    <ReactModal
      id={id}
      ariaHideApp={false}
      isOpen={isOpen}
      onAfterOpen={handleAfterOpen}
      onRequestClose={handleRequestClose}
      onAfterClose={handleAfterClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      className={{
        base: contentClasses.join(' '),
        afterOpen: [
          ...contentClasses,
          classes['Modal__content--after-open'],
        ].join(' '),
        beforeClose: [
          ...contentClasses,
          classes['Modal__content--before-close'],
        ].join(' '),
      }}
      overlayClassName={{
        base: overlayClasses.join(' '),
        afterOpen: [
          ...overlayClasses,
          classes['Modal__overlay--after-open'],
        ].join(' '),
        beforeClose: [
          ...overlayClasses,
          classes['Modal__overlay--before-close'],
        ].join(' '),
      }}
      bodyOpenClassName='body--modal-is-open'
    >
      {closeButton && (
        <button
          onClick={handleRequestClose}
          className={closeButtonClasses.join(' ')}
        >
          <Icon iconId='icon-cross' fill='neutral-medium' size='xs' />
        </button>
      )}
      <Component {...childrenProps} />
    </ReactModal>
  )
}

export default Modal
