import { lazy } from 'react'

export const pt = {
  knowConsumption: lazy(() =>
    import(
      /* webpackChunkName: 'app-pt-estimation-form-know-consumption' */
      './KnowConsumption'
    )
  ),
  cityAutocomplete: lazy(() =>
    import(
      /* webpackChunkName: 'app-pt-estimation-form-city' */
      './CityAutocomplete'
    )
  ),
  contactFields: lazy(() =>
    import(
      /* webpackChunkName: 'app-pt-estimation-form-contacts-fields' */
      './ContactFields'
    )
  ),
  equipments: lazy(() =>
    import(
      /* webpackChunkName: 'app-pt-estimation-form-equipment' */
      './Equipments'
    )
  ),
}
