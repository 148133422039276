import { lazy, Suspense, useEffect } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import TagManager from 'react-gtm-module'
import { useDispatch } from 'react-redux'
import { useGetSiteConfigQuery } from '@/reducers/endpoints/endpointsSlice'
import { updateConfig } from '@/reducers/config/configSlice'
import { host } from '@/utilities/apiEndPoints'
import '@/assets/sass/reset.scss'
import { Modal, Spinner } from '@common/components'
import useUnOnload from '@common/hooks/useUnload'
import { clearModal } from '@root/reducers/modal/modalSlice'
import { updateUserShouldSeeResultsTypeform } from '@root/reducers/user/userSlice'
import ErrorBoundary from '@features/ErrorBoundary'
import { env } from '@/utilities/config'
import { getSiteConfig } from '@root/utilities/config'
import useUrlPhoneForCalls from '@hooks/useUrlPhoneForCalls'

const components = {
  EstimationForm: lazy(() =>
    import(
      /* webpackChunkName: 'app-comparator-form' */
      '@features/EstimationForm/EstimationFormPage'
    )
  ),
  ProductList: lazy(() =>
    import(
      /* webpackChunkName: 'app-product-list-page' */
      '@features/ProductList/ProductListPage'
    )
  ),
  Checkout: lazy(() =>
    import(
      /* webpackChunkName: 'app-checkout-page' */
      '@features/Checkout/CheckoutPage'
    )
  ),
  NotFound: lazy(() =>
    import(
      /* webpackChunkName: 'app-not-found' */
      '@features/NotFound/NotFound'
    )
  ),
  ProductPage: lazy(() =>
    import(
      /* webpackChunkName: 'app-product-page' */
      '@features/ProductPage/ProductPage'
    )
  ),
  ClearRedux: lazy(() => import('@features/ClearRedux/ClearRedux'))
}

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])
}

const GoogleAnalytics = () => {
  if (env !== 'production') {
    return
  }

  const { pathname } = useLocation()

  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: pathname
      }
    })
  }, [pathname])
}

export default function App () {
  const dispatch = useDispatch()
  useUnOnload()
  const { data: siteConfigData, refetch } = useGetSiteConfigQuery(host)

  const config = getSiteConfig()
  const siteRoutes = config?.siteRoutes

  const { title, gtmId, country, siteId, lang, canonical } =
    siteConfigData ?? {}

  useUrlPhoneForCalls()

  useEffect(() => {
    dispatch(clearModal())
    dispatch(updateUserShouldSeeResultsTypeform(false))
    refetch()
  }, [])

  useEffect(() => {
    if (!country) return
    dispatch(updateConfig({ country, siteId }))
  }, [country])

  useEffect(() => {
    if (!lang) return
    document.documentElement.lang = lang
  }, [lang])

  useEffect(() => {
    if (!gtmId || window.Cypress) return
    TagManager.initialize({ gtmId })
  }, [gtmId])

  if (!siteConfigData || !siteRoutes?.length) return <div />

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner fullscreen />}>
        <Helmet>
          <title>{title}</title>
          <link rel='canonical' href={canonical} />
        </Helmet>
        <ErrorBoundary>
          <Suspense fallback={<Spinner fullscreen />}>
            <Modal />
          </Suspense>
          <ScrollToTop />
          <GoogleAnalytics />
          <Routes>
            {siteRoutes.map(route => {
              const RouteComponent = components[route.component]
              const routeProps = route.props ?? {}

              return (
                <Route
                  key={`${route.path}-route`}
                  path={route.path}
                  element={<RouteComponent {...routeProps} />}
                />
              )
            })}
          </Routes>
        </ErrorBoundary>
      </Suspense>
    </BrowserRouter>
  )
}
