import common from './common.json'
import elec from './elec.json'
import equipments from './equipments.json'
import estimationForm from './estimation-form.json'
import validation from './validation.json'
import eld from './eld.json'
import checkout from './checkout.json'
import productList from './product-list.json'
import cta from './cta.json'
import legal from './legal.json'
import misc from './misc.json'
import routes from './routes.json'

export default {
  common,
  elec,
  equipments,
  estimationForm,
  validation,
  eld,
  productList,
  cta,
  legal,
  checkout,
  misc,
  routes,
}
