import { useTranslation } from 'react-i18next'
import Icon from '@common/components/Icon'
import classes from './EnergyInfoBeModal.module.scss'

const GasEnergyPriceInfoBeModal = () => {
  const { t } = useTranslation(['common', 'checkout'])
  const texts = t('checkout:energy info modals.gas price.list', {
    returnObjects: true,
  })

  return (
    <div className={classes.EnergyInfoBeModal__text}>
      <h1 className={classes.EnergyInfoBeModal__title}>
        <Icon
          iconId='circle-interrogation-filled'
          fill='primary'
          size='xl'
          position='left'
        />
        {t('checkout:energy price')}
      </h1>
      <p>{t('checkout:energy info modals.gas price.intro')}</p>
      <ul className={classes.EnergyInfoBeModal__list}>
        {texts.map(text => (
          <li key={text}>{text}</li>
        ))}
      </ul>
    </div>
  )
}

export { GasEnergyPriceInfoBeModal as default }
