export const getRatingLineItems = (rateInfo, nbRating) => {
    const lineItems = Array.from(Array(5).keys()).reverse()
      .map((i) => {
        const key = i + 1;
        const votes = rateInfo[`voted_${key}`];
        return {
          starsKey: key,
          percentage: Math.round(votes * 100 / nbRating),
          percentageReal: votes * 100 / nbRating,
          votes
        };
      });

    const percentageTotal = lineItems.reduce((acc, item) => {
      acc += item.percentage;
      return acc;
    }, 0);
    if (percentageTotal !== 100) {
      lineItems[lineItems.length - 1].percentage += 100 - percentageTotal;
    }
    return lineItems;
}
