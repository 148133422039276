import { env } from '@/utilities/config'

const sendUserTrackData = ({ eventName, properties, eventData }) => {
  const sendUserTrackData =
    properties && eventData && sendinblue && env === 'production'

  if (!sendUserTrackData) {
    return
  }

  sendinblue.track(eventName, properties, eventData)
}

export default sendUserTrackData
