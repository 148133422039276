const productionConfig = {
  auth: {
    recaptchaPublicKey: '6Lc0rvgfAAAAAHmxsvQ3AOtUWL1ZSGOGRI2EbO9l',
    loyaltyAuthorizationToken: '2PrX79tL7thFtkl8Cd3B5lNGGFyrbQWR',
  },
  apiUrls: {
    hop: 'https://hop.selectra.io',
    customerService: 'https://customer-service.app.production.aws.selectra.io',
  },
}
export default productionConfig
