const mintSpecs = {
  formFields: {
    surface: {
      isNeeded: true,
    },
  },
  legalChecks: [
    {
      label: 'Je demande à Mint Energie de devenir mon fournisseur d’énergie.',
    },
    {
      label:
        'J’autorise Mint Energie à récupérer auprès de mon gestionnaire de réseau de distribution (Enedis pour l’électricité, GRDF pour le gaz) les éléments permettant le bon déroulement de ma souscription, ainsi que toutes les informations de mesure de ma consommation d’électricité et de gaz naturel passée et à venir.',
    },
  ],
}

const iberdrolaSpecs = {}

const ilekSpecs = {
  monthlyPaymentErrorTitle:
    'Nous avons actualisé <strong>vos informations de consommation</strong>.',
}

const vattenfallSpecs = {
  notEffectiveDateAvailableForCdf: true,
}

const offerData = {
  // MINT - elec
  // 100% Online & Green
  3696: {
    ...mintSpecs,
    notSellablePrestationType: 'mes',
    minimumContractEffectiveDate: new Date(2022, 2, 25),
  },
  4729: {
    ...mintSpecs,
    notSellablePrestationType: 'mes',
    minimumContractEffectiveDate: new Date(2022, 2, 25),
  }, // 100% Classic & Green
  3072: mintSpecs,

  // VATTENFALL - elec
  // Offre Eco Pro 2 ans
  // 3666: vattenfallSpecs,
  // Offre Eco Pro 3 ans
  // 3667: vattenfallSpecs,
  // Offre Eco Pro 1 an
  // 3668: vattenfallSpecs,
  // Electricité Eco
  // 4345: vattenfallSpecs,
  // Electricité Eco green
  // 5285: vattenfallSpecs,
  // Eco + green
  6508: {
    ...vattenfallSpecs,
  },

  // VATTENFALL - gas
  // Gaz Eco Plus
  4344: {
    ...vattenfallSpecs,
    formFields: {
      surface: {
        isNeeded: true,
      },
    },
  },

  // VATTENFALL - dual
  // Electricité Eco New Green + Gaz Eco Plus
  5340: {
    ...vattenfallSpecs,
    formFields: {
      surface: {
        isNeeded: true,
      },
    },
  },

  // IBERDROLA - elec
  // Digielec
  // 4589: iberdrolaSpecs,
  // Digielec Pro
  // 4713: iberdrolaSpecs,
  // Ecolib électricité
  // 6348: iberdrolaSpecs,
  // Iberzen Elec
  6395: iberdrolaSpecs,
  // Iberzen Pro Elec
  // 6453: iberdrolaSpecs,

  // OHM - elec
  // Petite Conso
  4293: {
    effectiveDateIsVisible: false,
  },
  // Octopus - elec
  // Eco Conso
  1833: {
    hasMonthlyAmountEndpoint: true,
    monthlyPaymentErrorTitle:
      'Nous avons actualisé <strong>vos informations de consommation</strong> (grâce au n° PDL).',
  },
  // Ilek - elec
  6815: {
    ...ilekSpecs,
  },
  // Ilek - gas
  6816: {
    ...ilekSpecs,
    formFields: {
      heatingEnergy: {
        isNeeded: true,
      },
      hotwaterEnergy: {
        isNeeded: true,
      },
    },
  },
  // Ilek - dual
  6302: {
    ...ilekSpecs,
    formFields: {
      heatingEnergy: {
        isNeeded: true,
      },
      hotwaterEnergy: {
        isNeeded: true,
      },
    },
  },
}

const getComparatorOfferConfig = offerId => {
  return offerData[offerId] ? { ...offerData[offerId] } : {}
}

export { getComparatorOfferConfig }
