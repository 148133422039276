import footerLinks from './footerLinks'
import siteRoutes from './siteRoutes'
import { getUtcBySummerSchedule } from '@root/utilities/site'
import phone from './phone'

const config = {
  urls: {
    app: {
      staging: 'fr.energy-comparator-spa.s3.staging.aws.selectra.io',
      production: 'energie.selectra.info'
    },
    api: {
      staging: 'energy-core.app.staging.aws.selectra.io',
      production: 'energy.selectra.tech'
    }
  },
  apiEndpointsModule: 'energy-core',
  locale: 'fr-FR',
  utc: getUtcBySummerSchedule(),
  id: 1,
  openingHours: {
    monday: ['08:00', '21:00'],
    tuesday: ['08:00', '21:00'],
    wednesday: ['08:00', '21:00'],
    thursday: ['08:00', '21:00'],
    friday: ['08:00', '21:00'],
    saturday: ['08:30', '18:30'],
    sunday: []
  },
  highlightedTop3Offers: {
    buttonIds: ['left-offer', 'center-offer', 'right-offer']
  },
  leadSources: {
    mainFormPro: 'fr-ei-co-comparateurpro',
    pdlFinderFormAccomodationStep: 'fr-ef-co-pdl1step',
    pdlFinderFormSituationStep: 'fr-ef-co-pdl1step',
    formContactStep: 'fr-ef-co-1comparator2online3hybridtunnel',
    callbackProductList: 'fr-ef-co-1comparator2online3herocmb',
    callbackCheckout: 'fr-ef-co-1comparator2online3hybridtunnel',
    callbackProductPage: 'fr-ef-co-1comparator2online3offerpages',
    contactCard: 'fr-ef-co-1comparator2online3help'
  },
  footerLinks,
  siteRoutes,
  phone
}

export default config
