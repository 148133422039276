import classes from './GridColumn.module.scss'

const Col = ({ children, xs, sm, md, lg, xl, extraClasses }) => {
  const colClasses = [classes.Col]
  ;[xs, sm, md, lg, xl].forEach((breakpoint, idx) => {
    const bp = ['xs', 'sm', 'md', 'lg', 'xl'][idx]
    const classList = []
    if (typeof breakpoint === 'object') {
      const width =
        typeof breakpoint.width === 'number' ? breakpoint.width : null
      const offset =
        typeof breakpoint.offset === 'number' ? breakpoint.offset : null
      width && classList.push(classes[`Col-${bp}-${width}`])
      offset && classList.push(classes[`Col-offset-${bp}-${offset}`])
    } else if (typeof breakpoint === 'number') {
      classList.push(classes[`Col-${bp}-${breakpoint}`])
    }
    colClasses.push(...classList)
  })
  Array.isArray(extraClasses) && colClasses.push(...extraClasses)

  return (
    <div className={colClasses.length ? colClasses.join(' ') : null}>
      {children}
    </div>
  )
}

export { Col as default }
