import { lazy } from 'react'

export const es = {
  knowConsumption: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-know-consumption' */
      './KnowConsumption'
    )
  ),
  equipments: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-equipments' */
      './Equipments'
    )
  ),
  contactFields: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-newsletter' */
      './ContactFields'
    )
  ),
  energySelector: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-newsletter' */
      './EnergySelector'
    )
  ),
}
