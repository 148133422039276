import PaginationItem from './PaginationItem'
import classes from './Pagination.module.scss'
import PaginationItemDiscontinued from './PaginationItemDiscontinued'
import Icon from '../Icon'
import { useState } from 'react'

/**
 * Create an array with all page items
 * @returns array
 */
const createItems = (visibleItems, lastPage, page) => {
  const itemsPerSide = Math.floor(visibleItems / 2)
  let startPage
  let endPage
  if (lastPage <= visibleItems) {
    startPage = 1
    endPage = lastPage
  } else if (page - itemsPerSide <= 0) {
    startPage = 1
    endPage = startPage + visibleItems
  } else if (page + itemsPerSide > lastPage) {
    startPage = lastPage - visibleItems
    endPage = lastPage
  } else {
    startPage = page - itemsPerSide
    endPage = page + itemsPerSide
  }

  return [...Array(endPage + 1 - startPage).keys()].map(i => startPage + i)
}

const Pagination = ({
  page,
  lastPage,
  visibleItems,
  setCurrentPage,
  hidden,
}) => {
  const [items] = useState(createItems(visibleItems, lastPage, page))
  const shouldRenderPrevDiscontinued = items[0] !== 1
  const shouldRenderNextDiscontinued = items[items.length - 1] !== lastPage

  if (hidden) return

  return (
    <div className={classes.Pagination}>
      {page > 1 && (
        <PaginationItem
          page={page - 1}
          key={'previous'}
          current={page}
          setCurrentPage={setCurrentPage}
        >
          <Icon iconId='icon-arrow-left' fill='primary' size='md' />
        </PaginationItem>
      )}

      {shouldRenderPrevDiscontinued && (
        <>
          <PaginationItem
            page={1}
            key={'start'}
            current={page}
            setCurrentPage={setCurrentPage}
          />
          <PaginationItemDiscontinued page={'...'} key={'disc'} />
        </>
      )}

      {items.map(item => (
        <PaginationItem
          page={item}
          key={item}
          current={page}
          setCurrentPage={setCurrentPage}
        />
      ))}

      {shouldRenderNextDiscontinued && (
        <>
          <PaginationItemDiscontinued page={'...'} key={'disc'} />
          <PaginationItem
            page={lastPage}
            key={'end'}
            current={page}
            setCurrentPage={setCurrentPage}
          />
        </>
      )}

      {page < lastPage && (
        <PaginationItem
          page={page + 1}
          key={'next'}
          current={page}
          setCurrentPage={setCurrentPage}
        >
          <Icon iconId='icon-arrow-right' fill='primary' size='md' />
        </PaginationItem>
      )}
    </div>
  )
}

export default Pagination
