import { createSlice } from '@reduxjs/toolkit'
import { keysToCamel } from '@/utilities/snake-case-to-camel-case'

const initialState = {
  elec: {},
  gas: {},
  dual: {},
  error: null,
}

const updateOffersHandler = (state, action) => {
  state[action.payload.energyType] = {
    ...(state[action.payload.energyType] || {}),
    ...keysToCamel(action.payload),
  }
}

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    updateOffers: updateOffersHandler,
    clearOffers: () => initialState,
  },
})

export const { updateOffers, clearOffers } = offersSlice.actions

export default offersSlice.reducer
