import { useEffect, useState } from 'react'
import withFormField from '../Hoc/withFormField'
import PopUp from '../PopUp'
import IconWithMouseEvents from '../Icon'
import classes from './Select.module.scss'
import { useTranslation } from 'react-i18next'

const Select = ({
  field,
  options,
  disabled,
  placeHolder,
  size,
  position,
  allowValueClearing,
  changeHandler: outerChangeHandler,
  triggerExtraClasses,
  cardExtraClasses,
  dataCy
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation(['common'])

  const [triggerLabel, setTriggerLabel] = useState(
    field.value?.label || placeHolder || t('common:select')
  )

  const changeHandler = value => {
    outerChangeHandler?.(value)
    field.onChange(value)
    setIsOpen(false)
  }

  const OptionList = ({ setIsOpen, currentValue }) => (
    <ul
      className={classes.Select__list}
      onBlur={() => {
        field.onBlur()
        setIsOpen?.()
      }}
    >
      {options.map(option => (
        <li
          className={
            option.value === currentValue
              ? [classes.Select__item, classes['Select__item--active']].join(
                  ' '
                )
              : classes.Select__item
          }
          key={`${field.name}-${option.label}`}
          onClick={() => {
            changeHandler(option.value)
            setIsOpen(false)
          }}
          data-cy={option.label}
        >
          {option.label}
        </li>
      ))}
    </ul>
  )

  const cardClasses = [classes.Select__card]

  Array.isArray(cardExtraClasses) && cardClasses.push(...cardExtraClasses)

  useEffect(() => {
    for (const option of options) {
      if (field.value === option.value) {
        setTriggerLabel(option.label)
        return
      }
    }
    setTriggerLabel(placeHolder || t('common:select...'))
  }, [field.value, options, placeHolder])

  return (
    <PopUp
      size={size}
      position={position}
      disabled={disabled}
      triggerLabel={
        <button
          type='button'
          tabIndex='0'
          className={classes.Select__label}
          ref={field.ref}
        >
          {triggerLabel}
          {!!field.value && allowValueClearing && (
            <IconWithMouseEvents
              clickHandler={() => changeHandler('')}
              fill='danger'
              size='sm'
              iconId='icon-cross'
            />
          )}
        </button>
      }
      cardExtraClasses={cardClasses}
      triggerExtraClasses={triggerExtraClasses}
      isOpen={isOpen}
      positioned
      dataCy={dataCy}
      tabIndex='-1'
    >
      {setIsOpen => (
        <OptionList setIsOpen={setIsOpen} currentValue={field.value} />
      )}
    </PopUp>
  )
}

export default withFormField(Select)
