import classes from './GridRow.module.scss'

const Row = ({
  children,
  center,
  right,
  alignItemsCenter,
  columnDirection,
  extraClasses,
}) => {
  const rowClasses = [classes.Row]
  center && rowClasses.push(classes['Row--center'])
  right && rowClasses.push(classes['Row--right'])
  alignItemsCenter && rowClasses.push(classes['Row--align-item-center'])
  columnDirection && rowClasses.push(classes['Row--column-direction'])
  Array.isArray(extraClasses) && rowClasses.push(...extraClasses)
  return <div className={rowClasses.join(' ')}>{children}</div>
}

export { Row as default }
