import { useTranslation } from 'react-i18next'
import Icon from '../components/Icon'
import classes from '@features/EstimationForm/FormPage.module.scss'

export default function EnedisModalError() {
  const { t } = useTranslation(['validation', 'common', 'eld'])

  return (
    <>
      <h1 className={classes['FormPage__title-modal']}>
        <Icon
          iconId='icon-warning-triangle'
          fill='secondary'
          size='xl'
          ex1Classes={[classes['FormPage__icon-pdl-modal']]}
        />
        {t('common:service not available')}
      </h1>
      <p className={classes['FormPage__text-modal']}>
        {t('common:service not available continue explanation')}
      </p>
      <p className={classes['FormPage__consumption-option']}>
        {t('estimationForm:calculate savings.estimateConsumption')}
      </p>
      <p className={classes['FormPage__consumption-option']}>
        {t('estimationForm:calculate savings.knowConsumption')}
      </p>
    </>
  )
}
