export const siteRoutes = [
  // NotFound wildcard
  {
    path: '*',
    component: 'NotFound',
  },
  // Default Route
  {
    path: '',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
      showEnergySelector: true,
    },
  },
  // Estimation Form Page
  {
    path: 'comparar-eletricidade',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  {
    path: 'comparar-gas',
    component: 'EstimationForm',
    props: {
      configModuleId: 'gasEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'gas',
      pageType: 'part',
    },
  },
  {
    path: 'comparar-eletricidade-gas',
    component: 'EstimationForm',
    props: {
      configModuleId: 'dualEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'dual',
      pageType: 'part',
    },
  },

  // Product List Page
  {
    path: 'eletricidade',
    component: 'ProductList',
    props: {
      energyType: 'elec',
      pageType: 'part',
    },
  },
  {
    path: 'gas',
    component: 'ProductList',
    props: {
      energyType: 'gas',
      pageType: 'part',
    },
  },
  {
    path: 'eletricidade-gas',
    component: 'ProductList',
    props: {
      energyType: 'dual',
      pageType: 'part',
    },
  },
  // Product Page
  {
    path: 'oferta/*',
    component: 'ProductPage',
  },
  // Checkout
  {
    path: 'checkout/*',
    component: 'Checkout',
    props: {
      siteId: 2,
      singleOfferTunnel: false,
    },
  },
  {
    path: 'clear-data',
    component: 'ClearRedux',
  },
]

export default siteRoutes
