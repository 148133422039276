import { useTranslation } from 'react-i18next'
import Icon from '@common/components/Icon'
import classes from './EnergyInfoBeModal.module.scss'

const EnergyDistributionInfoBeModal = () => {
  const { t } = useTranslation(['common', 'checkout'])
  const distributionTexts = t('checkout:energy info modals.energy distribution.texts', {
    returnObjects: true,
  })
  const distributionList = t('checkout:energy info modals.energy distribution.list', {
    returnObjects: true,
  })
  const outro = t('checkout:energy info modals.energy distribution.outro')

  return (
    <div className={classes.EnergyInfoBeModal__text}>
      <h1 className={classes.EnergyInfoBeModal__title}>
        <Icon
          iconId='circle-interrogation-filled'
          fill='primary'
          size='xl'
          position='left'
        />
        {t('checkout:energy price')}
      </h1>
      {distributionTexts.map(text => (
        <p key={text}
           dangerouslySetInnerHTML={{ __html: text }}
        />
      ))}
      <ul className={classes.EnergyInfoBeModal__list}>
        {distributionList.map(text => (
          <li key={text}
              dangerouslySetInnerHTML={{ __html: text }}
          />
        ))}
      </ul>
      <p className={classes.EnergyDistributionInfoBeModal__text}
         dangerouslySetInnerHTML={{ __html: outro }}
      />
    </div>
  )
}

export { EnergyDistributionInfoBeModal as default }
