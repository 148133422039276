import Icon from '@components/Icon'
import classes from './ProSuccessfulContactModal.module.scss'
import { Button } from '@components/index'
import { clearModal } from '@root/reducers/modal/modalSlice'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function ProSuccessfulContactModal() {
  const dispatch = useDispatch()
  const { t } = useTranslation(['estimationForm', 'common'])

  return (
    <>
      <div className={classes.ProSuccessfulContactModal__content}>
        <Icon
          iconId='icon-checkmark-rounded'
          size='xl'
          fill='success'
          extraClasses={[classes.ProSuccessfulContactModal__icon]}
        />
        <p className={classes.ProSuccessfulContactModal__title}>
          {t('estimationForm:proContactCreatedModal.title')}
        </p>
        <p className={classes.ProSuccessfulContactModal__information}>
          {t('estimationForm:proContactCreatedModal.message')}
        </p>{' '}
        <Button
          pill
          size='lg'
          color='primary'
          clickHandler={() => {
            dispatch(clearModal())
          }}
          extraClasses={[classes['ProSuccessfulContactModal__close-modal']]}
        >
          {t('common:return to menu')}
          <Icon iconId='icon-chevron-right' fill='white' position='right' />
        </Button>
      </div>
    </>
  )
}
