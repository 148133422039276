import classes from './HeaderHelpModal.module.scss'
import { useTranslation } from 'react-i18next'
import ContactCard from 'src/common/components/ContactCard'
import Schedule from 'src/common/components/Schedule'
import useTranslatedRoutes from '@hooks/useTranslatedRoutes'
import { useLocation } from 'react-router-dom'
import { PROFESSIONALS_PAGE } from '@root/constants'

const HeaderHelpModal = () => {
  const { t } = useTranslation(['estimationForm'])
  const { pathname } = useLocation()
  const { getPageTypeFromPath } = useTranslatedRoutes()
  const pageType = getPageTypeFromPath(pathname)

  const isProfessionalsPage = pageType === PROFESSIONALS_PAGE
  const phone = isProfessionalsPage
    ? t('estimationForm:header.helpModal.phoneNumberPro')
    : t('estimationForm:header.helpModal.phoneNumber')
  const phoneVisual = isProfessionalsPage
    ? t('estimationForm:header.helpModal.phoneNumber visual pro')
    : t('estimationForm:header.helpModal.phoneNumber visual')

  return (
    <div data-cy='help-modal'>
      <h1 className={classes.HeaderHelpModal__title}>
        {t('estimationForm:header.helpModal.title')}
      </h1>
      <p className={classes.HeaderHelpModal__text}>
        {t('estimationForm:header.helpModal.description')}
      </p>
      <ContactCard
        id='cmb-help'
        phone={phone}
        phoneVisual={phoneVisual}
        withCallback
        withIcons
        extraClasses={[classes['HeaderHelpModal__contact-card']]}
      />
      <Schedule />
    </div>
  )
}

export { HeaderHelpModal as default }
