import classes from './PaginationItem.module.scss'

const PaginationItem = ({ page, current, setCurrentPage, children }) => {
  const styles = [classes.PaginationItem]

  if (page === current) {
    styles.push(classes.PaginationItem__active)
  }
  return (
    <div
      className={styles.join(' ')}
      onClick={() => {
        setCurrentPage(page)
      }}
    >
      {children || page}
    </div>
  )
}

export default PaginationItem
