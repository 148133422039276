import { lazy } from 'react'

export const fr = {
  knowConsumption: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-know-consumption' */
      './KnowConsumption'
    )
  ),
  cityAutocomplete: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-city' */
      './CityAutocomplete'
    )
  ),
  equipments: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-equipments' */
      './Equipments'
    )
  ),
  enedisLegalChecks: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-pdl-information' */
      './EnedisLegalChecks'
    )
  ),
  contactFields: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-contact-fields' */
      './ContactFields'
    )
  ),
  energySelector: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-newsletter' */
      './EnergySelector'
    )
  ),
  proContactFields: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-pro-contact-fields' */
      './ProContactFields'
    )
  ),
  contactSection: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-form-contact-section' */
      './ContactSection'
    )
  ),
  powerSelect: lazy(() =>
    import(
      /* webpackChunkName: 'app-fr-estimation-power-select' */
      './PowerSelect')
  )
}
