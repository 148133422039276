const defaultValues = {
  elec: {
    annualConsoElec: 411,
    knowConsumption: '1',
    energyType: 'elec',
    tariffOption: 'base',
    subscribedPower: 4,
    elecInvoiceDays: 30,
    // consoHp: 98.64,
    // consoHc: 205.5,
    // consoHf: 106.86,
  },
  gas: {
    annualConsoGas: 584,
    knowConsumption: '1',
    energyType: 'elec',
    tariffOption: 'base',
    subscribedPower: 4,
    gasInvoiceDays: 30,
  },
  dual: {
    annualConsoElec: 411,
    annualConsoGas: 584,
    knowConsumption: '1',
    energyType: 'elec',
    tariffOption: 'base',
    subscribedPower: 4,
    elecInvoiceDays: 30,
    gasInvoiceDays: 30,
    // consoHp: 98.64,
    // consoHc: 205.5,
    // consoHf: 106.86,
  },
}

export default defaultValues
