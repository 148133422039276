export const siteRoutes = [
  // NotFound wildcard
  {
    path: '*',
    component: 'NotFound',
  },
  // Default Route
  {
    path: '',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
      showEnergySelector: true,
    },
  },
  // Estimation Form Page
  {
    path: 'comparaison',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
      showEnergySelector: true,
    },
  },
  {
    path: 'comparaison-electricite',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  {
    path: 'comparaison-gaz',
    component: 'EstimationForm',
    props: {
      configModuleId: 'gasEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'gas',
      pageType: 'part',
    },
  },
  {
    path: 'comparaison-electricite-gaz',
    component: 'EstimationForm',
    props: {
      configModuleId: 'dualEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'dual',
      pageType: 'part',
    },
  },
  // Product List Page
  {
    path: 'electricite',
    component: 'ProductList',
    props: {
      formConfigModuleId: 'elecEstimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  {
    path: 'gaz',
    component: 'ProductList',
    props: {
      formConfigModuleId: 'gasEstimationForm',
      energyType: 'gas',
      pageType: 'part',
    },
  },
  {
    path: 'electricite-gaz',
    component: 'ProductList',
    props: {
      formConfigModuleId: 'dualEstimationForm',
      energyType: 'dual',
      pageType: 'part',
    },
  },
  // Product Page
  {
    path: 'offres/*',
    component: 'ProductPage',
  },
  // Checkout
  {
    path: 'checkout/*',
    component: 'Checkout',
    props: {
      siteId: 1,
      singleOfferTunnel: false,
    },
  },
  // Single electricity offer tunnel
  {
    path: 'estimation-electricite/*',
    component: 'Checkout',
    props: {
      siteId: 1,
      singleOfferTunnel: true,
    },
  },
  // Single gas offer tunnel
  {
    path: 'estimation-gaz/*',
    component: 'Checkout',
    props: {
      siteId: 1,
      singleOfferTunnel: true,
    },
  },
  // Single dual offer tunnel
  {
    path: 'estimation-dual/*',
    component: 'Checkout',
    props: {
      siteId: 1,
      singleOfferTunnel: true,
    },
  },
  {
    path: 'clear-data',
    component: 'ClearRedux',
  },
]

export default siteRoutes
