import { createSelector } from '@reduxjs/toolkit'
import { t } from 'i18next'
import { formatPdl } from '@root/utilities/string-utils'
import {
  SENDING_BLUE_TEMPLATE_ID,
  SENDING_BLUE_URLS,
  SENDING_BLUE_UTM_CAMPAIGN,
  SENDING_BLUE_UTM_INFO
} from '@root/constants'

export const selectUser = state => state.user

export const selectUserFormData = createSelector(
  selectUser,
  userData => userData?.formData
)

export const selectUserCurrentEnergyType = createSelector(
  selectUserFormData,
  formData => formData?.currentEnergyType
)

export const selectUserData = createSelector(
  selectUser,
  selectUserCurrentEnergyType,
  (user, energyType) => {
    if (!user) {
      return {}
    }

    if (!user?.formData) {
      return {}
    }

    return {
      ...user?.formData[energyType],
      ...user?.personalInformation
    }
  }
)

export const selectUserTrackData = createSelector(
  selectUser,
  selectUserCurrentEnergyType,
  (user, energyType) => {
    const { email } = user?.personalInformation
    const notAvailableUserTrackLink =
      !user?.formData ||
      !Object.keys(user?.formData[energyType] ?? {}).length ||
      !email

    if (notAvailableUserTrackLink) {
      return {}
    }

    const {
      annualConsoElec,
      consoHp,
      consoHc,
      annualConsoGas,
      tariffOption,
      subscribedPower,
      houseType
    } = user?.formData[energyType]
    const { cityId } = user?.personalInformation.city ?? {}
    const sendingBlueUrl = SENDING_BLUE_URLS[energyType]

    const urlParameters = {
      ...(annualConsoElec && { car_base: annualConsoElec }),
      ...(consoHp && { car_hp: consoHp }),
      ...(consoHc && { car_hc: consoHc }),
      ...(annualConsoGas && { car_gas: annualConsoGas }),
      ...(tariffOption && { tariff_option: tariffOption }),
      ...(subscribedPower && { subscribed_power: subscribedPower }),
      house_type: houseType,
      city_id: cityId,
      ...SENDING_BLUE_UTM_INFO,
      utm_campaign: `${SENDING_BLUE_UTM_CAMPAIGN}-${energyType}`
    }

    const urlSearchParams = new URLSearchParams(urlParameters)

    return {
      properties: {
        email
      },
      eventData: {
        data: {
          url: `${sendingBlueUrl}?${urlSearchParams}`,
          templateId: SENDING_BLUE_TEMPLATE_ID
        }
      }
    }
  }
)

export const selectUserCurrentFormData = createSelector(
  selectUser,
  selectUserCurrentEnergyType,
  (user, energyType) => user?.formData[energyType]
)

export const selectUserComesFromPdlFinder = createSelector(
  selectUserData,
  userData => userData?.formModule === 'pdlFinder'
)

export const selectUserDataPhone = createSelector(
  selectUserData,
  userData => userData?.phone
)

export const selectUserPersonalInformation = createSelector(
  selectUser,
  userData => userData?.personalInformation
)

export const selectUserName = createSelector(
  selectUserPersonalInformation,
  userPersonalInformation => {
    if (!userPersonalInformation?.firstName) {
      return ''
    }
    return `, ${userPersonalInformation?.firstName ?? ''} ${
      userPersonalInformation?.lastName ?? ''
    }`
  }
)

export const selectUserDataPropane = createSelector(
  selectUserData,
  userData => userData?.propaneSelected
)

export const selectUserShouldSeeResultsUserComebackModal = createSelector(
  selectUser,
  user => user?.shouldSeeResultsUserComebackModal
)

export const selectUserShouldSeeResultsTypeform = createSelector(
  selectUser,
  user => user?.shouldSeeResultsTypeform
)

export const selectUserShouldSeeProductList = createSelector(
  selectUser,
  user => user?.shouldSeeProductList
)

export const selectUserComebackPersonalInformation = createSelector(
  selectUserPersonalInformation,
  userPersonalInformation => {
    if (!userPersonalInformation) {
      return {}
    }

    const { cityAutocomplete, email, phone, firstName, lastName } =
      userPersonalInformation

    return {
      cityAutocomplete,
      ...(firstName && { name: `${firstName} ${lastName}` }),
      ...(phone && { phone }),
      ...(email && { email })
    }
  }
)

export const selectUserComebackElecConsumptionInformation = createSelector(
  selectUserCurrentFormData,
  formData => {
    if (!formData) {
      return {}
    }

    const {
      tariffOption,
      consoHc,
      consoHp,
      annualConsoElec,
      subscribedPower,
      pdl
    } = formData

    return {
      ...(pdl && { pdl: `PDL: <strong> ${formatPdl(pdl)} </strong>` }),
      ...(tariffOption === 'base' && {
        annualConsoElec: `${t(
          'estimationForm:heatingEnergy.option.electricity'
        )} : <strong>${annualConsoElec} ${t('common:kwh per year')}</strong>`
      }),
      ...(tariffOption === 'hphc' && {
        consoHp: `${t(
          'estimationForm:consumption.electricityHpLabel'
        )} : <strong>${consoHp} ${t('common:kwh per year')}</strong>`,
        consoHc: `${t(
          'estimationForm:consumption.electricityHcLabel'
        )} : <strong>${consoHc} ${t('common:kwh per year')}</strong>`
      }),
      power: `${t('pdl:power')} <strong>${subscribedPower} ${t(
        'common:kva'
      )}</strong>`
    }
  }
)

export const selectUserComebackGasConsumptionInformation = createSelector(
  selectUserCurrentFormData,
  formData => {
    if (!formData) {
      return {}
    }

    const { annualConsoGas, houseType: formHouseType } = formData

    const houseType =
      formHouseType === 'actual' ? 'Logement actuel' : 'Déménagement'

    return {
      houseType,
      annualConsoGas: `${t(
        'estimationForm:heatingEnergy.option.gas'
      )} : <strong>${annualConsoGas} ${t('common:kwh per year')}</strong>`
    }
  }
)

export const selectUserComebackDualConsumptionInformation = createSelector(
  selectUserCurrentFormData,
  formData => {
    if (!formData) {
      return {}
    }

    const {
      tariffOption,
      consoHc,
      consoHp,
      annualConsoElec,
      subscribedPower,
      houseType: formHouseType,
      annualConsoGas
    } = formData

    const houseType =
      formHouseType === 'actual' ? 'Logement actuel' : 'Déménagement'

    return {
      houseType,
      ...(tariffOption === 'base' && {
        annualConsoElec: `${t(
          'estimationForm:heatingEnergy.option.electricity'
        )} : <strong>${annualConsoElec} ${t('common:kwh per year')}</strong>`
      }),
      ...(tariffOption === 'hphc' && {
        consoHc: `${t(
          'estimationForm:consumption.electricityHcLabel'
        )} : <strong>${consoHc} ${t('common:kwh per year')}</strong>`,
        consoHp: `${t(
          'estimationForm:consumption.electricityHcLabel'
        )}: <strong>${consoHp} ${t('common:kwh per year')}</strong>`
      }),
      power: `${t('pdl:power')} <strong>${subscribedPower} ${t(
        'common:kva'
      )}</strong>`,
      annualConsoGas: `${t(
        'estimationForm:heatingEnergy.option.gas'
      )} : <strong>${annualConsoGas} ${t('common:kwh per year')}</strong>`
    }
  }
)

export const selectUserComebackConsumptionData = createSelector(
  selectUserComebackElecConsumptionInformation,
  selectUserComebackGasConsumptionInformation,
  selectUserComebackDualConsumptionInformation,
  (elecConsumptionData, gasConsumptionData, dualConsumptionData) => {
    return {
      elec: elecConsumptionData,
      gas: gasConsumptionData,
      dual: dualConsumptionData
    }
  }
)

export const selectUserDataCompareRequest = createSelector(
  selectUserData,
  userData => {
    return userData
      ? Object.assign(
        Object.create(null),
        {
          energy_type: userData.energyType,
          insee: userData.city.inseeCode
        },
        (userData.energyType || userData.energyChoice) !== 'gas' &&
            userData.tariffOption !== 'hphc'
          ? {
              tariff_option: userData.tariffOption,
              car_base: Number.parseInt(userData.annualConsoElec),
              power: userData.subscribedPower
            }
          : null,
        (userData.energyType || userData.energyChoice) !== 'gas' &&
            userData.tariffOption === 'hphc'
          ? {
              tariff_option: userData.tariffOption,
              car_hp: Number.parseInt(userData.consoHp),
              car_hc: Number.parseInt(userData.consoHc),
              power: userData.subscribedPower
            }
          : null,
        (userData.energyType || userData.energyChoice) !== 'elec'
          ? {
              car_gas: userData.annualConsoGas
            }
          : null
      )
      : null
  }
)

export const selectUserError = createSelector(selectUser, user => user?.error)

export const selectUserShouldSeeHelpButtonPopUp = createSelector(
  selectUser,
  user => user?.shouldSeeHelpButtonPopUp
)

export const selectUserSawTypeform = createSelector(
  selectUser,
  user => user?.sawTypeform
)

export const selectUserExperimentId = createSelector(
  selectUser,
  user => user?.experimentId
)

export const selectUserPhoneToCall = createSelector(
  selectUser,
  user => user?.phoneToCall
)

export const selectUserPhoneVisualToCall = createSelector(
  selectUser,
  user => user?.phoneVisualToCall
)
