import { lazy } from 'react'
import {
  fr,
  it,
  beFr,
  beNl,
  jp,
  es,
  pt,
} from 'src/features/EstimationForm/FormFields'

const countryComponents = {
  fr,
  it,
  beFr,
  beNl,
  jp,
  es,
  pt,
}

const components = {
  buttonGroup: lazy(() => import('@common/components/ButtonGroup')),
  checkbox: lazy(() => import('@common/components/Checkbox')),
  col: lazy(() => import('../Layout/grid/GridColumn')),
  contactFields: lazy(() =>
    import('@features/EstimationForm/FormFields/fr/ContactFields')
  ),
  equipments: lazy(() =>
    import('@features/EstimationForm/FormFields/fr/Equipments')
  ),
  enedisLegalChecks: lazy(() =>
    import('@features/EstimationForm/FormFields/fr/EnedisLegalChecks')
  ),
  formGroup: lazy(() => import('@common/components/FormGroup')),
  input: lazy(() => import('@common/components/Input')),
  inputNumeric: lazy(() => import('@common/components/InputNumeric')),
  inputMask: lazy(() => import('@common/components/InputMask')),
  inputAutocomplete: lazy(() => import('@common/components/InputAutocomplete')),
  row: lazy(() => import('../Layout/grid/GridRow')),
  select: lazy(() => import('@common/components/Select')),
  stepper: lazy(() => import('@common/components/Stepper')),
}

const FormComponents = country => {
  return { ...components, ...countryComponents[country] }
}

export default FormComponents
