import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { setupListeners } from '@reduxjs/toolkit/query'
import createSagaMiddleware from 'redux-saga'
import Sagas from './Sagas'

import { endpointsApi } from '@/reducers/endpoints/endpointsSlice'
import { rootReducer } from './Reducer'
import { reviewsApi } from '@root/reducers/reviews/reviewsSlice'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware()

export default preloadedState => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => [
      ...getDefaultMiddleware({
        serializableCheck: false,
      }),
      endpointsApi.middleware,
      reviewsApi.middleware,
      sagaMiddleware,
    ],
    preloadedState,
    devTools: !import.meta.env.PROD,
  })

  sagaMiddleware.run(Sagas)

  const persistor = persistStore(store)
  setupListeners(store.dispatch)

  return {
    store,
    persistor,
  }
}
