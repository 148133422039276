import { useState, useEffect } from 'react'
import classes from './ProgressBar.module.scss'

const ProgressBar = props => {
  const { percentage } = props
  const [percent, setPercent] = useState(0)

  useEffect(() => {
    setPercent(percentage)
  }, [percentage])
  return (
    <div className={classes.ProgressBar__containerStyles}>
      <div
        className={classes.ProgressBar__fillerStyles}
        style={{
          width: `${percent}%`,
        }}
      ></div>
    </div>
  )
}

export default ProgressBar
