import { Input } from '../Input/Input'
import withFormField from '../Hoc/withFormField'
import withAddons from '../Hoc/withAddons'

// TODO: We loose the focus when we enter number
const InputNumeric = ({
  field,
  changeHandler: outerChangeHandler,
  disabled,
  readOnly,
  min,
  max,
  dataCy,
  control,
  hidden,
}) => {
  if (hidden) {
    return null
  }
  return (
    <Input
      field={field}
      changeHandler={event => {
        if (!/^[0-9]*$/.test(event.target.value)) {
          return event.preventDefault()
        }
        let numericValue = Number.parseInt(event.target.value)
        if (!Number.isNaN(numericValue)) {
          if (min && numericValue < min) {
            numericValue = min
          } else if (max && numericValue > max) {
            numericValue = max
          }
          event.target.value = `${numericValue}`
        } else {
          event.target.value = ''
        }
        outerChangeHandler?.(event)
      }}
      inputMode='numeric'
      disabled={disabled}
      readOnly={readOnly}
      dataCy={dataCy}
      control={control}
    />
  )
}

export default withFormField(withAddons(InputNumeric))
