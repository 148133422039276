import { getSiteConfig } from '@/utilities/config'
import { dateByUtc } from './date'

const timeToMilitary = time => {
  const [hours, minutes] = time.split(':')
  const militaryTime = parseInt(hours, 10) * 100 + parseInt(minutes, 10)
  return militaryTime
}

export const isCallcenterOpen = () => {
  const config = getSiteConfig()
  const currentDate = dateByUtc(config.utc)
  const currentDay = currentDate
    .toLocaleDateString('en-US', { weekday: 'long' })
    .toLowerCase()

  if (!config?.openingHours?.[currentDay].length) {
    return false
  }

  const [openingStringTime, closingStringTime] = config.openingHours[currentDay]

  const currentTime = currentDate.getHours() * 100 + currentDate.getMinutes()
  const openingTime = timeToMilitary(openingStringTime)
  const closingTime = timeToMilitary(closingStringTime)

  return currentTime >= openingTime && currentTime < closingTime
}

export const getUtcBySummerSchedule = options => {
  const summerStartMonthNumber = options?.summerStartMonthNumber ?? 3
  const summerEndMonthNumber = options?.summerEndMonthNumber ?? 10
  const summerUtc = options?.summerUtc ?? 2
  const notSummerUtc = options?.notSummerUtc ?? 1

  const now = new Date()
  const year = now.getFullYear()
  const summerStart = new Date(year, summerStartMonthNumber - 1, 31)
  const summerEnd = new Date(year, summerEndMonthNumber - 1, 31)
  const summerStartWeekday = summerStart.getDay()
  const summerEndWeekday = summerEnd.getDay()
  summerStart.setDate(31 - summerStartWeekday)
  summerEnd.setDate(31 - summerEndWeekday)

  if (now >= summerStart && now < summerEnd) {
    return summerUtc
  }

  return notSummerUtc
}
