import Button from '@common/components/Button/Button'
import classes from './MySelectraModal.module.scss'
import { useTranslation } from 'react-i18next'
import { Icon } from '@common/components'
import { getGlobalConfig } from '@root/utilities/config'

const MySelectraModal = ({ buttonId }) => {
  const { t } = useTranslation(['estimationForm'])
  const { urls } = getGlobalConfig()

  const navigateToMySelectra = () => {
    open(urls.mySelectra, '_blank')
  }

  const benefitsList = t('estimationForm:header.mySelectraModal.list', {
    returnObjects: true
  })

  return (
    <div className={classes.MySelectraModal}>
      <h4 className={classes['MySelectraModal__title-header']}>
        <Icon
          iconId='icon-acute'
          fill='success'
          size='xl'
          position='left'
          extraClasses={[classes['MySelectraModal__title-header__icon']]}
        />
        {t('estimationForm:header.mySelectraModal.title')}
      </h4>
      <p
        className={classes.MySelectraModal__intro}
        dangerouslySetInnerHTML={{
          __html: t('estimationForm:header.mySelectraModal.intro')
        }}
      />
      <ul className={classes.MySelectraModal__list}>
        {benefitsList.map(text => (
          <li key={text}>
            <Icon
              iconId='icon-thin-checkmark'
              size='xl'
              fill='success'
              position='left'
              extraClasses={[classes['SideCard__guidance-icon']]}
            />
            {text}
          </li>
        ))}
      </ul>
      <Button
        id={buttonId}
        color='primary'
        pill
        block
        clickHandler={navigateToMySelectra}
      >
        {t('estimationForm:header.mySelectraModal.button')}
        <Icon
          iconId='icon-external-arrow'
          fill='neutral-light'
          size='sm'
          position='right'
          extraClasses={[classes['MySelectraModal__phone-icon']]}
        />
      </Button>
    </div>
  )
}

export { MySelectraModal as default }
