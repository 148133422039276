import { createSelector } from '@reduxjs/toolkit'
import { selectCurrentOfferProviderSlug } from '@root/reducers/currentOffer/currentOfferSelectors'
import { EXTERNAL_ID_PROVIDERS_PARAMETER } from '@root/constants'

export const selectContract = state => state.contract

export const selectContractData = createSelector(
  selectContract,
  contract => contract?.data
)

export const selectUtmCampaignName = createSelector(
  selectContractData,
  contract => contract?.campaignData?.name
)

export const selectExternalId = createSelector(
  selectContractData,
  selectCurrentOfferProviderSlug,
  (contract, providerSlug) => {
    if (!contract?.campaignData?.externalId) {
      return null
    }

    const externalIdParameter = EXTERNAL_ID_PROVIDERS_PARAMETER[providerSlug] ?? 'external_id'

    return `${externalIdParameter}=${contract.campaignData.externalId}`
  }
)
