import { forwardRef } from 'react'
import classes from './Button.module.scss'
import Icon from '@components/Icon'

const ButtonWithRef = (
  {
    value,
    children,
    id,
    name,
    ariaLabel,
    type,
    style,
    clickHandler,
    href,
    target,
    rel,
    color,
    extraClasses,
    extraAttributes,
    size,
    pill,
    outline,
    block,
    disabled,
    tabIndex,
    dataCy,
    isLoading,
    isActionCompleted,
    hidden
  },
  ref
) => {
  if (hidden) {
    return
  }

  const buttonClickHandler = href
    ? event => {
      const link = document.createElement('a')
      link.href = href
      link.target = target || ''
      link.rel = rel || ''
      link.click()
      clickHandler?.(event)
    }
    : clickHandler

  const buttonClasses = [classes.Button]

  buttonClasses.push(classes[`Button--${size ?? 'sm'}`])
  buttonClasses.push(classes[`Button--${color ?? 'primary'}`])
  pill && buttonClasses.push(classes['Button--pill'])
  outline && buttonClasses.push(classes['Button--outline'])
  block && buttonClasses.push(classes['Button--block'])
  disabled &&
    !isActionCompleted &&
    buttonClasses.push(classes['Button--disabled'])
  isLoading && buttonClasses.push(classes['Button--loading'])
  isActionCompleted &&
    buttonClasses.push(classes['Button--action-is-completed'])

  Array.isArray(extraClasses) && buttonClasses.push(...extraClasses)

  return (
    <button
      data-cy={dataCy}
      aria-label={ariaLabel ?? name}
      ref={ref}
      id={id || null}
      type={type || 'button'}
      className={[buttonClasses.join(' ')]}
      onClick={disabled ? null : buttonClickHandler}
      style={style}
      disabled={disabled}
      value={value}
      tabIndex={tabIndex || '0'}
      {...extraAttributes}
    >
      {isLoading
        ? (
          <Icon iconId='icon-loader' size='lg' rotate fill='primary' />
          )
        : (
            children
          )}
    </button>
  )
}

export default forwardRef(ButtonWithRef)
