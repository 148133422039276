import { getSiteConfig } from '@/utilities/config'
import DOMPurify from 'dompurify'
import unidecode from 'unidecode'
import { isNumeric } from './number-utils'

const capitalize = string =>
  string
    .split(' ')
    .map(word =>
      word ? word[0].toUpperCase() + word.substr(1).toLowerCase() : null
    )
    .join(' ')
    .trim()

const padNum = (number, length = 2) =>
  ('0'.repeat(length) + number).slice(-length)

const parseHTMLEntities = string =>
  new DOMParser().parseFromString(DOMPurify.sanitize(string), 'text/html')
    .documentElement.textContent

const formatPrice = (amount, maxDecimals = 2, minDecimals = 2) => {
  if (!isNumeric(amount)) {
    return ''
  }

  amount = +amount

  const config = getSiteConfig()
  const hasDecimals = amount !== Math.floor(amount)
  const localeConfig = hasDecimals
    ? {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
      }
    : {}

  return config?.locale
    ? amount.toLocaleString(config.locale, localeConfig)
    : amount.toString().replace('.', ',')
}

const formatPriceWithCurrency = (amount, maxDecimals) => {
  const price = formatPrice(amount, maxDecimals)
  const config = getSiteConfig()

  return config.priceFormat
    .replace('{price}', price)
    .replace('{currencySymbol}', config.currencySymbol)
}

const looseSearch = (string, search) =>
  new RegExp(`(?:^| )${unidecode(search).toLowerCase()}`).test(
    unidecode(string).toLowerCase()
  )

const formatPdl = pdl =>
  pdl &&
  [pdl.substr(0, 2)].concat(pdl.substr(2, pdl.length).match(/.{3}/g)).join(' ')

const camelToKebabCase = str =>
  String(str).replace(/[A-Z]/g, letter => `-${letter.toLowerCase()}`)

const cleanTextForMatching = text => {
  return text
    .normalize('NFKD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/-/g, '')
    .replace(/ /g, '')
    .toLowerCase()
    .replace(/[^a-z0-9 ]/g, '')
}

export {
  capitalize,
  padNum,
  parseHTMLEntities,
  formatPrice,
  formatPriceWithCurrency,
  looseSearch,
  formatPdl,
  camelToKebabCase,
  cleanTextForMatching,
}
