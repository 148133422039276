import { Col, Row } from '@components/Layout/grid/Grid'
import Icon from '@components/Icon'
import classes from './PDLInfoModal.module.scss'
import { Trans, useTranslation } from 'react-i18next'

const PDLInfoModal = () => {
  const { t } = useTranslation(['common', 'checkout'])
  return (
    <>
      <h1 className={classes.PDLInfoModal__title}>
        <Icon iconId='icon-meter' fill='primary' size='lg' position='left' />
        {t('common:pdl long')}
      </h1>
      <p className={classes.PDLInfoModal__text}>
        <Trans i18nKey='checkout:pdl info.modal_text'>
          Le point de livraison ou PDL est l’identifiant unique de votre
          compteur électrique, il est composé de <strong>14 chiffres</strong>{' '}
          que vous pouvez retrouver de 2 manières différentes :
        </Trans>
      </p>
      <Row extraClasses={[classes.PDLInfoModal__section]}>
        <Col md={7}>
          <h2 className={classes.PDLInfoModal__subtitle}>
            {t('checkout:pdl info.section_1.subtitle')}
          </h2>
          <p className={classes.PDLInfoModal__text}>
            <Trans i18nKey='checkout:pdl info.section_1.text'>
              Par exemple, sur une facture EDF, il peut être référencé en tant
              que <strong>numéro de point de comptage</strong> et se situe plus
              ou moins dans la zone de l’encadré ci-dessous.
            </Trans>
          </p>
        </Col>
        <Col md={{ width: 4, offset: 1 }}>
          <img src='/img/new/edf-bill.svg' alt='bill-example' />
        </Col>
      </Row>
      <hr className={classes.PDLInfoModal__separator} />
      <Row extraClasses={[classes.PDLInfoModal__section]}>
        <Col md={7}>
          <h2 className={classes.PDLInfoModal__subtitle}>
            {t('checkout:pdl info.section_2.subtitle')}
          </h2>
          <p className={classes.PDLInfoModal__text}>
            <Trans i18nKey='checkout:pdl info.section_2.text'>
              Ici le PDL est réferencé en tant que <strong>Numéro PRM</strong>,
              vous pouvez l’afficher en appuyant plusieurs fois sur le bouton +
              de votre compteur.
            </Trans>
          </p>
        </Col>
        <Col md={{ width: 4, offset: 1 }}>
          <img src='/img/new/linky.svg' alt='linky-example' />
        </Col>
      </Row>
    </>
  )
}

export { PDLInfoModal as default }
