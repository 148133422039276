import { getUtcBySummerSchedule } from '@root/utilities/site'
import footerLinks from './footerLinks'
import siteRoutes from './siteRoutes'
import phone from './phone'

const config = {
  urls: {
    app: {
      staging: 'be-fr.energy-comparator-spa.s3.staging.aws.selectra.io',
      production: 'comparateur-energie.callmepower.be',
    },
    api: {
      staging: 'be-fr.energy-comparator.app.staging.aws.selectra.io',
      production: 'comparateur.callmepower.be',
    },
  },
  locale: 'nl-BE',
  utc: getUtcBySummerSchedule(),
  openingHours: {
    monday: ['08:00', '19:00'],
    tuesday: ['08:00', '19:00'],
    wednesday: ['08:00', '19:00'],
    thursday: ['08:00', '19:00'],
    friday: ['08:00', '19:00'],
    saturday: ['08:30', '18:30'],
    sunday: [],
  },
  footerLinks,
  siteRoutes,
  phone,
}

export default config
