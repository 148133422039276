import withFormField from '../Hoc/withFormField'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.min.css'
import classes from './DatePicker.module.scss'
import inputClasses from '@common/components/Input/Input.module.scss'
import fr from 'date-fns/locale/fr'
import Icon from '@components/Icon'
registerLocale('fr', fr)

const CustomDatePicker = ({
  field: { ref, value, onChange: handleOnSelect, onBlur: handleOnBlur },
  minDate,
  maxDate,
  showMonthDropdown,
  showYearDropdown,
  disabled,
  readOnly,
  extraClasses,
}) => {
  const allClasses = [
    classes.DatePicker__input,
    inputClasses.Input__input,
    'Field__control',
  ]

  Array.isArray(extraClasses) && allClasses.push(...extraClasses)

  return (
    <div ref={ref} className={classes['DatePicker__item__with-icon']}>
      <Icon
        iconId='icon-calendar-check'
        fill='neutral-light'
        extraClasses={[classes['DatePicker__item__with-icon__icon']]}
      />
      <DatePicker
        className={allClasses.join(' ')}
        selected={value}
        onSelect={handleOnSelect}
        onBlur={handleOnBlur}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat='dd/MM/yyyy'
        locale='fr'
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        dropdownMode='select'
        disabled={disabled}
        readOnly={readOnly}
      />
    </div>
  )
}

export default withFormField(CustomDatePicker)
