import footerLinks from './footerLinks'
import siteRoutes from './siteRoutes'

const config = {
  urls: {
    app: {
      staging: 'jp.energy-comparator-spa.s3.staging.aws.selectra.io',
      production: 'energy.selectra.jp',
    },
    api: {
      staging: 'jp.energy-comparator.app.staging.aws.selectra.io',
      production: 'hikaku.selectra.jp',
    },
  },
  locale: 'ja-JP',
  utc: 9,
  currencySymbol: '円',
  footerLinks,
  siteRoutes,
}

export default config
