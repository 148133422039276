const footerLinks = [
  {
    href: 'https://callmepower.be/fr/qui-sommes-nous',
    translationKey: 'in regards to',
  },
  {
    href: 'https://callmepower.be/fr/mentions-legales',
    translationKey: 'legal notice',
  },
  {
    href: 'https://callmepower.be/fr/methodologie',
    translationKey: 'methodology and comparatives',
  },
]

export default footerLinks
