import { createSelector } from '@reduxjs/toolkit'

export const selectConfig = state => state.config

export const selectConfigData = createSelector(
  selectConfig,
  config => config?.data
)

export const selectConfigShouldShowCheckoutHeaderProgress = createSelector(
  selectConfigData,
  data => data?.shouldShowCheckoutHeaderProgress
)

export const selectConfigShouldShowProductTablePeriodSwitcher = createSelector(
  selectConfigData,
  data => data?.shouldShowCheckoutProductTablePeriodSwitcher
)

export const selectConfigShouldShowFormDefaultValues = createSelector(
  selectConfigData,
  data => data?.shouldShowFormDefaultValues
)

export const selectConfigCountry = createSelector(
  selectConfigData,
  data => data?.country
)

export const selectConfigShouldShowProviderRating = createSelector(
  selectConfigData,
  data => data?.shouldShowProviderRating
)

export const selectConfigShouldRenderPreviousOccupant = createSelector(
  selectConfigData,
  data => data?.shouldRenderPreviousOccupant
)

export const selectConfigShouldShowHelpOnHeader = createSelector(
  selectConfigData,
  data => data?.shouldShowHelpOnHeader
)

export const selectConfigShouldShowCTAOnHeader = createSelector(
  selectConfigData,
  data => data?.shouldShowCTAOnHeader
)

export const selectConfigShouldShowLinkOnHeader = createSelector(
  selectConfigData,
  data => data?.shouldShowLinkOnHeader
)

export const selectShouldShowMySelectraCTAOnHeaderWithHelp = createSelector(
  selectConfigData,
  data => data?.shouldShowMySelectraCTAOnHeaderWithHelp
)
export const selectShouldShowOffersPriceAlertButtonInProductList =
  createSelector(
    selectConfigData,
    data => data?.shouldShowOffersPriceAlertButtonInProductList
  )

export const selectConfigShouldShowCTAPhoneInProviderDescOnMobile =
  createSelector(
    selectConfigData,
    data => data?.shouldShowCTAPhoneInProviderDescOnMobile
  )

export const selectConfigShouldShowNoticesBelowCTACardOrCTAFooter =
  createSelector(
    selectConfigData,
    data => data?.shouldShowNoticesBelowCTACardOrCTAFooter
  )

export const selectConfigShouldShowResultsUserComebackModal = createSelector(
  selectConfigData,
  data => data?.shouldShowResultsUserComebackModal
)

export const selectShouldShowResultsMySelectraBanner = createSelector(
  selectConfigData,
  data => data?.shouldShowResultsMySelectraBanner
)

export const selectConfigShouldShowTypeform = createSelector(
  selectConfigData,
  data => data?.shouldShowTypeform
)

export const selectConfigShouldShowSavings = createSelector(
  selectConfigData,
  data => data?.shouldShowSavings
)

export const selectConfigShouldShowRating = createSelector(
  selectConfigData,
  data => data?.shouldShowRating
)

export const selectConfigTypeform = createSelector(
  selectConfigData,
  data => data?.configTypeform ?? {}
)

export const selectConfigShouldShowPrintResultsButton = createSelector(
  selectConfigData,
  data => data?.shouldShowPrintResultsButton
)

export const selectConfigShouldShowPowerInResultsHeroCard = createSelector(
  selectConfigData,
  data => data?.shouldShowPowerInResultsHeroCard
)

export const selectShouldShowAnotherMessageWhenIsMoving = createSelector(
  selectConfigData,
  data => data?.shouldShowAnotherMessageWhenIsMoving
)

export const selectShouldShowAnotherMessageWhenCheaperOffersNotFound =
  createSelector(
    selectConfigData,
    data => data?.shouldShowAnotherMessageWhenCheaperOffersNotFound
  )

export const selectConfigShouldShowProductPdfLink = createSelector(
  selectConfigData,
  data => data?.shouldShowProductPdfLink
)

export const selectShouldShowProductNoMeterChanges = createSelector(
  selectConfigData,
  data => data?.shouldShowProductNoMeterChanges
)

export const selectShouldShowProductReviews = createSelector(
  selectConfigData,
  data => data?.shouldShowProductReviews
)

export const selectShouldHideProductReviewsWhenIsReference = createSelector(
  selectConfigData,
  data => data?.shouldHideProductReviewsWhenIsReference
)

export const selectShouldShowOwnResultsHeroCardElec = createSelector(
  selectConfigData,
  data => data?.shouldShowOwnResultsHeroCardElec
)

export const selectShouldShowPdlFinderFormInElec = createSelector(
  selectConfigData,
  data => data?.shouldShowPdlFinderFormInElec
)

export const selectShouldShowResultCardTooltip = createSelector(
  selectConfigData,
  data => data?.shouldShowResultCardTooltip
)

export const selectShouldShowEnergySelectorInModalForm = createSelector(
  selectConfigData,
  data => data?.shouldShowEnergySelectorInModalForm
)

export const selectShouldShowHighlightedOffersInResults = createSelector(
  selectConfigData,
  data => data?.shouldShowHighlightedOffersInResults
)
export const selectShouldShowHighlightedTop3OffersInResults = createSelector(
  selectConfigData,
  data => data?.shouldShowHighlightedTop3OffersInResults
)

export const selectShouldShowNotCheaperOffersFoundMessage = createSelector(
  selectConfigData,
  data => data?.shouldShowNotCheaperOffersFoundMessage
)

export const selectShouldShowHelpButtonPopUp = createSelector(
  selectConfigData,
  data => data?.shouldShowHelpButtonPopUp
)

export const selectShouldShowMonthlyConsumption = createSelector(
  selectConfigData,
  data => data?.shouldShowMonthlyConsumption
)

export const selectShouldShowMonthlyPrices = createSelector(
  selectConfigData,
  data => data?.shouldShowMonthlyPrices
)

export const selectShouldShowHeaderStickyInEstimationFormPage = createSelector(
  selectConfigData,
  data => data?.shouldShowHeaderStickyInEstimationFormPage
)

export const selectShouldShowAnnualElecConsumptionInHeroCard = createSelector(
  selectConfigData,
  data => data?.shouldShowAnnualElecConsumptionInHeroCard
)

export const selectShouldShowLegalTextAboveHighlightedOffers = createSelector(
  selectConfigData,
  data => data?.shouldShowLegalTextAboveHighlightedOffers
)

export const selectConfigShouldShowSelectraScoreRating = createSelector(
  selectConfigData,
  data => data?.shouldShowSelectraScoreRating
)

export const selectConfigShouldShowContractConditionInProductPage = createSelector(
  selectConfigData,
  data => data?.shouldShowContractConditionInProductPage
)

export const selectConfigShouldShouldShowAdvancedFilters = createSelector(
  selectConfigData,
  data => data?.shouldShowAdvancedFilters
)
