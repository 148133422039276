import Icon from '@common/components/Icon'
import classes from './Spinner.module.scss'

const Spinner = ({ fullscreen }) => {
  const containerClasses = [classes.Spinner]
  fullscreen && containerClasses.push(classes['Spinner--fullscreen'])

  const iconClasses = [classes['Spinner-icon']]
  fullscreen && iconClasses.push(classes['Spinner-icon--fullscreen'])

  return (
    <div className={containerClasses.join(' ')}>
      <Icon iconId='icon-loader' extraClasses={iconClasses} />
    </div>
  )
}

export { Spinner as default }
