import { useTranslation } from 'react-i18next'
import {
  CHECKOUT_PAGE,
  DUAL,
  ELEC,
  FORM_PAGE,
  GAS,
  PRODUCT_LIST_PAGE,
  PRODUCT_PAGE,
  PROFESSIONALS_PAGE,
} from '@root/constants'

const useTranslatedRoutes = () => {
  const { t } = useTranslation(['routes'])

  const translatedResultsUrl = offerEnergyType => {
    return {
      elec: t('routes:electricity'),
      gas: t('routes:gas'),
      dual: t('routes:dual'),
    }[offerEnergyType || 'elec']
  }

  const translatedComparisonsUrl = offerEnergyType => {
    return {
      elec: t('routes:compare_electricity'),
      gas: t('routes:compare_electricity'),
      dual: t('routes:compare_dual'),
    }[offerEnergyType || 'elec']
  }

  const getEnergyTypeFromUrl = url => {
    const energyTypesFromUrls = {
      [t('routes:compare_electricity')]: ELEC,
      [t('routes:compare_electricity')]: GAS,
      [t('routes:compare_dual')]: DUAL,
      [t('routes:electricity')]: ELEC,
      [t('routes:gas')]: GAS,
      [t('routes:dual')]: DUAL,
    }

    return energyTypesFromUrls[url] || ELEC
  }

  const getPageTypeFromPath = url => {
    const energyTypesFromUrls = {
      [t('routes:compare_electricity')]: FORM_PAGE,
      [t('routes:compare_electricity')]: FORM_PAGE,
      [t('routes:compare_dual')]: FORM_PAGE,
      [t('routes:electricity')]: PRODUCT_LIST_PAGE,
      [t('routes:gas')]: PRODUCT_LIST_PAGE,
      [t('routes:dual')]: PRODUCT_LIST_PAGE,
      [t('routes:product_page')]: PRODUCT_PAGE,
      [t('routes:professionals')]: PROFESSIONALS_PAGE,
      [t('routes:checkout')]: CHECKOUT_PAGE,
    }

    return energyTypesFromUrls[url] || ''
  }

  const translatedProductPageUrl = t('routes:product_page')

  return {
    translatedResultsUrl,
    translatedComparisonsUrl,
    translatedProductPageUrl,
    getEnergyTypeFromUrl,
    getPageTypeFromPath,
  }
}

export default useTranslatedRoutes
