import classes from '../Input/Input.module.scss'

const withAddons = InputComponent => {
  const InputWithAddons = props => {
    const { preAddon, postAddon, ...passThroughProps } = props

    const inputClasses = [classes.Input]
    const isInputDisabled = props.disabled || props.readOnly

    isInputDisabled && inputClasses.push(classes['Input--disabled'])
    preAddon && inputClasses.push(classes['Input--with-pre-addon'])
    postAddon && inputClasses.push(classes['Input--with-post-addon'])

    return (
      <div className={inputClasses.join(' ')}>
        {preAddon && (
          <div
            className={[
              classes.Input__addon,
              classes['Input__addon--pre'],
              'Field__control',
            ].join(' ')}
          >
            {preAddon}
          </div>
        )}
        <InputComponent {...passThroughProps} />
        {postAddon && (
          <div
            className={[
              classes.Input__addon,
              classes['Input__addon--post'],
              'Field__control',
            ].join(' ')}
          >
            {postAddon}
          </div>
        )}
      </div>
    )
  }
  return InputWithAddons
}

export { withAddons as default }
