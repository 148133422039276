import Button from '@components/Button/Button'
import Icon from '@components/Icon'

import classes from './CTAProviderSubscriptionButton.module.scss'
import { useTranslation } from 'react-i18next'
import useCheckout from '@features/Checkout/useCheckout'
import { useSiteCountry } from '@hooks/useSiteCountry'
import { useSelector } from 'react-redux'
import {
  selectExternalId,
  selectUtmCampaignName
} from '@root/reducers/contract/contractSelectors'

const addAdditionalParamsToUrl = (url, additionalParams) => {
  const urlHasParams = url.includes('?')
  const paramsDelimiter = urlHasParams ? '&' : '?'

  if (additionalParams?.utmCampaignName && additionalParams?.externalId) {
    return `${url}${paramsDelimiter}utm_campaign=${additionalParams.utmCampaignName}&${additionalParams.externalId}`
  }

  if (additionalParams?.utmCampaignName) {
    return `${url}${paramsDelimiter}utm_campaign=${additionalParams.utmCampaignName}`
  }

  if (additionalParams?.externalId) {
    return `${url}${paramsDelimiter}${additionalParams.externalId}`
  }

  return url
}

const CTAProviderSubscriptionButton = ({
  withIcon = false,
  color = 'primary',
  url,
  hidden
}) => {
  const { t } = useTranslation(['cta'])
  const { providerName } = useCheckout()
  const country = useSiteCountry()
  const buttonId = `${providerName?.toLowerCase()}-subscribe-${country}`
  const utmCampaignName = useSelector(selectUtmCampaignName)
  const externalId = useSelector(selectExternalId)

  if (hidden || !url) {
    return null
  }

  const urlWithAdditionalParams = addAdditionalParamsToUrl(url, { utmCampaignName, externalId })

  return (
    <Button
      id={buttonId}
      pill
      block
      size='md'
      color={color}
      href={urlWithAdditionalParams}
      target='_blank'
    >
      {t('cta:visit supplier website')}
      {withIcon
        ? (
          <Icon
            iconId='icon-arrow-right'
            fill='primary'
            size='md'
            position='right'
            extraClasses={[classes.CTAProviderSubscriptionButton__icon]}
          />
          )
        : null}
    </Button>
  )
}

export { CTAProviderSubscriptionButton as default }
