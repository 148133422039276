import localConfig from './env/local'
import productionConfig from './env/production'
import stagingConfig from './env/staging'
import testConfig from './env/test'

const globalConfig = {
  defaultCountry: 'fr',
  apiUrls: {
    address: 'https://api-adresse.data.gouv.fr',
    dialga: 'https://dialga.selectra.io'
  },
  urls: {
    mySelectra:
      'https://my.selectra.com/home?utm_source=comparateur&utm_medium=login-button&utm_campaign=compaxloyalty'
  }
}
const globalConfigByEnv = {
  staging: stagingConfig,
  production: productionConfig,
  local: localConfig,
  test: testConfig
}

export { globalConfig, globalConfigByEnv }
