import defaultValues from './defaultValues'
import footerLinks from './footerLinks'
import siteRoutes from './siteRoutes'
import { getUtcBySummerSchedule } from '@root/utilities/site'

const config = {
  urls: {
    app: {
      staging: 'pt.energy-comparator-spa.s3.staging.aws.selectra.io',
      production: 'energia.selectra.pt',
    },
    api: {
      staging: 'pt.energy-comparator.app.staging.aws.selectra.io',
      production: 'comparador.selectra.pt',
    },
  },
  locale: 'pt-PT',
  utc: getUtcBySummerSchedule({
    summerUtc: 1,
    notSummerUtc: 0,
  }),
  openingHours: {
    monday: ['08:00', '21:00'],
    tuesday: ['08:00', '21:00'],
    wednesday: ['08:00', '21:00'],
    thursday: ['08:00', '21:00'],
    friday: ['08:00', '21:00'],
    saturday: ['09:00', '19:00'],
    sunday: ['09:00', '19:00'],
  },
  leadSources: {
    mainForm: 'pt-ei-cc-0cmps1cn2formulario',
    callbackForm: 'pt-ei-cc-0cmps1cn2',
  },
  footerLinks,
  defaultValues,
  siteRoutes,
}

export default config
