import Icon from '@components/Icon'
import classes from './PCEInfoModal.module.scss'
import { useTranslation } from 'react-i18next'

const PCEInfoModal = () => {
  const { t } = useTranslation(['checkout'])
  return (
    <>
      <h1 className={classes.PCEInfoModal__title}>
        <Icon iconId='icon-meter' fill='primary' size='lg' position='left' />
        {t('checkout:metering and estimation point')}
      </h1>
      <p
        className={classes.PCEInfoModal__text}
        dangerouslySetInnerHTML={{
          __html: t('checkout:PCE info modal'),
        }}
      />
      <div className={classes['PCEInfoModal__image-container']}>
        <img
          src='/img/new/comprendre-facture-gaz-pce.png'
          alt='linky-example'
        />
      </div>
    </>
  )
}

export { PCEInfoModal as default }
