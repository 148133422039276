import { CATEGORY_FILTER, FEATURE, PROVIDER } from '@root/constants'

export const getCommonOffersId = ({ checkedOffers }) =>
  checkedOffers?.reduce((prev, current) => {
    if (!prev.length) return current
    return current.filter(id => prev.includes(id))
  }, [])

export const getFilters = ({ filters, filterType }) =>
  filters.filter(({ type }) => type === filterType)

export const getCheckedFilters = ({ filters, filterType }) =>
  getFilters({ filters, filterType }).filter(({ checked }) => !!checked)

export const getCheckedOffers = ({ filters, filterType }) =>
  getCheckedFilters({ filters, filterType }).map(filter => filter.offers)

export const enableFilter = ({ filter }) => ({
  ...filter,
  enabled: true
})

const enableProviderFilters = ({ filters }) => {
  return filters.map(filter => {
    if (filter.type !== PROVIDER) return filter

    return {
      ...filter,
      enabled: true
    }
  })
}

export const handleFeatureFilters = ({ filters }) => {
  const featureFilters = getFilters({ filters, filterType: FEATURE })
  const providerFilters = getFilters({ filters, filterType: PROVIDER })
  const categoryFilters = getFilters({ filters, filterType: CATEGORY_FILTER })
  const checkedFeatureOffers = getCheckedOffers({
    filters,
    filterType: FEATURE
  })

  const commonOffersId = getCommonOffersId({
    checkedOffers: checkedFeatureOffers
  })

  const activeFeatureFiltersIds = featureFilters
    ?.filter(({ offers }) =>
      offers.some(offerId => commonOffersId?.includes(offerId))
    )
    .map(({ id }) => id)

  const activeProviderFiltersIds = providerFilters
    ?.filter(({ offers }) =>
      offers.some(offerId => commonOffersId?.includes(offerId))
    )
    .map(({ id }) => id)

  const activeCategoryFiltersIds = categoryFilters
    ?.filter(({ offers }) =>
      offers.some(offerId => commonOffersId?.includes(offerId))
    )
    .map(({ id }) => id)

  if (!activeFeatureFiltersIds.length && !activeCategoryFiltersIds.length) {
    return enableProviderFilters({ filters })
  }

  const newFilters = filters.map(filter => {
    if (filter.type === FEATURE && activeFeatureFiltersIds.includes(filter.id)) return enableFilter({ filter })
    if (filter.type === PROVIDER && activeProviderFiltersIds.includes(filter.id)) return enableFilter({ filter })
    if (filter.type === CATEGORY_FILTER && activeCategoryFiltersIds.includes(filter.id)) return enableFilter({ filter })

    return {
      ...filter,
      enabled: false,
      checked: false
    }
  })
  return newFilters
}

export const handleCategoryFilters = ({ filters }) => {
  const featureFilters = getFilters({ filters, filterType: FEATURE })
  const providerFilters = getFilters({ filters, filterType: PROVIDER })
  const categoryFilters = getFilters({ filters, filterType: CATEGORY_FILTER })
  const checkedCategoryOffers = getCheckedOffers({
    filters,
    filterType: CATEGORY_FILTER
  })

  const commonOffersId = getCommonOffersId({
    checkedOffers: checkedCategoryOffers
  })

  const activeCategoryFiltersIds = categoryFilters
    ?.filter(({ offers }) =>
      offers.some(offerId => commonOffersId?.includes(offerId))
    )
    .map(({ id }) => id)

  const activeFeatureFiltersIds = featureFilters
    ?.filter(({ offers }) =>
      offers.some(offerId => commonOffersId?.includes(offerId))
    )
    .map(({ id }) => id)

  const activeProviderFiltersIds = providerFilters
    ?.filter(({ offers }) =>
      offers.some(offerId => commonOffersId?.includes(offerId))
    )
    .map(({ id }) => id)

  if (!activeCategoryFiltersIds.length && !activeFeatureFiltersIds.length) {
    return enableProviderFilters({ filters })
  }

  const newFilters = filters.map(filter => {
    if (filter.type === FEATURE && activeFeatureFiltersIds.includes(filter.id)) return enableFilter({ filter })
    if (filter.type === PROVIDER && activeProviderFiltersIds.includes(filter.id)) return enableFilter({ filter })
    if (filter.type === CATEGORY_FILTER && activeCategoryFiltersIds.includes(filter.id)) return enableFilter({ filter })

    return {
      ...filter,
      enabled: false,
      checked: false
    }
  })
  return newFilters
}

export const handleProviderFilters = ({ filters }) => {
  const offersId = getCheckedOffers({ filters, filterType: PROVIDER }).flat()

  if (!offersId.length) return handleFeatureFilters({ filters })

  const featureFilters = getFilters({
    filters,
    filterType: FEATURE
  })

  const providerFilters = getFilters({
    filters,
    filterType: PROVIDER
  })

  const categoryFilters = getFilters({
    filters,
    filterType: CATEGORY_FILTER
  })

  const activeFeatureFiltersIds = featureFilters.map(filter => {
    const isFilterEnabled = offersId.length
      ? filter.offers.some(id => offersId.includes(id))
      : true

    return {
      ...filter,
      enabled: isFilterEnabled
    }
  })

  const activeCategoryFiltersIds = categoryFilters.map(filter => {
    const isFilterEnabled = offersId.length
      ? filter.offers.some(id => offersId.includes(id))
      : true

    return {
      ...filter,
      enabled: isFilterEnabled
    }
  })

  return [...activeFeatureFiltersIds, ...activeCategoryFiltersIds, ...providerFilters]
}

export const handleTemporaryFilters = ({ filters }) => {
  const checkedProviderOffers = getCheckedOffers({
    filters,
    filterType: PROVIDER
  }).flat()

  const checkedFeatureOffers = getCheckedOffers({
    filters,
    filterType: FEATURE
  })

  const checkedCategoryOffers = getCheckedOffers({
    filters,
    filterType: CATEGORY_FILTER
  })

  const featureOffersIds = getCommonOffersId({
    checkedOffers: checkedFeatureOffers
  })

  const categoryOffersIds = getCommonOffersId({
    checkedOffers: checkedCategoryOffers
  })

  if (!categoryOffersIds.length) {
    return checkedProviderOffers.filter(offer =>
      featureOffersIds.includes(offer)
    )
  }

  if (!featureOffersIds.length) {
    return checkedProviderOffers.filter(offer =>
      categoryOffersIds.includes(offer)
    )
  }

  if (!checkedProviderOffers.length) {
    return categoryOffersIds.filter(offer =>
      featureOffersIds.includes(offer)
    )
  }

  const offerIds = checkedProviderOffers.filter(offer =>
    featureOffersIds.includes(offer) || categoryOffersIds.includes(offer)
  )

  return offerIds
}
