import { createSelector } from '@reduxjs/toolkit'

export const selectModal = state => state.modal

export const selectModalComponent = createSelector(
  selectModal,
  modal => modal.component
)

export const selectModalIsOpen = createSelector(
  selectModal,
  modal => modal.isOpen
)

export const selectModalData = createSelector(selectModal, modal => modal.data)
