import Icon from '../components/Icon'
import Button from '../components/Button/Button'
import classes from '@features/EstimationForm/FormPage.module.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectModalData } from '@root/reducers/modal/modalSelectors'

export default function EldModalErrorNotConnected () {
  const { elecFormRedirectHandler } = useSelector(selectModalData)
  const { t } = useTranslation(['validation', 'eld', 'common'])

  return (
    <>
      <h1 className={classes['FormPage__title-modal']}>
        <Icon
          iconId='icon-warning-triangle'
          fill='secondary'
          size='xl'
          extraClasses={[classes['FormPage__icon-eld-modal']]}
        />
        {t('validation:municipality not connected')}
      </h1>
      <p className={classes['FormPage__text-modal']}>
        {t('eld:municipality not connected description')}{' '}
        <a href={`mailto:${t('common:emails.main')}`}>
          {t('common:emails.main')}
        </a>
      </p>
      <Button
        clickHandler={() => {
          elecFormRedirectHandler()
        }}
        extraClasses={[classes['FormPage__eld-form-button']]}
      >
        {t('eld:compare for electricity')}
        <Icon iconId='icon-chevron-right' fill='white' position='right' />
      </Button>
    </>
  )
}
