import { useEffect, useRef, useCallback } from 'react'

export default function useOutsideClick(outsideClickHandler) {
  const ref = useRef(null)
  const handleClickOutside = useCallback(
    event => {
      if (
        typeof outsideClickHandler === 'function' &&
        ref.current &&
        !ref.current.contains(event.target)
      ) {
        outsideClickHandler(event)
      }
    },
    [outsideClickHandler]
  )
  useEffect(() => {
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  })
  return { ref }
}
