import { createSlice } from '@reduxjs/toolkit'
import featureFlagsFr from '@root/engines/fr/config/feature-flags.json'
import featureFlagsIt from '@root/engines/it/config/feature-flags.json'
import featureFlagsBeFr from '@root/engines/be-fr/config/feature-flags.json'
import featureFlagsBeNl from '@root/engines/be-nl/config/feature-flags.json'
import featureFlagsJp from '@root/engines/jp/config/feature-flags.json'
import featureFlagsEs from '@root/engines/es/config/feature-flags.json'
import featureFlagsPt from '@root/engines/pt/config/feature-flags.json'
import typeformConfigFr from '@root/engines/fr/config/typeform.json'
import typeformConfigIt from '@root/engines/it/config/typeform.json'
import typeformConfigBeFr from '@root/engines/be-fr/config/typeform.json'
import typeformConfigBeNl from '@root/engines/be-nl/config/typeform.json'
import typeformConfigJp from '@root/engines/jp/config/typeform.json'
import typeformConfigEs from '@root/engines/es/config/typeform.json'

const initialState = {
  data: {},
  error: null
}

const featuresFlags = {
  fr: featureFlagsFr,
  it: featureFlagsIt,
  beFr: featureFlagsBeFr,
  beNl: featureFlagsBeNl,
  jp: featureFlagsJp,
  es: featureFlagsEs,
  pt: featureFlagsPt
}

const configTypeform = {
  fr: typeformConfigFr,
  it: typeformConfigIt,
  beFr: typeformConfigBeFr,
  beNl: typeformConfigBeNl,
  jp: typeformConfigJp,
  es: typeformConfigEs
}

const updateConfigHandler = (state, { payload: { country, siteId } }) => {
  state.data = {
    ...featuresFlags[country],
    configTypeform: configTypeform[country],
    country,
    siteId
  }
}

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    updateConfig: updateConfigHandler,
    clearConfig: () => initialState
  }
})

export const { updateConfig, clearConfig } = configSlice.actions

export default configSlice.reducer
