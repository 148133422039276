import { createSlice } from '@reduxjs/toolkit'
import { keysToCamel } from '@/utilities/snake-case-to-camel-case'

const initialState = {
  formData: {
    currentEnergyType: 'elec',
    elec: {},
    gas: {},
    dual: {}
  },
  personalInformation: {},
  shouldSeeResultsUserComebackModal: false,
  shouldSeeResultsTypeform: false,
  shouldSeeHelpButtonPopUp: true,
  shouldSeeProductList: true,
  sawTypeform: false,
  experimentId: null,
  phoneToCall: null,
  phoneVisualToCall: null,
  error: null
}

const updateUserPersonalInformationHandler = (state, action) => {
  const {
    email,
    phone,
    city,
    cityAutocomplete,
    address,
    addressAutocomplete,
    firstName,
    lastName,
    street,
    streetAutocomplete,
    optionInsurance,
    optionCallback,
    optionNewsLetter,
    proSocialReason,
    prestationType,
    urlOrigin
  } = action.payload

  const userPersonalInformation = {
    email,
    phone,
    city,
    cityAutocomplete,
    address,
    addressAutocomplete,
    firstName,
    lastName,
    street,
    streetAutocomplete,
    optionInsurance,
    optionCallback,
    optionNewsLetter,
    proSocialReason,
    prestationType,
    urlOrigin
  }
  state.personalInformation = {
    ...(state.personalInformation || {}),
    ...keysToCamel(userPersonalInformation)
  }
}

const updateUserShouldSeeResultsUserComebackModalHandler = (state, action) => {
  state.shouldSeeResultsUserComebackModal = action.payload
}

const updateUserShouldSeeResultsTypeformHandler = (state, action) => {
  state.shouldSeeResultsTypeform = action.payload
}

const updateUserShouldSeeHelpButtonPopUpHandler = (state, action) => {
  state.shouldSeeHelpButtonPopUp = action.payload
}

const updateUserSawTypeformHandler = (state, action) => {
  state.sawTypeform = action.payload
}

const updateUserExperimentIdHandler = (state, action) => {
  state.experimentId = action.payload
}

const updateUserShouldSeeProductListHandler = (state, action) => {
  state.shouldSeeProductList = action.payload
}

const updateUserPhonesToCallHandler = (state, action) => {
  state.phoneToCall = action.payload.phoneToCall
  state.phoneVisualToCall = action.payload.phoneVisualToCall
}

const clearUserPhonesToCallHandler = state => {
  state.phoneToCall = null
  state.phoneVisualToCall = null
}

const updateUserCurrentEnergyTypeHandler = (state, action) => {
  // prevent old user results blank page
  if (!state.formData) {
    return
  }

  state.formData.currentEnergyType = action.payload
}

const updateUserElecFormDataHandler = (state, action) => {
  const {
    knowConsumption,
    knowPdl,
    houseType,
    people,
    heatingEnergy,
    waterEnergy,
    cookingEnergy,
    tariffOption,
    subscribedPowerId,
    consoHc,
    consoHf,
    consoHp,
    consoHn,
    surface,
    electroLlinge,
    electroSlinge,
    electroLvaisselle,
    electroAirConditionne,
    electroFloorHeating,
    electroAirConditioner,
    electroFanHeater,
    electroDishwasher,
    electroDryer,
    electroInductionHeating,
    electroKotatsu,
    electroComputer,
    electroOven,
    electroMicrowave,
    annualConsoElec,
    annualConsoElecNight,
    pdl,
    pdlsFound,
    optionSubscribedInfo,
    optionHistoryInfo,
    optionPowerInfo,
    optionOwnershipPdl,
    subscribedPower,
    prestationType,
    resident,
    energyType,
    propaneSelected,
    hasExclusiveNightTariff,
    period,
    elecCompany,
    ratePlan,
    monthConsoElec,
    elecMonth,
    consumptionRanges,
    rangeMorning,
    rangeAfternoon,
    rangeNight,
    withPromos,
    formModule,
    elecInvoiceDays
  } = action.payload

  const formData = {
    knowConsumption,
    knowPdl,
    houseType,
    people,
    heatingEnergy,
    waterEnergy,
    cookingEnergy,
    tariffOption,
    subscribedPowerId,
    consoHc,
    consoHf,
    consoHp,
    consoHn,
    surface,
    electroLlinge,
    electroSlinge,
    electroLvaisselle,
    electroAirConditionne,
    electroFloorHeating,
    electroAirConditioner,
    electroFanHeater,
    electroDishwasher,
    electroDryer,
    electroInductionHeating,
    electroKotatsu,
    electroComputer,
    electroOven,
    electroMicrowave,
    annualConsoElec,
    annualConsoElecNight,
    pdl,
    pdlsFound,
    optionSubscribedInfo,
    optionHistoryInfo,
    optionPowerInfo,
    optionOwnershipPdl,
    subscribedPower,
    prestationType,
    resident,
    energyType,
    propaneSelected,
    hasExclusiveNightTariff,
    period,
    elecCompany,
    ratePlan,
    monthConsoElec,
    elecMonth,
    consumptionRanges,
    rangeMorning,
    rangeAfternoon,
    rangeNight,
    withPromos,
    formModule,
    elecInvoiceDays
  }

  const elecFormData = {
    ...(state.formData?.elec || {}),
    ...keysToCamel(formData)
  }

  state.formData = {
    ...(state.formData || {}),
    elec: elecFormData
  }
}

const updateUserGasFormDataHandler = (state, action) => {
  const {
    knowConsumption,
    houseType,
    people,
    heatingEnergy,
    waterEnergy,
    cookingEnergy,
    surface,
    annualConsoGas,
    prestationType,
    energyType,
    period,
    withPromos,
    formModule,
    gasInvoiceDays
  } = action.payload

  const formData = {
    knowConsumption,
    houseType,
    people,
    heatingEnergy,
    waterEnergy,
    cookingEnergy,
    surface,
    annualConsoGas,
    prestationType,
    energyType,
    period,
    withPromos,
    formModule,
    gasInvoiceDays
  }

  const gasFormData = {
    ...(state.formData?.gas || {}),
    ...keysToCamel(formData)
  }

  state.formData = {
    ...(state.formData || {}),
    gas: gasFormData
  }
}

const updateUserDualFormDataHandler = (state, action) => {
  const {
    knowConsumption,
    houseType,
    people,
    heatingEnergy,
    waterEnergy,
    cookingEnergy,
    tariffOption,
    subscribedPowerId,
    consoHc,
    consoHf,
    consoHp,
    surface,
    electroLlinge,
    electroSlinge,
    electroLvaisselle,
    electroAirConditionne,
    electroFloorHeating,
    electroAirConditioner,
    electroFanHeater,
    electroDishwasher,
    electroDryer,
    electroInductionHeating,
    electroKotatsu,
    electroComputer,
    electroOven,
    electroMicrowave,
    annualConsoElec,
    annualConsoElecNight,
    subscribedPower,
    prestationType,
    annualConsoGas,
    resident,
    energyType,
    propaneSelected,
    hasExclusiveNightTariff,
    period,
    elecCompany,
    ratePlan,
    monthConsoElec,
    elecMonth,
    consumptionRanges,
    rangeMorning,
    rangeAfternoon,
    rangeNight,
    withPromos,
    formModule,
    elecInvoiceDays,
    gasInvoiceDays
  } = action.payload

  const formData = {
    knowConsumption,
    houseType,
    people,
    heatingEnergy,
    waterEnergy,
    cookingEnergy,
    tariffOption,
    subscribedPowerId,
    consoHc,
    consoHf,
    consoHp,
    surface,
    electroLlinge,
    electroSlinge,
    electroLvaisselle,
    electroAirConditionne,
    electroFloorHeating,
    electroAirConditioner,
    electroFanHeater,
    electroDishwasher,
    electroDryer,
    electroInductionHeating,
    electroKotatsu,
    electroComputer,
    electroOven,
    electroMicrowave,
    annualConsoElec,
    annualConsoElecNight,
    annualConsoGas,
    subscribedPower,
    prestationType,
    resident,
    energyType,
    propaneSelected,
    hasExclusiveNightTariff,
    period,
    elecCompany,
    ratePlan,
    monthConsoElec,
    elecMonth,
    consumptionRanges,
    rangeMorning,
    rangeAfternoon,
    rangeNight,
    withPromos,
    formModule,
    elecInvoiceDays,
    gasInvoiceDays
  }

  const dualFormData = {
    ...(state.formData?.dual || {}),
    ...keysToCamel(formData)
  }

  state.formData = {
    ...(state.formData || {}),
    dual: dualFormData
  }
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserPersonalInformation: updateUserPersonalInformationHandler,
    updateUserShouldSeeResultsUserComebackModal:
      updateUserShouldSeeResultsUserComebackModalHandler,
    updateUserShouldSeeResultsTypeform:
      updateUserShouldSeeResultsTypeformHandler,
    updateUserShouldSeeHelpButtonPopUp:
      updateUserShouldSeeHelpButtonPopUpHandler,
    updateUserSawTypeform: updateUserSawTypeformHandler,
    updateUserExperimentId: updateUserExperimentIdHandler,
    updateUserShouldSeeProductList: updateUserShouldSeeProductListHandler,
    updateUserPhonesToCall: updateUserPhonesToCallHandler,
    updateUserCurrentEnergyType: updateUserCurrentEnergyTypeHandler,
    updateUserElecFormData: updateUserElecFormDataHandler,
    updateUserGasFormData: updateUserGasFormDataHandler,
    updateUserDualFormData: updateUserDualFormDataHandler,
    clearUser: () => initialState,
    clearUserPhonesToCall: clearUserPhonesToCallHandler
  }
})

export const {
  updateUserPersonalInformation,
  clearUser,
  updateUserShouldSeeResultsUserComebackModal,
  updateUserShouldSeeResultsTypeform,
  updateUserShouldSeeHelpButtonPopUp,
  updateUserSawTypeform,
  updateUserExperimentId,
  updateUserShouldSeeProductList,
  updateUserCurrentEnergyType,
  updateUserElecFormData,
  updateUserGasFormData,
  updateUserDualFormData,
  updateUserPhonesToCall,
  clearUserPhonesToCall
} = userSlice.actions

export default userSlice.reducer
