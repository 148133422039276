const footerLinks = [
  {
    href: 'https://selectra.pt/grupo',
    translationKey: 'in regards to',
  },
  {
    href: 'https://selectra.pt/aviso-legal',
    translationKey: 'legal notice',
  },
  {
    href: 'https://selectra.pt/metodologia-comparador',
    translationKey: 'methodology and comparatives',
  },
]

export default footerLinks
