import { call, put, takeLatest } from 'redux-saga/effects'
import { updateContract } from '@root/reducers/contract/contractSlice'
import { updateOffers } from '@root/reducers/offers/offersSlice'
import {
  updateUserCurrentEnergyType,
  updateUserDualFormData,
  updateUserElecFormData,
  updateUserGasFormData,
  updateUserPersonalInformation,
  updateUserShouldSeeProductList
} from '@root/reducers/user/userSlice'
import { compareData, makeContractInformation } from '@root/utilities/estimation-form-helper'
import { updateModalComponent, updateModalData, updateModalProps } from '@root/reducers/modal/modalSlice'

export const estimationFormActions = {
  SUBMIT_FORM: 'SUBMIT_FORM'
}

export const formDataHandlersByEnergy = {
  elec: updateUserElecFormData,
  gas: updateUserGasFormData,
  dual: updateUserDualFormData
}

export function * getOffersDataSaga ({
  payload: {
    formData,
    countryId,
    onCompleteEld,
    elecFormRedirectHandler,
    gasFormRedirectHandler
  }
}) {
  try {
    const energyType = formData.energyType

    const offersData = yield call(
      compareData,
      countryId,
      energyType,
      formData
    )

    if (!offersData.error) {
      offersData.energyType = energyType

      return offersData
    }

    const { gasProviderName } = offersData.data

    const showEldNotConnectedModal = energyType !== 'elec' && !gasProviderName

    if (showEldNotConnectedModal) {
      return yield call(showEldModal, {
        payload: {
          eldData: offersData.data,
          onCompleteEld,
          elecFormRedirectHandler,
          gasFormRedirectHandler,
          modalName: 'notConnectedEldError'
        }
      })
    }

    yield call(showEldModal, {
      payload: {
        eldData: offersData.data,
        onCompleteEld,
        elecFormRedirectHandler,
        gasFormRedirectHandler,
        modalName: `${energyType}EldError`
      }
    })
  } catch (e) {
    console.error(e)
  }
}

export function * showEldModal ({
  payload: {
    eldData,
    modalName,
    onCompleteEld,
    elecFormRedirectHandler,
    gasFormRedirectHandler
  }
}) {
  yield put(updateModalData({ eldData, elecFormRedirectHandler, gasFormRedirectHandler }))
  yield put(updateModalProps({
    size: 'md'
  }))
  yield put(updateModalComponent(modalName))
  yield call(onCompleteEld)
}

export function * getContractDataSaga ({
  payload: { formData, countryId, offersData, countryCode }
}) {
  return makeContractInformation(
    countryId,
    formData.energyType,
    formData,
    offersData,
    'part',
    countryCode
  )
}

export function * submitFormSaga ({
  payload: {
    formData,
    countryId,
    countryCode,
    onComplete,
    onCompleteEld,
    elecFormRedirectHandler,
    gasFormRedirectHandler
  }
}) {
  yield put(updateUserShouldSeeProductList(false))
  try {
    const offersData = yield call(getOffersDataSaga, {
      payload: { formData, countryId, onCompleteEld, elecFormRedirectHandler, gasFormRedirectHandler }
    })

    if (!offersData) {
      return
    }

    const contractData = yield call(getContractDataSaga, {
      payload: { formData, countryId, offersData, countryCode }
    })

    yield put(updateOffers(offersData))
    yield put(updateContract(contractData))
    yield put(formDataHandlersByEnergy[formData.energyType](formData))
    yield put(updateUserPersonalInformation(formData))
    yield put(updateUserCurrentEnergyType(formData.energyType))
    yield put(updateUserShouldSeeProductList(true))
    yield call(onComplete, { contractData })
  } catch (e) {
    console.error(e)
  }
}

export default function * estimationFormSagas () {
  yield takeLatest(estimationFormActions.SUBMIT_FORM, submitFormSaga)
}
