import {
  cloneElement,
  forwardRef,
  isValidElement,
  useEffect,
  useState,
} from 'react'
import Card from '../Card'
import useOpenState from '@hooks/useOpenState'
import classes from './PopUp.module.scss'

const TriggerWithRef = (
  {
    triggerLabel,
    isOpen,
    clickHandler,
    disabled,
    extraClasses,
    tabIndex,
    keyPressHandler,
  },
  ref
) => {
  const triggerClasses = [classes.PopUp__trigger]
  isOpen && triggerClasses.push(classes['PopUp__trigger--open'])
  disabled && triggerClasses.push(classes['PopUp__trigger--disabled'])
  Array.isArray(extraClasses) && triggerClasses.push(...extraClasses)

  return (
    <div
      ref={ref}
      className={triggerClasses.join(' ')}
      onClick={disabled ? null : clickHandler}
      tabIndex={tabIndex || null}
      onKeyDown={keyPressHandler}
    >
      {triggerLabel}
    </div>
  )
}

const Trigger = forwardRef(TriggerWithRef)

const PopUp = ({
  TriggerComponent,
  triggerLabel,
  triggerElement,
  onOpen,
  onClose,
  position,
  size,
  disabled,
  triggerExtraClasses,
  cardExtraClasses,
  triggerWrapperExtraClasses,
  children,
  positioned,
  dataCy,
  popUpExtraClasses,
  backdropExtraClasses,
  openTimeout,
  closeTimeout,
  gapSize,
  leftGap,
  relativePlacement,
  id = '',
  tabIndex,
}) => {
  const popUpClasses = []

  if (positioned) {
    popUpClasses.push(classes.PopUp__positioned)
  }

  Array.isArray(triggerWrapperExtraClasses) &&
    popUpClasses.push(...triggerWrapperExtraClasses)

  const cardClasses = [classes.PopUp__card]

  const cardSizeClasses = {
    sm: classes['PopUp__card--sm'],
    md: classes['PopUp__card--md'],
    xl: classes['PopUp__card--xl'],
    full: classes['PopUp__card--full-width'],
  }

  const { triggerRef, popupRef, isOpen, setIsOpen } = useOpenState(
    false,
    disabled
  )

  const PopUpTrigger = TriggerComponent || Trigger

  const triggerClasses = triggerExtraClasses
    ? [...triggerExtraClasses, 'Field__control']
    : ['Field__control']

  disabled && popUpClasses.push(classes['PopUp--disabled'])
  size && cardClasses.push(cardSizeClasses[size])
  popUpExtraClasses && popUpClasses.push(...popUpExtraClasses)

  Array.isArray(cardExtraClasses) && cardClasses.push(...cardExtraClasses)
  gapSize ??= 8

  const [wasOpen, setWasOpen] = useState(false)

  useEffect(() => {
    const cardElement = popupRef.current

    if (isOpen && !disabled) {
      const cardOffsetHeight = `${-cardElement.offsetHeight - gapSize}px`
      const cardOffsetWidth = `${-cardElement.offsetWidth - gapSize}px`
      const positionIsTopOrBottom = ['top', 'bottom'].includes(position)

      cardElement.style[position] = positionIsTopOrBottom
        ? cardOffsetHeight
        : cardOffsetWidth

      if (positionIsTopOrBottom && leftGap) {
        cardElement.style.left = `${cardElement.offsetLeft - leftGap}px`
      }

      cardElement.style.opacity = 1

      if (relativePlacement) {
        cardElement.style[relativePlacement] = 0
      }
    }
  }, [isOpen, popupRef, position, disabled])

  useEffect(() => {
    if (isOpen) {
      setWasOpen(true)
      onOpen?.()
      return
    }
    if (wasOpen) {
      onClose?.()
    }
  }, [isOpen, onOpen, onClose, wasOpen])

  const renderChildren = () => {
    if (typeof children === 'function') {
      return children(setIsOpen)
    }
    if (isValidElement(children)) {
      return cloneElement(children, { setIsOpen })
    }

    return children
  }

  return (
    <>
      <div className={popUpClasses.join(' ')} data-cy={dataCy} id={id}>
        <PopUpTrigger
          ref={triggerRef}
          id={id}
          triggerLabel={triggerLabel}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          keyPressHandler={e => {
            // Space or Enter
            if (e.which === 13 || e.which === 32) {
              e.preventDefault()
              setIsOpen(!isOpen)
            }
          }}
          disabled={disabled}
          extraClasses={triggerClasses}
          backdropExtraClasses={backdropExtraClasses}
          triggerElement={triggerElement}
          openTimeout={openTimeout}
          closeTimeout={closeTimeout}
          tabIndex={tabIndex || null}
        />
        {isOpen && (
          <Card
            ref={popupRef}
            rounded='md'
            bordered
            height={2}
            extraClasses={cardClasses}
          >
            {renderChildren()}
          </Card>
        )}
      </div>
    </>
  )
}

export { PopUp as default, Trigger }
