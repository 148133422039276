const footerLinks = [
  {
    href: 'https://selectra.es/grupo',
    translationKey: 'in regards to',
  },
  {
    href: 'https://climate.selectra.com/es',
    translationKey: 'methodology and comparatives',
  },
  {
    href: 'https://selectra.es/aviso-legal',
    translationKey: 'legal notice',
  },
  {
    href: 'https://selectra.es/politica-privacidad',
    translationKey: 'privacy policy',
  },
]

export default footerLinks
