import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isOpen: false,
  component: null,
  isDrawer: false,
  size: 'md',
  shouldCloseOnOverlayClick: true,
  shouldCloseOnEsc: true,
  closeButton: true,
  data: null,
  childrenProps: null,
}

const updateModalComponentHandler = (state, action) => {
  if (!state.isOpen && action.payload) state.isOpen = true
  state.component = action.payload
}

const updateModalIsOpenHandler = (state, action) => {
  state.isOpen = action.payload
}

const updateModalDataHandler = (state, action) => {
  state.data = action.payload
}

const updateModalPropsHandler = (state, action) => {
  const {
    isDrawer,
    size,
    shouldCloseOnOverlayClick,
    shouldCloseOnEsc,
    closeButton,
    id,
    onAfterOpen,
    onAfterClose,
    childrenProps,
  } = action.payload

  state.isDrawer = isDrawer ?? false
  state.size = size ?? 'md'
  state.shouldCloseOnOverlayClick = shouldCloseOnOverlayClick ?? true
  state.shouldCloseOnEsc = shouldCloseOnEsc ?? true
  state.closeButton = closeButton ?? true
  state.id = id ?? ''
  state.onAfterOpen = onAfterOpen ?? null
  state.onAfterClose = onAfterClose ?? null
  state.childrenProps = childrenProps ?? null
}

export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    updateModalComponent: updateModalComponentHandler,
    updateModalIsOpen: updateModalIsOpenHandler,
    updateModalProps: updateModalPropsHandler,
    updateModalData: updateModalDataHandler,
    clearModal: () => initialState,
  },
})

export const {
  updateModalComponent,
  updateModalIsOpen,
  updateModalProps,
  updateModalData,
  clearModal,
} = modalSlice.actions

export default modalSlice.reducer
