import { getUtcBySummerSchedule } from '@root/utilities/site'
import footerLinks from './footerLinks'
import defaultValues from './defaultValues'
import siteRoutes from './siteRoutes'

const config = {
  urls: {
    app: {
      staging: 'es.energy-comparator-spa.s3.staging.aws.selectra.io',
      production: 'energia.selectra.es',
    },
    api: {
      staging: 'es.energy-comparator.app.staging.aws.selectra.io',
      production: 'comparador.selectra.es',
    },
  },
  locale: 'es-ES',
  utc: getUtcBySummerSchedule(),
  openingHours: {
    monday: ['08:00', '22:00'],
    tuesday: ['08:00', '22:00'],
    wednesday: ['08:00', '22:00'],
    thursday: ['08:00', '22:00'],
    friday: ['08:00', '22:00'],
    saturday: ['09:00', '21:00'],
    sunday: ['09:00', '20:00'],
  },
  priceFormat: '{price}{currencySymbol}',
  leadSources: {
    mainForm: 'es-eg-cc-gn0cmps1cn2newform',
    callbackForm: 'es-eg-cc-gn0cmps1cn2new',
  },
  footerLinks,
  defaultValues,
  siteRoutes,
}

export default config
