const phoneRegex = {
  fr: {
    any: /^ *(?:(?:\+|00)33|0)\s*[1-9](?:[\s]*\d{2}){4} *$/,
    land: /^ *(?:(?:\+|00)33|0)\s*[1-59](?:[\s]*\d{2}){4} *$/,
    mobile: /^ *(?:(?:\+|00)33|0)\s*[67](?:[\s]*\d{2}){4} *$/
  },
  it: {
    any: /^(?:\d{3})?(?:[\s]*\d{4,7})?$/
  },
  jp: {
    any: /^0\d{9,10}$/
  },
  es: {
    any: /.*/
  },
  pt: {
    any: /.*/
  }
}
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const formAddress = /^([0-9]*) (.*)$/u
const formCity =
  /^([0-9a-zA-Zàáâäãåąǽčćęèéêëėįìíîïłńœòóôöõøùúûü'ųūÿýżźñçšžÀÁÂÄÃÅĄǼĆČĖĘÈÉÊËÌÍÎÏĮŁŃŒÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇÆŠŽ∂ð\-\s()]*)\s\((\d{5})\)$/u
const formCityIt =
  /^([0-9a-zA-ZàáâäãåąǽčćęèéêëėįìíîïłńœòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄǼĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð\-\s()]*)\s\((\d{5})\s-\s(.+)\)$/u
const formCityBe =
  /^([0-9a-zA-ZàáâäãåąǽčćęèéêëėįìíîïłńœòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄǼĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð\-\s()]*)\s\(([0-9]{4}) - .+\)$/u

export { phoneRegex, emailRegex, formAddress, formCity, formCityIt, formCityBe }
