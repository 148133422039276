import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Icon, Button } from '@components/index'
import classes from './PdlModalError.module.scss'
import useCheckout from '@features/Checkout/useCheckout'
import { removeEmailToken, sendWebLead } from '@root/utilities/hop'
import { updateContract } from '@root/reducers/contract/contractSlice'
import { updatePrices } from '@root/reducers/currentOffer/currentOfferSlice'
import { selectUserData } from '@root/reducers/user/userSelectors'
import { apiContacts } from '@root/utilities/comparator'
import { formatPriceWithCurrency } from '@root/utilities/string-utils'
import { setRequestHasErrors } from '@root/reducers/checkout/checkoutSlice'
import {
  updateUserDualFormData,
  updateUserElecFormData,
  updateUserGasFormData,
} from '@root/reducers/user/userSlice'
import { clearModal } from '@root/reducers/modal/modalSlice'
import { selectModalData } from '@root/reducers/modal/modalSelectors'

const userFormDataUpdateHandlers = {
  elec: updateUserElecFormData,
  gas: updateUserGasFormData,
  dual: updateUserDualFormData,
}

export default function PowerAndTariffOptionPdlModalError() {
  const {
    previousValues,
    newValues,
    newOfferPrices,
    newData,
    contactsPayload,
    incrementStep,
    hopSource,
  } = useSelector(selectModalData)
  const {
    carElectricityBase,
    carElectricityHC,
    carElectricityHP,
    energyChoice,
  } = useCheckout()
  const dispatch = useDispatch()
  const { t } = useTranslation(['validation', 'common', 'eld', 'pdl'])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const userData = useSelector(state => selectUserData(state, energyChoice))

  const clickHandler = async () => {
    setIsSubmitting(true)

    const annualConsoElec = Number.parseInt(
      Number(carElectricityHC) + Number(carElectricityHP),
      10
    )
    const consoHp = Math.round(Number(carElectricityBase) * 0.7)
    const consoHc = Math.round(Number(carElectricityBase) * 0.3)
    const newContractData = Object.assign(
      Object.create(null),
      newData,
      newValues.tariffOption === 'base'
        ? {
            carElectricityBase: annualConsoElec,
          }
        : null,
      newValues.tariffOption === 'hphc'
        ? {
            carElectricityHP: consoHp,
            carElectricityHC: consoHc,
          }
        : null
    )
    const newUserData = Object.assign(
      Object.create(null),
      userData,
      newValues.tariffOption === 'base'
        ? {
            tariffOption: newValues.tariffOption,
            annualConsoElec,
          }
        : null,
      newValues.tariffOption === 'hphc'
        ? {
            tariffOption: newValues.tariffOption,
            consoHp,
            consoHc,
          }
        : null
    )

    try {
      await sendWebLead(
        {
          ...newContractData,
          ...(newContractData.contractEffectiveDate && {
            contractEffectiveDate: new Date(
              newContractData.contractEffectiveDate
            ),
          }),
        },
        hopSource
      )
      dispatch(updateContract(newContractData))
      incrementStep()
      dispatch(clearModal())
    } catch {
      removeEmailToken(true)
      dispatch(clearModal())
      dispatch(setRequestHasErrors(true))
    } finally {
      dispatch(userFormDataUpdateHandlers[newUserData.energyType](newUserData))
      dispatch(updatePrices({ ...newOfferPrices }))
      apiContacts(contactsPayload)
      setIsSubmitting(false)
    }
  }

  return (
    <>
      <h3 className={classes.PdlErrorModal__title}>
        <Icon
          iconId='icon-meter'
          fill='primary'
          size='xl'
          extraClasses={[classes['PdlErrorModal__icon-title']]}
        />
        {t('pdl:error title')}
      </h3>
      <div className={classes.PdlErrorModal__information}>
        <p
          className={classes['PdlErrorModal__information-text']}
          dangerouslySetInnerHTML={{
            __html: t('pdl:error power and tariff option information'),
          }}
        />
        <div
          className={classes['PdlErrorModal__information-card-power-tariff']}
        >
          <div className={classes['PdlErrorModal__information-section']}>
            <p className={classes['PdlErrorModal__information-title']}>
              {t('pdl:tariff option')}
            </p>
            <p
              className={classes['PdlErrorModal__information-to-change']}
              dangerouslySetInnerHTML={{
                __html: t(
                  `pdl:previous tariff option.${previousValues.tariffOption}`
                ),
              }}
            />
            <p className={classes['PdlErrorModal__information-to-change']}>
              {t('pdl:new value text')}
              <span className={classes['PdlErrorModal__new-value']}>
                {t(`pdl:new tariff option.${newValues.tariffOption}`)}
              </span>
            </p>
          </div>
          <div className={classes['PdlErrorModal__information-section']}>
            <p className={classes['PdlErrorModal__information-title']}>
              {t('pdl:power')}
            </p>
            <p
              className={classes['PdlErrorModal__information-to-change']}
              dangerouslySetInnerHTML={{
                __html: t('pdl:previous power', {
                  power: previousValues.power,
                }),
              }}
            />
            <p className={classes['PdlErrorModal__information-to-change']}>
              {t('pdl:new value text')}
              <span className={classes['PdlErrorModal__new-value']}>
                {t('pdl:new power', { power: newValues.power })}
              </span>
            </p>
          </div>
        </div>
        <div className={classes['PdlErrorModal__new-price-information']}>
          <p className={classes['PdlErrorModal__information-text']}>
            {t('pdl:new price text')}
          </p>
          <h3 className={classes['PdlErrorModal__new-price']}>
            {formatPriceWithCurrency(newOfferPrices.offerPriceMonthly)}
            <span className={classes['PdlErrorModal__price-period']}>
              /{t('common:month')}
            </span>
          </h3>
        </div>
        <p className={classes['PdlErrorModal__help-card']}>
          <Icon
            iconId='icon-info-circle-full'
            fill='success'
            size='md'
            extraClasses={[classes['PdlErrorModal__icon-help']]}
          />
          <span className={classes['PdlErrorModal__help-text']}>
            {t('pdl:help information power and tariff option part 1')}{' '}
            <strong>{t('pdl:after')}</strong> {t('pdl:help information part 2')}
          </span>
        </p>
        <Button
          block
          pill
          isLoading={isSubmitting}
          disabled={isSubmitting}
          clickHandler={clickHandler}
          extraClasses={[classes['PdlErrorModal__submit-button']]}
        >
          {t('pdl:accept changes')}
          <Icon iconId='icon-chevron-right' fill='white' position='right' />
        </Button>
      </div>
    </>
  )
}
