import endpointsByApi from '@root/config/endpoints'
import { env, getGlobalConfig, getSiteConfig, isLocalEnv } from './config'

const { apiUrls } = getGlobalConfig()
const config = getSiteConfig()

const getApiProtocol = () => {
  return isLocalEnv && config.urls.api.local ? 'https://' : 'https://'
}

const getApiUrl = () => {
  return config.urls.api[env] ?? config.urls.api.staging
}

const getHost = () => {
  if (!isLocalEnv) {
    return config.urls.app[env]
  }

  return config.urls.app.staging ?? config.urls.app.production
}

const getEndpoints = apiUrlsByKey => {
  const allEndpoints = {}
  const apiEndpoints = endpointsByApi[config.apiEndpointsModule] ?? endpointsByApi.default

  for (const [apiKey, endpoints] of Object.entries(apiEndpoints)) {
    for (const [endpointName, endpointPath] of Object.entries(endpoints)) {
      allEndpoints[endpointName] = apiUrlsByKey[apiKey] + endpointPath
    }
  }

  return allEndpoints
}

const endpoints = getEndpoints({
  comparator: getApiProtocol() + getApiUrl(),
  hop: apiUrls.hop,
  customerService: apiUrls.customerService,
  address: apiUrls.address,
  dialga: apiUrls.dialga
})

export const host = getHost()
export { endpoints as default }
