import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import {
  updateUserSawTypeform,
  updateUserShouldSeeHelpButtonPopUp,
  updateUserShouldSeeResultsUserComebackModal,
} from '@root/reducers/user/userSlice'
import { t } from 'i18next'
import sendUserTrackData from '@common/services/sendUserTrackData'
import { selectUserTrackData } from '@root/reducers/user/userSelectors'
import { SENDING_BLUE_EVENT_NAME } from '@root/constants'

const useUnload = () => {
  const dispatch = useDispatch()
  const { properties, eventData } = useSelector(selectUserTrackData)

  const handleTabClosing = () => {
    const eventName = SENDING_BLUE_EVENT_NAME

    sendUserTrackData({ eventName, properties, eventData })
    dispatch(updateUserShouldSeeResultsUserComebackModal(true))
    dispatch(updateUserShouldSeeHelpButtonPopUp(true))
    dispatch(updateUserSawTypeform(false))
  }

  const showResultsUserComebackModal = () => {
    const resultsUrls = [
      t('routes:electricity'),
      t('routes:gas'),
      t('routes:dual'),
    ]
    const pathname = window.location.pathname
    return resultsUrls.includes(pathname)
  }

  if (!showResultsUserComebackModal()) {
    dispatch(updateUserShouldSeeResultsUserComebackModal(false))
  }

  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClosing)
    return () => {
      window.removeEventListener('beforeunload', handleTabClosing)
    }
  }, [handleTabClosing])
}

export default useUnload
