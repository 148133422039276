const defaultValues = {
  elec: {
    knowConsumption: '1',
    tariffOption: 'base',
    annualConsoElec: 1200,
    subscribedPower: 3,
    resident: 1,
    city: {
      label: 'Milano (20121 - LOMBARDIA)',
      postalCode: '20121',
      cityId: 51322,
      cityName: 'Milano'
    },
    cityAutocomplete: 'Milano (20121 - LOMBARDIA)'
  },
  gas: {
    knowConsumption: '1',
    tariffOption: 'base',
    annualConsoGas: 150,
    resident: 1,
    city: {
      label: 'Milano (20121 - LOMBARDIA)',
      postalCode: '20121',
      cityId: 51322,
      cityName: 'Milano'
    },
    cityAutocomplete: 'Milano (20121 - LOMBARDIA)'
  },
  dual: {
    knowConsumption: '1',
    tariffOption: 'base',
    annualConsoElec: 1200,
    subscribedPower: 3,
    annualConsoGas: 150,
    resident: 1,
    city: {
      label: 'Milano (20121 - LOMBARDIA)',
      postalCode: '20121',
      cityId: 51322,
      cityName: 'Milano'
    },
    cityAutocomplete: 'Milano (20121 - LOMBARDIA)'
  }
}

export default defaultValues
