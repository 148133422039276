import { useCallback, useState, useRef, useEffect } from 'react'
import useDocumentEvent from './useDocumentEvent'

export default function useOpenState(
  initialState = false,
  disabled,
  onBeforeOpen,
  onAfterOpen,
  onBeforeClose,
  onAfterClose
) {
  const triggerRef = useRef(null)
  const popupRef = useRef(null)
  const [isOpen, setIsOpen] = useState(initialState)

  const handleTriggerClick = useCallback(
    event => {
      const triggerElement = triggerRef.current
      if (
        !disabled &&
        triggerElement &&
        triggerElement.contains(event.target)
      ) {
        onBeforeOpen?.()
        setIsOpen(true)
      }
    },
    [onBeforeOpen, disabled]
  )

  const handleOutsidePopupClick = useCallback(
    event => {
      const popupElement = popupRef.current
      if (!disabled && popupElement && !popupElement.contains(event.target)) {
        if (triggerRef.current.contains(event.target)) {
          event.stopImmediatePropagation()
        }
        onBeforeClose?.()
        setIsOpen(false)
      }
    },
    [onBeforeClose, disabled]
  )

  useEffect(() => {
    isOpen ? onAfterOpen?.() : onAfterClose?.()
  }, [isOpen, onAfterOpen, onAfterClose])

  useDocumentEvent([
    {
      type: 'click',
      callback: handleOutsidePopupClick,
    },
    {
      type: 'click',
      callback: handleTriggerClick,
    },
  ])

  return { triggerRef, popupRef, isOpen, setIsOpen }
}
