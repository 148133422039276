export const siteRoutes = [
  // NotFound wildcard
  {
    path: '*',
    component: 'NotFound',
  },
  // Default Route
  {
    path: '',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  // Estimation Form Page
  {
    path: 'hikaku',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  {
    path: 'hikaku-denki',
    component: 'EstimationForm',
    props: {
      configModuleId: 'elecEstimationForm',
      stringsModuleId: 'estimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  // Product List Page
  {
    path: 'denki',
    component: 'ProductList',
    props: {
      formConfigModuleId: 'elecEstimationForm',
      energyType: 'elec',
      pageType: 'part',
    },
  },
  // Product Page
  {
    path: 'offer/*',
    component: 'ProductPage',
  },
  {
    path: 'clear-data',
    component: 'ClearRedux',
  },
]

export default siteRoutes
