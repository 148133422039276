import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  currentStep: 1,
  maxStepCompleted: 1,
  isLoading: false,
  requestHasErrors: false,
  isActionCompleted: false,
  module: 'default',
  stepErrors: {},
  pdlDataWarningText: null,
}

const nextFormStepHandler = state => {
  const { maxStepCompleted, currentStep } = state

  const nextStep = currentStep + 1
  state.currentStep = nextStep

  if (maxStepCompleted > currentStep) {
    state.currentStep = maxStepCompleted + 1
  }

  if (nextStep > maxStepCompleted) {
    state.maxStepCompleted = nextStep - 1
  }
}

const clearFormStepStateHandler = state => {
  state.requestHasErrors = initialState.requestHasErrors
  state.isActionCompleted = initialState.isActionCompleted
  state.isLoading = initialState.isLoading
}

const updateCurrentFormStepHandler = (state, action) => {
  const nextStep = action.payload
  state.currentStep = nextStep
  if (nextStep > state.maxStepCompleted) {
    state.maxStepCompleted = nextStep - 1
  }
}

const showSpinnerHandler = state => {
  state.isLoading = true
}

const hideSpinnerHandler = state => {
  state.isLoading = false
}

const setIsActionCompletedHandler = (state, action) => {
  state.isActionCompleted = action.payload
}
const setRequestHasErrorsHandler = (state, action) => {
  state.requestHasErrors = action.payload
}
const setFormStepErrorHandler = (state, action) => {
  const { step, error } = action.payload
  state.stepErrors[step] = error
}
const clearFormStepErrorHandler = (state, action) => {
  if (state.stepErrors[action.payload]) {
    delete state.stepErrors[action.payload]
  }
}

const setFormModuleHandler = (state, action) => {
  state.module = action.payload
}

const initFormStepsHandler = state => {
  state.currentStep = 1
  state.maxStepCompleted = 1
}

const setPdlDataWarningTextHandler = (state, action) => {
  state.pdlDataWarningText = action.payload
}

export const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateCurrentFormStep: updateCurrentFormStepHandler,
    nextFormStep: nextFormStepHandler,
    showSpinner: showSpinnerHandler,
    hideSpinner: hideSpinnerHandler,
    setIsActionCompleted: setIsActionCompletedHandler,
    setRequestHasErrors: setRequestHasErrorsHandler,
    clearFormStepState: clearFormStepStateHandler,
    setFormStepError: setFormStepErrorHandler,
    clearFormStepError: clearFormStepErrorHandler,
    initFormSteps: initFormStepsHandler,
    setFormModule: setFormModuleHandler,
    setPdlDataWarningText: setPdlDataWarningTextHandler,
    clearForm: () => initialState,
  },
})

export const {
  updateCurrentFormStep,
  nextFormStep,
  showSpinner,
  hideSpinner,
  setIsActionCompleted,
  setRequestHasErrors,
  setFormStepError,
  clearFormStepError,
  initFormSteps,
  setFormModule,
  setPdlDataWarningText,
  clearFormStepState,
} = formSlice.actions

export default formSlice.reducer
