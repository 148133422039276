import apiEndpoints from './apiEndPoints'
import { getSiteConfig } from '@root/utilities/config'

const apiSite = async (currentUrl = window.location.href) => {
  const endpointUrl = new URL(apiEndpoints.site)
  endpointUrl.searchParams.set('path', currentUrl)
  const response = await window.fetch(endpointUrl.href)
  if (response.status !== 200) {
    throw new Error('Could not fetch site data')
  }
  return await response.json()
}

const apiContacts = ({
  phone,
  page,
  knowConsumption,
  energyChoice,
  pdl,
  firstName,
  lastName,
  email,
  insuranceCheckbox,
  errors,
  address,
  knowPdl,
  prestationType,
  urlOrigin,
  experimentId,
}) => {
  if (
    typeof phone !== 'string' ||
    phone === '' ||
    typeof page !== 'string' ||
    page === '' ||
    typeof energyChoice !== 'string' ||
    energyChoice === ''
  ) {
    return
  }

  const siteConfig = getSiteConfig()

  // TODO: Polyfill
  const controller = new window.AbortController()
  const payload = {
    phone,
    page,
    pdl: !isNaN(Number(pdl)) ? Number(pdl) : null,
    first_name: firstName || null,
    last_name: lastName || null,
    email: email || null,
    know_consumption: Number(knowConsumption) > 0 ?? true,
    energy_type: energyChoice,
    insurance_checkbox: insuranceCheckbox || false,
    errors: errors ?? null,
    address: address ?? null,
    know_pdl: Number(knowPdl) ?? 0,
    prestation_type: prestationType || null,
    url_origin: urlOrigin || null,
    experiment_id: experimentId || null,
    site_id: siteConfig.id,
  }

  try {
    window.fetch(apiEndpoints.contacts, {
      method: 'POST',
      signal: controller.signal,
      body: JSON.stringify(payload),
      headers: {
        'Content-type': 'application/json',
      },
    })
  } catch {
    // ignore
  }

  return () => controller.abort()
}

const getOfferData = async (offerId, idSite, data) => {
  const formData = new FormData()
  Object.entries(data).forEach(([key, value]) => {
    formData.append(key, value)
  })
  formData.append('site_id', idSite)

  const url = new URL(`${apiEndpoints.compare}/${offerId}`)

  const response = await fetch(url.toString(), {
    method: 'POST',
    body: formData,
  })

  const offerData = await response.json()

  return {
    status: response.status,
    offerData,
  }
}

const calculateRatingInfoAverage = data => {
  if (!data) {
    return 0
  }

  const { avgTotal } = data
  return (Math.round(avgTotal * 10) / 10).toFixed(1)
}

export { apiSite, apiContacts, getOfferData, calculateRatingInfoAverage }
