const footerLinks = [
  {
    href: 'https://callmepower.be/nl/over',
    translationKey: 'in regards to',
  },
  {
    href: 'https://callmepower.be/nl/disclaimer',
    translationKey: 'legal notice',
  },
  {
    href: 'https://callmepower.be/nl/methodologie',
    translationKey: 'methodology and comparatives',
  },
]

export default footerLinks
