import { useTranslation } from 'react-i18next'
import Icon from '../components/Icon'
import ContactCard from 'src/common/components/ContactCard'
import classes from '@features/Checkout/Modals/FinishSubscriptionByCallModal.module.scss'
import { useSelector } from 'react-redux'
import { selectModalData } from '@root/reducers/modal/modalSelectors'

export default function PdlFinderPdlDataIsNotLinkyModalError() {
  const { t } = useTranslation(['estimationForm'])
  const { leadSource } = useSelector(selectModalData)

  return (
    <>
      <h3 className={classes.FinishSubscriptionByCallModal__title}>
        <Icon
          iconId='icon-checkmark-label'
          fill='notice'
          size='xl'
          extraClasses={[classes['FinishSubscriptionByCallModal__icon-title']]}
        />
        {t('estimationForm:finalize the comparison')}
      </h3>
      <p className={classes.FinishSubscriptionByCallModal__description}>
        {t('estimationForm:pdl is linky')}
      </p>
      <ContactCard
        phone={t('estimationForm:errorModal.phoneNumber')}
        phoneVisual={t('estimationForm:errorModal.phoneNumber visual')}
        withCallback
        withSchedule
        withIcons
        leadSource={leadSource}
      />
    </>
  )
}
