import classes from '@features/ProductList/StickyFooter/StickyFooter.module.scss'
import Icon from '@components/Icon'
import { t } from 'i18next'
import Button from '@components/Button/Button'
import useProductList from '@features/ProductList/useProductList'
import { useDispatch } from 'react-redux'
import { clearModal } from '@root/reducers/modal/modalSlice'

const ResetFiltersConfirm = () => {
  const { handleResetFilters: resetFilters } = useProductList()
  const dispatch = useDispatch()
  return (
    <>
      <h4 className={classes['ResultsStickyFooter__confirm-modal__header']}>
        <Icon
          iconId='icon-warning-triangle'
          fill='primary'
          position='left'
          size='lg'
        />
        {t('common:are you sure?')}
      </h4>
      <div className={classes['ResultsStickyFooter__confirm-modal__content']}>
        <Button
          size='md'
          color='neutral'
          clickHandler={() => {
            dispatch(clearModal())
          }}
          extraClasses={[classes['ResultsStickyFooter__confirm-modal__button']]}
        >
          {t('common:cancel')}
        </Button>
        <Button
          size='md'
          color='danger'
          clickHandler={() => {
            resetFilters()
            dispatch(clearModal())
          }}
          extraClasses={[classes['ResultsStickyFooter__confirm-modal__button']]}
        >
          {t('common:confirm')}
        </Button>
      </div>
    </>
  )
}

export default ResetFiltersConfirm
