import classes from './PaginationItem.module.scss'

const PaginationItemDiscontinued = ({page}) => {
    return (
        <div className={classes.PaginationItem} >
            <label>{page}</label>
        </div>
    )
}

export default PaginationItemDiscontinued