import { Trans, useTranslation } from 'react-i18next'
import classes from './ReviewsHeader.module.scss'
import Icon from '@common/components/Icon'
import ratingModalClasses from '@features/ProductList/Modals/RatingModal.module.scss'
import RatingModalCountItem from '../Rating/RatingModalItem'
import useCheckout from '@features/Checkout/useCheckout'
import { getRatingLineItems } from '@root/utilities/ratings'
import { Col, Row } from '@common/components/Layout/grid/Grid'
import { useDispatch } from 'react-redux'
import { updateModalComponent, updateModalProps } from '@root/reducers/modal/modalSlice'
import useBreakpoint from '@hooks/useBreakpoint'
import { formatPrice } from '@root/utilities/string-utils'

const ReviewsHeader = ({ providerId }) => {
  const { t } = useTranslation(['common', 'productList'])
  const { rateInfo } = useCheckout()
  const { nbRating, avgTotal } = rateInfo
  const dispatch = useDispatch()
  const breakpoint = useBreakpoint()

  const lineItems = getRatingLineItems(rateInfo, nbRating)

  return (
    <div className={classes.ReviewsHeader}>
      <h2 className={classes.ReviewsHeader__title}>
        {t('productList:customer reviews')}
      </h2>

      <div className={classes.ReviewsHeader__rating}>
        <Icon
          iconId='icon-star-full'
          size='xl'
          extraClasses={[ratingModalClasses.RatingModal__star]}
        />
        <label>
          {formatPrice(avgTotal?.toFixed(1), 1, 1)} {t('common:on')} 5&nbsp;
        </label>
        <label className={classes.ReviewsHeader__rating__number}>
          {rateInfo?.nbRating} {t('common:opinions')}
        </label>
      </div>

      <Row>
        <Col xs={12} md={6}>
          {lineItems.map(item => (
            <RatingModalCountItem {...item} key={item.starsKey} />
          ))}
        </Col>
      </Row>

      <p className={classes.ReviewsHeader__subtitle}>
        <Trans
          i18nKey={`customer review note number${
            nbRating > 1 ? '_plural' : ''
          }`}
          count={nbRating}
        >
          Note moyenne basée sur <strong>{{ nbRating }}</strong> avis
          authentiques et vérifiés par nos soins.
        </Trans>
        <Icon
          iconId='circle-interrogation-filled'
          size='md'
          fill='neutral-light'
          clickHandler={() => {
            dispatch(
              updateModalProps({ size: breakpoint === 'xs' ? 'full' : 'md' })
            )
            dispatch(updateModalComponent('reviewsInfo'))
          }}
          extraClasses={[classes['ReviewsHeader__more-info']]}
        />
      </p>
    </div>
  )
}

export default ReviewsHeader
