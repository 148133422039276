const defaultEnginesConfig = {
  urls: {
    app: {
      test: 'localhost',
      local: 'localhost:4000',
      staging: '',
      production: ''
    },
    api: {
      // local: 'energy.selectra.tech',
      staging: '',
      production: ''
    }
  },
  locale: 'fr-FR',
  utc: 2,
  currencySymbol: '€',
  priceFormat: '{price}{currencySymbol}',
  openingHours: {
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: []
  },
  highlightedTop3Offers: {
    buttonIds: []
  },
  leadSources: {
    mainForm: null,
    callbackProductList: null,
    callbackCheckout: null,
    callbackProductPage: null,
    contactCard: null
  },
  footerLinks: null,
  defaultValues: null,
  siteRoutes: null,
  zendeskChatKey: null,
  phone: {
    prefix: null,
    visualPrefix: null,
    visualFormatRegex: null,
    visualFormatRegexDisplay: null
  }
}

export default defaultEnginesConfig
