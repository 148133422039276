import { lazy } from 'react'

export const jp = {
  knowConsumption: lazy(() => import('./KnowConsumption')),
  cityAutocomplete: lazy(() => import('./CityAutocomplete')),
  equipments: lazy(() => import('./Equipments')),
  contactFields: lazy(() => import('./ContactFields')),
  energySelector: lazy(() => import('./EnergySelector')),
  consumptionRanges: lazy(() => import('./ConsumptionRanges')),
}
