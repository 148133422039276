import HelpTooltip from '@components/Hoc/HelpTooltip'
import { useTranslation } from 'react-i18next'
import classes from './Schedule.module.scss'
import i18next from 'i18next'

const getTimeOfWeek = () => {
  const currentDate = new Date()
  const timeOfWeekByDay = {
    0: 'sunday',
    1: 'weekdays',
    2: 'weekdays',
    3: 'weekdays',
    4: 'weekdays',
    5: 'weekdays',
    6: 'saturday',
  }
  return timeOfWeekByDay[currentDate.getDay()]
}

const Schedule = ({ extraClasses }) => {
  const { t } = useTranslation(['estimationForm'])
  const timeOfWeek = getTimeOfWeek()
  const scheduleTranslationKey = `estimationForm:header.helpModal.schedule ${timeOfWeek}`

  if (
    !i18next.exists(scheduleTranslationKey) &&
    t(scheduleTranslationKey) !== ''
  ) {
    return false
  }

  const scheduleClasses = [classes.Schedule]

  Array.isArray(extraClasses) && scheduleClasses.push(...extraClasses)

  return (
    <div className={scheduleClasses.join(' ')}>
      {t(scheduleTranslationKey)}
      <HelpTooltip
        position='top'
        size='full'
        triggerWrapperExtraClasses={[classes['Schedule__help-icon-container']]}
        cardExtraClasses={[classes['Schedule__help-card']]}
      >
        {t('estimationForm:header.helpModal.schedule tooltip')}
      </HelpTooltip>
    </div>
  )
}
export default Schedule
