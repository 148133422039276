import { lazy } from 'react'
import HeaderHelpModal from '../Layout/header-modules/HeaderHelp/HeaderHelpModal'
import MySelectraModal from '../Layout/header-modules/HeaderHelp/MySelectraModal'
import OffersPriceAlertModal from '@features/ProductList/Buttons/OffersPriceAlert/Modal'
import EldModalErrorElectricity from '@common/forms/EldModalErrorElectricity'
import EldModalErrorGas from '@common/forms/EldModalErrorGas'
import EldModalErrorDual from '@common/forms/EldModalErrorDual'
import EldModalErrorNotConnected from '@common/forms/EldModalErrorNotConnected'
import EnedisModalError from '@common/forms/EnedisModalError'
import CardTooltip from '@features/ProductList/TooltipCard'
import ResetFiltersConfirm from '@features/ProductList/Modals/ResetFiltersConfirm'
import CTASubscriptionModal from '@features/ProductPage/CTA/Modals/CTASubscriptionModal'
import ReviewsInfoModal from '@components/Reviews/ReviewsInfoModal'
import PCEInfoModal from '@features/Checkout/FunnelSteps/PCEInfoModal'
import PDLInfoModal from '@features/Checkout/FunnelSteps/PDLInfoModal'
import PowerAndTariffOptionPdlModalError from '@features/Checkout/Modals/PowerAndTariffOptionPdlModalError'
import PowerPdlModalError from '@features/Checkout/Modals/PowerPdlModalError'
import TariffOptionPdlModalError from '@features/Checkout/Modals/TariffOptionPdlModalError'
import OfferMonthlyPaymentError from '@features/Checkout/Modals/OfferMonthlyPaymentError'
import PrestationTypeModalError from '@features/Checkout/Modals/PrestationTypeModalError'
import ElectricityEnergyPriceInfoBeModal from '@features/ProductPage/ProductTable/be/Modals/ElectricityEnergyPriceInfoBeModal'
import ElectricityAdditionalChargesInfoBeModal from '@features/ProductPage/ProductTable/be/Modals/ElectricityAdditionalChargesInfoBeModal'
import GasEnergyPriceInfoBeModal from '@features/ProductPage/ProductTable/be/Modals/GasEnergyPriceInfoBeModal'
import EnergyDistributionInfoBeModal from '@features/ProductPage/ProductTable/be/Modals/EnergyDistributionInfoBeModal'
import EnergyPromotionsInfoBeModal from '@features/ProductPage/ProductTable/be/Modals/EnergyPromotionsInfoBeModal'
import GasAdditionalChargesInfoBeModal from '@features/ProductPage/ProductTable/be/Modals/GasAdditionalChargesInfoBeModal'
import ProSuccessfulContactModal from '@common/forms/ProSuccessfulContactModal'
import PdlFinderEnedisModalError from '@common/forms/PdlFinderEnedisModalError'
import PdlFinderGenericModalError from '@common/forms/PdlFinderGenericModalError'
import PdlFinderNotEnoughConsumptionDataModal from '@common/forms/PdlFinderNotEnoughConsumptionDataModal'
import PdlFinderPdlDataIsNotLinkyModalError from '@common/forms/PdlFinderPdlDataIsNotLinkyModalError'

const modalComponents = {
  headerHelp: HeaderHelpModal,
  mySelectra: MySelectraModal,
  offersPriceAlert: OffersPriceAlertModal,
  callback: lazy(() => import('@components/CallbackModal/CallbackModal')),
  elecEldError: EldModalErrorElectricity,
  gasEldError: EldModalErrorGas,
  dualEldError: EldModalErrorDual,
  notConnectedEldError: EldModalErrorNotConnected,
  enedisError: EnedisModalError,
  productListCardTooltip: CardTooltip,
  userComeback: lazy(() =>
    import('@features/ProductList/Modals/UserComebackModal')
  ),
  estimationForm: lazy(() =>
    import('@features/EstimationForm/ModalEstimationForm')
  ),
  productListFilters: lazy(() =>
    import('@features/ProductList/Modals/Filters')
  ),
  resultsResetFiltersConfirm: ResetFiltersConfirm,
  ctaSubscription: CTASubscriptionModal,
  reviewsInfo: ReviewsInfoModal,
  pceInfo: PCEInfoModal,
  pdlInfo: PDLInfoModal,
  finishSubscriptionByCall: lazy(() =>
    import('@features/Checkout/Modals/FinishSubscriptionByCallModal')
  ),
  powerAndTariffOptionPdlError: PowerAndTariffOptionPdlModalError,
  powerPdlError: PowerPdlModalError,
  tariffOptionPdlError: TariffOptionPdlModalError,
  offerMonthlyPaymentError: OfferMonthlyPaymentError,
  prestationTypeError: PrestationTypeModalError,
  energyDistributionInfoBe: EnergyDistributionInfoBeModal,
  energyPromotionsInfoBe: EnergyPromotionsInfoBeModal,
  electricityEnergyPriceInfoBe: ElectricityEnergyPriceInfoBeModal,
  electricityAdditionalChargesInfoBe: ElectricityAdditionalChargesInfoBeModal,
  gasEnergyPriceInfoBe: GasEnergyPriceInfoBeModal,
  gasAdditionalChargesInfoBe: GasAdditionalChargesInfoBeModal,
  proSuccessfulContact: ProSuccessfulContactModal,
  pdlFinderEnedisModalError: PdlFinderEnedisModalError,
  pdlFinderGenericModalError: PdlFinderGenericModalError,
  pdlFinderNotEnoughConsumptionDataModal:
    PdlFinderNotEnoughConsumptionDataModal,
  pdlFinderPdlDataIsNotLinkyModalError: PdlFinderPdlDataIsNotLinkyModalError,
  selectraScore: lazy(() =>
    import('@components/SelectraScore/SelectraScoreModal')
  )
}

export default modalComponents
