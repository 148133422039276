import Icon from '@components/Icon'
import useBreakpoint from '@hooks/useBreakpoint'
import { selectConfigCountry } from '@root/reducers/config/configSelectors'
import { Trans } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import Schedule from '../Schedule'
import classes from './ContactCard.module.scss'
import {
  updateModalComponent,
  updateModalData,
  updateModalProps,
} from '@root/reducers/modal/modalSlice'
import { getSiteConfig } from '@root/utilities/config'
import {
  selectUserPhoneToCall,
  selectUserPhoneVisualToCall,
} from '@root/reducers/user/userSelectors'

const ContactCard = ({
  id = null,
  withCallback,
  withSchedule,
  externalLink,
  phone: outerPhone,
  phoneVisual: outerPhoneVisual,
  withIcons,
  extraClasses,
  displayPhoneNumber = true,
  leadSource,
  flagExtraClasses,
  hidden,
}) => {
  const dispatch = useDispatch()
  const country = useSelector(selectConfigCountry)
  const config = getSiteConfig()
  const userPhoneVisualToCall = useSelector(selectUserPhoneVisualToCall)
  const userPhoneToCall = useSelector(selectUserPhoneToCall)
  const phone = userPhoneToCall ?? outerPhone
  const phoneVisual = userPhoneVisualToCall ?? outerPhoneVisual

  const setCallbackModalIsOpen = () => {
    dispatch(
      updateModalProps({
        size: 'xs',
        isDrawer: breakpoint === 'xs',
      })
    )
    dispatch(
      updateModalData({
        leadSource: leadSource ?? config.leadSources.contactCard,
      })
    )
    dispatch(updateModalComponent('callback'))
  }

  const breakpoint = useBreakpoint()
  const cardClasses = [classes.ContactCard]
  Array.isArray(extraClasses) && cardClasses.push(...extraClasses)

  if (hidden) {
    return null
  }

  return (
    <>
      <div className={cardClasses.join(' ')} hidden={!displayPhoneNumber}>
        <div className={classes.ContactCard__cols}>
          {withIcons && (
            <img
              className={classes.ContactCard__callcenter}
              src='/img/new/callcenter_woman.jpg'
              alt='Callcenter'
            />
          )}
          <a href={`tel:${phone}`} className={classes.ContactCard__phone}>
            {phoneVisual}
          </a>
          {withIcons && (
            <Icon
              iconId={`flag-${country}`}
              size='xl'
              extraClasses={flagExtraClasses}
            />
          )}
        </div>
        {withCallback && (
          <div className={classes['ContactCard__callback-text']}>
            <Trans i18nKey='estimationForm:header.helpModal.request callback'>
              ou
              <button
                id={id}
                data-cy='help-callback-link'
                onClick={setCallbackModalIsOpen}
                type='button'
              >
                faites-vous rappeler gratuitement
              </button>
            </Trans>
          </div>
        )}
        {externalLink && (
          <div className={classes['ContactCard__callback-text']}>
            <Trans i18nKey='estimationForm:header.helpModal.request callback'>
              ou
              <a href={externalLink} target='_blank' rel='noreferrer'>
                faites-vous rappeler gratuitement
              </a>
            </Trans>
          </div>
        )}
        {withSchedule && (
          <Schedule extraClasses={[classes.ContactCard__schedule]} />
        )}
      </div>
    </>
  )
}
export default ContactCard
