import { combineReducers } from 'redux'
import user from '@/reducers/user/userSlice'
import offers from '@/reducers/offers/offersSlice'
import currentOffer from '@/reducers/currentOffer/currentOfferSlice'
import contract from '@/reducers/contract/contractSlice'
import config from '@/reducers/config/configSlice'
import filters from '@/reducers/filters/filtersSlice'
import reviews from '@/reducers/reviews/reviewsSlice'
import checkout from '@/reducers/checkout/checkoutSlice'
import modal from '@/reducers/modal/modalSlice'
import form from '@/reducers/form/formSlice'

import { endpointsApi } from '@/reducers/endpoints/endpointsSlice'

export const rootReducer = combineReducers({
  [endpointsApi.reducerPath]: endpointsApi.reducer,
  checkout,
  config,
  contract,
  currentOffer,
  filters,
  form,
  modal,
  offers,
  reviews,
  user,
})
