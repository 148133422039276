import { lazy } from 'react'
import { country } from '@root/utilities/config'

const TooltipByCountry = {
  fr: lazy(() => import('./fr/Tooltip')),
  it: lazy(() => import('./it/Tooltip')),
  default: () => null,
}

const Tooltip = TooltipByCountry[country] ?? TooltipByCountry.default

export default Tooltip
