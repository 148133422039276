// import { reportFrontError } from '@root/utilities/error-reporting'
import { Component } from 'react'
import { connect } from 'react-redux'
import { clearCheckout } from '@root/reducers/checkout/checkoutSlice'
import { clearConfig } from '@root/reducers/config/configSlice'
import { clearContract } from '@root/reducers/contract/contractSlice'
import { clearCurrentOffer } from '@root/reducers/currentOffer/currentOfferSlice'
import { clearFilters } from '@root/reducers/filters/filtersSlice'
import { clearModal } from '@root/reducers/modal/modalSlice'
import { clearOffers } from '@root/reducers/offers/offersSlice'
import { clearUser } from '@root/reducers/user/userSlice'
import i18n from 'i18next'
import classes from './ErrorBoundary.module.scss'
// import { env } from '@utilities/config'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    }
  }

  // componentDidCatch(error, errorInfo) {
  //
  //   const clearAlReduxData = () => {
  //     this.props.clearCheckout()
  //     this.props.clearConfig()
  //     this.props.clearContract()
  //     this.props.clearCurrentOffer()
  //     this.props.clearFilters()
  //     this.props.clearModal()
  //     this.props.clearOffers()
  //     this.props.clearUser()
  //   }
  //
  //   reportFrontError(
  //     error.message,
  //     `${error.stack}\n\n\n${errorInfo.componentStack}`,
  //     this.props.config.siteId || null
  //   )
  //
  //   if (env !== 'local') {
  //     clearAlReduxData()
  //   }
  // }

  render() {
    const { hasError, error } = this.state
    if (hasError) {
      return (
        <div className={classes.ErrorBoundary}>
          <p
            dangerouslySetInnerHTML={{
              __html: i18n.t('misc:application crashed'),
            }}
          />
          <button
            onClick={() => {
              window.location.reload()
            }}
            className={classes.ErrorBoundary__button}
          >
            {i18n.t('common:refresh')}
          </button>
        </div>
      )
    }

    return this.props.children
  }
}

const mapStateToProps = state => {
  return {
    config: state?.config?.data || {},
  }
}

const setActions = dispatch => {
  return {
    clearCheckout: () => dispatch(clearCheckout()),
    clearConfig: () => dispatch(clearConfig()),
    clearContract: () => dispatch(clearContract()),
    clearCurrentOffer: () => dispatch(clearCurrentOffer()),
    clearFilters: () => dispatch(clearFilters()),
    clearModal: () => dispatch(clearModal()),
    clearOffers: () => dispatch(clearOffers()),
    clearUser: () => dispatch(clearUser()),
  }
}

export default connect(mapStateToProps, setActions)(ErrorBoundary)
