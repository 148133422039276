const defaultValues = {
  elec: {
    knowConsumption: '1',
    tariffOption: 'base',
    annualConsoElec: 3000,
    subscribedPowerId: 41,
    houseType: 'actual',
    city: {
      label: '',
      postalCode: '',
      cityId: '',
      cityName: '',
    },
    cityAutocomplete: '',
  },
  gas: {
    knowConsumption: '1',
    annualConsoGas: 3400,
    houseType: 'actual',
    city: {
      label: '',
      postalCode: '',
      cityId: '',
      cityName: '',
    },
    cityAutocomplete: '',
  },
  dual: {
    knowConsumption: '1',
    tariffOption: 'base',
    annualConsoElec: 3000,
    subscribedPowerId: 41,
    annualConsoGas: 3400,
    houseType: 'actual',
    city: {
      label: '',
      postalCode: '',
      cityId: '',
      cityName: '',
    },
    cityAutocomplete: '',
  },
}

export default defaultValues
