import { createSlice } from '@reduxjs/toolkit'
import { keysToCamel } from '@/utilities/snake-case-to-camel-case'

const initialState = {
  data: {},
  error: null,
}

const updateContractHandler = (state, action) => {
  state.data = {
    ...state.data,
    ...keysToCamel(action.payload),
  }
}

const updateMonthlyPaymentHandler = (state, action) => {
  state.data = {
    ...state.data,
    monthlyPaymentElec: action.payload.monthlyPaymentElec,
    monthlyPaymentGas: action.payload.monthlyPaymentGas,
    monthlyPaymentTotal: action.payload.monthlyPaymentTotal,
  }
}

const updateContractEnergyTypeHandler = (state, action) => {
  state.data = {
    ...state.data,
    energyChoice: action.payload,
  }
}

export const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    updateContract: updateContractHandler,
    clearContract: () => initialState,
    updateMonthlyPayment: updateMonthlyPaymentHandler,
    updateContractEnergyType: updateContractEnergyTypeHandler,
  },
})

export const {
  updateContract,
  clearContract,
  updateMonthlyPayment,
  updateContractEnergyType,
} = contractSlice.actions

export default contractSlice.reducer
