import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import endpoints from '@/utilities/apiEndPoints'

export const reviewsApi = createApi({
  reducerPath: 'reviews',
  baseQuery: fetchBaseQuery({ baseUrl: endpoints.v2 }),
  endpoints: builder => ({
    getReviewKeywordsByProvider: builder.query({
      query: idProvider => `providers/${idProvider}/keywords`,
      transformResponse: response =>
        response.data.map(item => ({
          text: item.text,
          isPositive: item.is_positive,
        })),
    }),
  }),
})

export const { useGetReviewKeywordsByProviderQuery } = reviewsApi

export default reviewsApi.reducer
