const footerLinks = [
  {
    href: 'https://selectra.info/groupe',
    translationKey: 'in regards to',
  },
  {
    href: 'https://selectra.info/mentions-legales',
    translationKey: 'legal notice',
  },
  {
    href: 'https://selectra.info/methodologie',
    translationKey: 'methodology and comparatives',
  },
]

export default footerLinks
