const footerLinks = [
  {
    href: 'https://selectra.jp/about-us',
    translationKey: 'in regards to',
  },
  {
    href: 'https://selectra.jp/privacy-policy',
    translationKey: 'legal notice',
  },
  {
    href: 'https://selectra.jp/methodology',
    translationKey: 'methodology and comparatives',
  },
]

export default footerLinks
