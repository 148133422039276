import { useController, useFormState } from 'react-hook-form'
import Icon from '@components/Icon'
import classes from './WithFormField.module.scss'
import HelpTooltip from '@components/Hoc/HelpTooltip'
import { useEffect, useId } from 'react'

const FormFieldLabel = ({
  isRequired,
  helpCardContent,
  helpModalComponent,
  helpModalProps,
  helpModalTriggerId,
  children,
  htmlFor,
  helpCardContentId,
}) => (
  <div className={classes['FormField__label-wrapper']}>
    <label
      htmlFor={htmlFor}
      className={classes['FormField__label-wrapper__label']}
    >
      {children && (
        <>
          {isRequired && (
            <span className={classes['FormField__required-mark']}>*&nbsp;</span>
          )}
          {children}
        </>
      )}
    </label>
    {helpModalComponent && (
      <span>
        <HelpTooltip
          type='modal'
          modalComponent={helpModalComponent}
          modalProps={helpModalProps}
          modalId={helpModalTriggerId}
        />
      </span>
    )}
    {helpCardContent && (
      <HelpTooltip
        type='popup'
        position='top'
        size='full'
        id={helpCardContentId}
      >
        {helpCardContent}
      </HelpTooltip>
    )}
  </div>
)

const FormFieldMessage = ({ children }) => (
  <span className={classes.FormField__message}>{children}</span>
)

const withFormField = FormComponent => {
  const FormField = props => {
    const {
      label,
      helpIconId,
      helpMessage,
      errorMessage,
      errorIconId,
      helpCardContent,
      withoutRequiredMark,
      noMargin,
      noMarginDesktop,
      extraClasses,
      fieldExtraClasses,
      hidden,
      helpCardContentId,
      helpModalComponent,
      helpModalProps,
      helpModalTriggerId,
      unregister,
      ...passThroughProps
    } = props

    const { field } = useController({
      name: props.name,
      control: props.control,
      rules: props.rules || {},
      defaultValue: props.defaultValue || '',
    })

    const { isValidating, isSubmitting, errors } = useFormState({
      control: props.control,
      name: props.name,
    })

    useEffect(() => {
      return () => {
        unregister && unregister(props.name, { keepValue: true })
      }
    }, [])

    const thisFieldError = errors[props.name]?.message || errorMessage

    const fieldClasses = [classes.FormField]

    noMargin && fieldClasses.push(classes['FormField__no-margin'])

    noMarginDesktop &&
      fieldClasses.push(classes['FormField__no-margin-desktop'])

    Array.isArray(extraClasses) && fieldClasses.push(...extraClasses)

    const isDisabled = isSubmitting || isValidating || props.disabled

    thisFieldError && fieldClasses.push(classes['FormField--danger'])

    props?.rules?.required && fieldClasses.push('FormField--required')

    const id = useId() + field.name

    if (hidden) {
      return null
    }

    return (
      <div className={fieldClasses.join(' ')}>
        <FormFieldLabel
          disabled={isDisabled}
          helpModalComponent={helpModalComponent}
          helpModalProps={helpModalProps}
          helpModalTriggerId={helpModalTriggerId}
          helpCardContent={helpCardContent}
          isRequired={props.rules?.required && !withoutRequiredMark}
          htmlFor={id}
          helpCardContentId={helpCardContentId}
        >
          {label}
        </FormFieldLabel>
        <div className={classes.FormField__item}>
          <FormComponent
            field={field}
            id={id}
            extraClasses={fieldExtraClasses}
            {...passThroughProps}
          />
          {thisFieldError && (
            <FormFieldMessage withError>
              <Icon
                position='left'
                fill='danger'
                iconId={errorIconId || 'icon-warning-triangle'}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: thisFieldError,
                }}
              />
            </FormFieldMessage>
          )}
          {!thisFieldError && helpMessage && (
            <FormFieldMessage>
              <Icon
                position='left'
                fill='neutral-light'
                iconId={helpIconId || 'icon-info-circle-full'}
              />
              {helpMessage}
            </FormFieldMessage>
          )}
        </div>
      </div>
    )
  }

  return FormField
}

export { withFormField as default }
