import { padNum } from './string-utils'

const isValidDate = date =>
  date &&
  Object.prototype.toString.call(date) === '[object Date]' &&
  !isNaN(date)

const getOffsetDate = (
  yearOffset = 0,
  monthOffset = 0,
  dayOffset = 0,
  date = new Date()
) =>
  new Date(
    date.getFullYear() + yearOffset,
    date.getMonth() + monthOffset,
    date.getDate() + dayOffset
  )

const formatDate = (
  date = new Date(),
  separator = '/',
  order = ['day', 'month', 'year'],
  withTime = false
) => {
  const values = {
    day: padNum(date.getDate()),
    month: padNum(date.getMonth() + 1),
    year: date.getFullYear(),
  }
  return `${values[order[0]]}${separator}${values[order[1]]}${separator}${
    values[order[2]]
  }${
    withTime
      ? ` ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
      : ''
  }`
}

const getUnixTimeStamp = (date = new Date()) =>
  Math.floor(date.getTime() / 1000)

export { isValidDate, getOffsetDate, formatDate, getUnixTimeStamp }
