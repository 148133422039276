import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import createStore from '@/app/Store'
import App from '@/app/App'
import { getEnvAndCountry, getGlobalConfig } from '@/utilities/config'
import '@/assets/sass/reset.scss'
import '@/i18n'

const container = document.getElementById('root')
const root = createRoot(container)
const globalConfig = getGlobalConfig()
const { country } = getEnvAndCountry()
const { store, persistor } = createStore()

root.render(
  country === 'fr'
    ? (
      <GoogleReCaptchaProvider
        useRecaptchaNet
        reCaptchaKey={globalConfig.auth.recaptchaPublicKey}
      >
        <Provider store={store}>
          <PersistGate loading='loading persistor' persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </GoogleReCaptchaProvider>
      )
    : (
      <Provider store={store}>
        <PersistGate loading='loading persistor' persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
      )
)
