import { useEffect, useState } from 'react'
import Icon from '../Icon'
import classes from './SubmitButton.module.scss'

const timeSwitchingTexts = 500
const defaultVisibleTextClasses = [
  classes.SubmitButton__content__text
]
const defaultHiddenTextClasses = [
  classes.SubmitButton__content__text,
  classes['SubmitButton__content__text--waiting-to-show']
]
const defaultTextsClasses = {
  default: defaultVisibleTextClasses,
  loading: defaultHiddenTextClasses,
  successful: defaultHiddenTextClasses,
  error: defaultHiddenTextClasses
}

const SubmitButtonLoadingBar = (
  {
    isActionCompleted,
    loadingText,
    children,
    isLoading,
    loadingTime = 0.9,
    errorText,
    hasError,
    onCompleteLoadBar = () => {},
    onAnimationCompleted = () => {},
  }
) => {
  const defaultLoadBarAttributes = {
    transition: `width ${loadingTime}s ease-in-out`
  }

  const [hasPercentageBarFullWidth, setHasPercentageBarFullWidth] = useState(false)
  const [isLoadingTextShown, setIsLoadingTextShown] = useState(false)
  const [textsClasses, setTextsClasses] = useState(defaultTextsClasses)
  const [loadBarAttributes, setLoadBarAttributes] = useState(defaultLoadBarAttributes)
  let loadingBarTimeout = null
  let timerToCompleteAnimation = null
  const timeShowingSuccess = 1000

  const fillLoadingBar = (seconds) => {
    loadingBarTimeout && clearTimeout(loadingBarTimeout)
    let timeFillingBar = 0
    let transition = 'none'
    const timeShowingFullBar = 500

    if (seconds !== 0) {
      timeFillingBar = seconds * 1000
      transition = `width ${seconds}s ease-in-out`
    }

    setLoadBarAttributes({
      transition,
      width: '100%'
    })

    loadingBarTimeout = setTimeout(() => {
      if (!isActionCompleted) {
        return
      }
      setHasPercentageBarFullWidth(true)
      onCompleteLoadBar()
    }, timeFillingBar + timeShowingFullBar);
  }

  const resetLoadingBar = () => {
    setLoadBarAttributes({
      transition: 'none',
      width: '0%'
    })
    setHasPercentageBarFullWidth(false)
  }

  const switchTexts = (from, to) => {
    setTextsClasses(current => {
      const newClasses = {...current}
      newClasses[from] = newClasses[from].concat([classes['SubmitButton__content__text--done']])
      newClasses[to] = newClasses[to].filter(elem => {
        return elem !== classes['SubmitButton__content__text--waiting-to-show']
      });
      return newClasses
    })
  }

  const setTextAsShown = (which) => {
    setTextsClasses(current => {
      const newClasses = {...current}
      newClasses[which] = newClasses[which].concat([classes['SubmitButton__content__text--done']])
      return newClasses
    })
  }

  const setTextAsPendingToShow = (which) => {
    setTextsClasses(current => {
      const newClasses = {...current}
      newClasses[which] = defaultHiddenTextClasses
      newClasses[which] = newClasses[which].concat([classes['SubmitButton__content__text--not-animated']])
      return newClasses
    })
  }

  useEffect(() => {
    if (hasError) {
      resetLoadingBar()
      setTextAsShown('default')
      if (isLoadingTextShown) {
        switchTexts('loading', 'error')
      }
      const timersetTextAsPendingToShow = setTimeout(() => {
        setTextAsPendingToShow('loading')
        setIsLoadingTextShown(false)
      }, timeSwitchingTexts);
      return () => clearTimeout(timersetTextAsPendingToShow);
    }

    if (!isLoading) {
      if(!isActionCompleted) {
        setTextsClasses(defaultTextsClasses)
        setIsLoadingTextShown(false)
      }
      return
    }

    setLoadBarAttributes(currentAttributes => {
      return {
        ...currentAttributes,
        ...defaultLoadBarAttributes
      }
    })

    switchTexts('default', 'loading')
    setIsLoadingTextShown(true)

    const timerLoadingBar = setTimeout(() => {
      if (isActionCompleted) {
        fillLoadingBar(0.5)
      } else {
        fillLoadingBar(loadingTime)
      }
    }, timeSwitchingTexts);
    return () => clearTimeout(timerLoadingBar);
  }, [isLoading, hasError])

  useEffect(() => {
    if (!isActionCompleted || !hasPercentageBarFullWidth) {
      return
    }

    switchTexts('loading', 'successful')

    setTimeout(() => {
      resetLoadingBar()
    }, timeSwitchingTexts - 400 || 0);

    timerToCompleteAnimation && clearTimeout(timerToCompleteAnimation)

    timerToCompleteAnimation = setTimeout(() => {
      onAnimationCompleted()
    }, timeSwitchingTexts + timeShowingSuccess);

  }, [isActionCompleted, hasPercentageBarFullWidth])

  useEffect(() => {
    if (isActionCompleted && isLoadingTextShown) {
      fillLoadingBar(0)
    }

  }, [isActionCompleted])

  useEffect(() => {
    if (!hasError) {
      return
    }

    switchTexts('loading', 'error')
  }, [hasError])

  return (
    <>
      <span className={classes['SubmitButton__load-bar']} style={loadBarAttributes}></span>
      <span className={classes.SubmitButton__content}>
        <span className={textsClasses.default.join(' ')}>{children}</span>
        <span className={textsClasses.loading.join(' ')}>{loadingText}</span>
        <span className={textsClasses.successful.join(' ')}>
          <Icon iconId='icon-checkmark' size='lg' />
        </span>
        <span className={textsClasses.error.join(' ')}>
          {errorText}
          <Icon iconId='icon-cross' size='md' position='right' />
        </span>
      </span>
    </>
  )
}

export default SubmitButtonLoadingBar
