import { useTranslation } from 'react-i18next'
import Icon from '@common/components/Icon'
import classes from './EnergyInfoBeModal.module.scss'
import { Fragment } from 'react'

const GasAdditionalChargesInfoBeModal = () => {
  const { t } = useTranslation(['common', 'checkout'])
  const federalChargesList = t(
    'checkout:energy info modals.gas additional charges.federals.list',
    {
      returnObjects: true,
    }
  )
  const regionChargesList = t(
    'checkout:energy info modals.gas additional charges.regions.list',
    {
      returnObjects: true,
    }
  )

  return (
    <div className={classes.EnergyInfoBeModal__text}>
      <h1 className={classes.EnergyInfoBeModal__title}>
        <Icon
          iconId='circle-interrogation-filled'
          fill='primary'
          size='xl'
          position='left'
        />
        {t('checkout:additional charges')}
      </h1>
      <p>
        {t('checkout:energy info modals.gas additional charges.federals.intro')}
      </p>
      <ul className={classes.EnergyInfoBeModal__list}>
        {federalChargesList.map(text => (
          <li key={text}>{text}</li>
        ))}
      </ul>
      <br />
      <p>
        {t('checkout:energy info modals.gas additional charges.regions.intro')}
      </p>
      {regionChargesList.map(text => (
        <Fragment key={text.title}>
          <h2 className={classes.EnergyInfoBeModal__subtitle} key={text.title}>
            {text.title}
          </h2>
          <p>{text.description}</p>
        </Fragment>
      ))}
    </div>
  )
}

export { GasAdditionalChargesInfoBeModal as default }
