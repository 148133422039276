import { useState, useEffect } from 'react'

export default function useBreakpoint() {
  const [breakpoint, setBreakpoint] = useState(null)

  useEffect(() => {
    const xsMql = window.matchMedia('(max-width: 575px)')
    const smMql = window.matchMedia('(min-width: 576px) and (max-width: 767px)')
    const mdMql = window.matchMedia('(min-width: 768px) and (max-width: 991px)')
    const lgMql = window.matchMedia('(min-width: 992px) and (max-width: 1199px)')
    const xlMql = window.matchMedia('(min-width: 1200px)')

    const breakpoints = [
      {
        name: 'xs',
        mql: xsMql,
      },
      {
        name: 'sm',
        mql: smMql,
      },
      {
        name: 'md',
        mql: mdMql,
      },
      {
        name: 'lg',
        mql: lgMql,
      },
      {
        name: 'xl',
        mql: xlMql,
      },
    ]
    breakpoints.forEach(breakpoint => {
      breakpoint.mql?.matches && setBreakpoint(breakpoint.name)
      breakpoint.handler = event => {
        event.matches && setBreakpoint(breakpoint.name)
      }
      breakpoint.mql?.addEventListener('change', breakpoint.handler)
    })
    return () => {
      breakpoints.forEach(breakpoint => {
        breakpoint.mql?.removeEventListener('change', breakpoint.handler)
      })
    }
  }, [])
  return breakpoint
}
