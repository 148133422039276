/* eslint-disable no-debugger */
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { apiContacts } from '@/utilities/comparator'
import { updateContract } from '@/reducers/contract/contractSlice'
import { phone as parsePhone } from 'phone'
import { selectContractData } from '@root/reducers/contract/contractSelectors'
import { selectUserData, selectUserExperimentId } from '@root/reducers/user/userSelectors'
import { selectCurrentOfferData } from '@root/reducers/currentOffer/currentOfferSelectors'
import { removeEmailToken, sendWebLead } from '@root/utilities/hop'
import { setIsActionCompleted, setRequestHasErrors, showSpinner } from '@root/reducers/checkout/checkoutSlice'
import { updateModalComponent, updateModalProps } from '@root/reducers/modal/modalSlice'

export const checkoutActions = {
  POST_CONTACT: 'POST_CONTACT',
  POST_WEB_LEAD: 'POST_WEB_LEAD',
  SUBMIT_STEP: 'SUBMIT_STEP'
}

export function * postWebLeadSaga ({ payload }) {
  const { hopSource, detailsData, breakpoint } = payload

  try {
    const contractData = yield select(selectContractData)

    const values = {
      ...contractData,
      ...detailsData,
      ...(contractData.contractEffectiveDate && {
        contractEffectiveDate: new Date(contractData.contractEffectiveDate)
      })
    }

    yield call(sendWebLead, { ...values }, hopSource)
  } catch {
    yield call(removeEmailToken, true)
    yield call(showFinishSubscriptionByCallModal, {
      breakpoint
    })
    throw new Error()
  }
}

export function * showFinishSubscriptionByCallModal ({ breakpoint }) {
  yield put(
    updateModalProps({
      size: 'xs',
      isDrawer: breakpoint === 'xs',
      id: 'contact-info-modal',
      onAfterClose: () => {
        put(setRequestHasErrors(false))
      }
    })
  )
  yield put(updateModalComponent('finishSubscriptionByCall'))
}

export function * postContactSaga ({ payload }) {
  try {
    const {
      firstName,
      lastName,
      email,
      PDL: pdl,
      energyChoice,
      knowPdl,
      street,
      prestationType
    } = yield select(selectContractData)
    const { knowConsumption, urlOrigin } = yield select(selectUserData)
    const { funnelType, isFullOnline } = yield select(selectCurrentOfferData)
    const experimentId = yield select(selectUserExperimentId)

    const { detailsData } = payload ?? {}
    const { phone1: phone } = detailsData

    const page =
      funnelType === 'comparator'
        ? `checkout-${isFullOnline ? 'online' : 'hybrid'}-address`
        : `${funnelType}-checkout-online-address`

    const contactsPayload = {
      phone: parsePhone(phone, { country: 'FR' }).phoneNumber,
      page,
      pdl,
      firstName,
      lastName,
      email,
      energyChoice,
      knowConsumption,
      knowPdl,
      address: street?.label,
      urlOrigin,
      experimentId,
      prestationType
    }
    yield call(apiContacts, { ...contactsPayload })
  } catch (e) {
    console.error(e)
  }
}

export function * submitStepSaga ({
  payload: { detailsData, hopSource, breakpoint }
}) {
  try {
    yield put(showSpinner())
    yield put(updateContract(detailsData))
    yield call(postWebLeadSaga, {
      payload: {
        detailsData,
        hopSource,
        breakpoint
      }
    })
    yield call(postContactSaga, { payload: { detailsData } })
    yield put(setIsActionCompleted(true))
  } catch (e) {
    yield put(setRequestHasErrors(true))
  }
}

export default function * checkoutSagas () {
  yield takeLatest(checkoutActions.POST_WEB_LEAD, postWebLeadSaga)
  yield takeLatest(checkoutActions.POST_CONTACT, postContactSaga)
  yield takeLatest(checkoutActions.SUBMIT_STEP, submitStepSaga)
}
