const postFormValues = (url, values) => {
  const form = document.createElement('form')
  Object.entries(values).forEach(([key, value]) => {
    const input = document.createElement('input')
    input.type = 'hidden'
    input.name = key
    input.value = `${value}`
    form.appendChild(input)
  })
  form.method = 'POST'
  form.action = url
  document.body.appendChild(form)
  form.submit()
}

export { postFormValues }
