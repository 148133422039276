import useOutsideClick from '@hooks/useOutsideClick'

const withMouseEvents = Component => {
  const MouseHandler = ({
    mouseEnterHandler,
    mouseLeaveHandler,
    mouseClickHandler,
    outsideMouseClickHandler,
    ...props
  }) => {
    const { ref: wrapperRef } = useOutsideClick(outsideMouseClickHandler)
    const handlers = Object.assign(
      {},
      typeof mouseEnterHandler === 'function'
        ? { onMouseEnter: mouseEnterHandler }
        : null,
      typeof mouseLeaveHandler === 'function'
        ? { onMouseLeave: mouseLeaveHandler }
        : null,
      typeof mouseClickHandler === 'function'
        ? { onClick: mouseClickHandler }
        : null
    )
    return (
      <div ref={wrapperRef} {...handlers}>
        <Component {...props} />
      </div>
    )
  }
  return MouseHandler
}

export { withMouseEvents as default }
