import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from './LanguageDetector'
import translationFr from './lang/fr-fr'
import translationIt from './lang/it-it'
import translationBeFr from './lang/be-fr'
import translationBeNl from './lang/be-nl'
import translationJp from './lang/jp-jp'
import translationEs from './lang/es-es'
import translationPt from './lang/pt-pt'

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: 'common',
    ns: ['common'],
    resources: {
      fr: translationFr,
      it: translationIt,
      beFr: translationBeFr,
      beNl: translationBeNl,
      jp: translationJp,
      es: translationEs,
      pt: translationPt,
    },
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    keySeparator: '.',
  })

export default i18n
