import Icon from '../Icon'
import ProgressBar from '../ProgressBar/ProgressBar'
import classes from '@features/ProductList/Modals/RatingModal.module.scss'

const RatingModalCountItem = props => {
  const { starsKey, percentage } = props
  return (
    <div className={classes.RatingModal__item}>
      <label className={classes.RatingModal__item__label}>
        {starsKey}
        <Icon
          iconId='icon-star-full'
          size='md'
          extraClasses={[classes.RatingModal__star]}
        />
      </label>
      <ProgressBar percentage={percentage} />
      <label className={classes.RatingModal__item__label}>
        {`${percentage}%`}
      </label>
    </div>
  )
}

export default RatingModalCountItem
