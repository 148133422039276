import { forwardRef, useCallback, useEffect, useReducer, useState } from 'react'
import ReviewsList from './ReviewsList'
import ReviewsHeader from './ReviewsHeader'
import endpoints from '@/utilities/apiEndPoints'
import classes from './Reviews.module.scss'
import Icon from '../Icon'
import Pagination from '../Pagination/Pagination'

const initialState = {
  data: {},
  pagination: {
    page: 1,
    perPage: 10,
    totalItems: null
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_PAGE':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          page: action.payload
        }
      }
    case 'SET_TOTAL_ITEMS':
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalItems: action.payload
        }
      }
    case 'SET_ITEMS':
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.page]: action.payload.items
        }
      }
  }
}

const ReviewsWithRef = ({ providerId, onDataLoaded }, ref) => {
  const [isLoading, setIsLoading] = useState(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const lastPage = Math.ceil(
    state.pagination.totalItems / state.pagination.perPage
  )

  const fetchReviews = useCallback(async () => {
    try {
      setIsLoading(true)
      const { page } = state.pagination
      const start = state.pagination.perPage * (state.pagination.page - 1)

      /**
       * Already fetched, we can skip
       */
      if (state.data[page]?.length) {
        return
      }

      const response = await fetch(
        `${endpoints.paginatedReviews}${providerId}/${start}/${state.pagination.perPage}`
      )

      if (response.status !== 200) {
        return
      }

      const { data: reviews } = await response.json()

      dispatch({
        type: 'SET_TOTAL_ITEMS',
        payload: reviews?.pagination?.total
      })
      dispatch({
        type: 'SET_ITEMS',
        payload: {
          page,
          items: reviews?.list
        }
      })
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      setIsLoading(false)
      onDataLoaded()
    }
  }, [state.pagination.page])

  useEffect(() => {
    fetchReviews()
  }, [fetchReviews])

  if (!isLoading && !state.data?.[1]?.length) {
    return
  }

  return (
    <section ref={ref} className={classes.Reviews}>
      <ReviewsHeader providerId={providerId} />
      {isLoading && (
        <Icon
          iconId='icon-loader'
          rotate
          fill='neutral-light'
          extraClasses={[classes.Reviews__loader]}
        />
      )}
      {state.data[state.pagination.page] && !isLoading && (
        <ReviewsList reviews={state.data[state.pagination.page]} />
      )}
      <Pagination
        hidden={lastPage <= 1}
        key={`${state.pagination.page}-${state.pagination.totalItems}`}
        page={state.pagination.page}
        perPage={10}
        lastPage={lastPage}
        visibleItems={5}
        setCurrentPage={page => {
          dispatch({
            type: 'SET_PAGE',
            payload: page
          })
        }}
      />
    </section>
  )
}

const Reviews = forwardRef(ReviewsWithRef)

export { Reviews as default }
